import styled from "styled-components";

export const Wrapper = styled("div")<{
  backgroundColor: any;
  mainContentTextColor: string;
  titleColor: any;
  textColor: any;
  visibilty?: boolean;
}>`
  .mainSection {
    /* display: flex;
    width: 100%;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    background-color: ${(props) => props.backgroundColor.hex};
    border-radius: 28px;
    box-sizing: border-box;
    overflow: hidden;
    .mainSection-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      svg {
        color: #474646;
      }
    } */

    .mainSection-content {
      width: 100%;
    }
    .mainSection-content-infos {
      padding: 14px 16px 0px 16px;
    }
    /* .mainSection-content-enseigne {
      box-sizing: border-box;
      width: 100%;
      h3 {
        font-family: "Product Sans", sans-serif;
        font-size: 1.3rem;
        color: ${(props) => props.mainContentTextColor};
        line-height: 22px;
        letter-spacing: 1px;
        margin: 0;
        font-weight: 500;
      }
    } */

    .mainSection-content-enseigne {
      width: 100%;
      box-sizing: border-box;
      .mainSection-issuerName {
        font-size: 0.85rem;
        font-family: "Product Sans Light", sans-serif;
        color: ${(props) => props.mainContentTextColor};
      }
      .mainSection-eventName {
        font-family: "Product Sans Light", sans-serif;
        font-size: 1.34rem;
        font-weight: 700;
        color: ${(props) => props.mainContentTextColor};
      }
      .mainSection-location {
        font-family: "Product Sans Light", sans-serif;
        font-size: 0.85rem;
        font-weight: 700;
        color: ${(props) => props.mainContentTextColor};
      }
    }

    .mainSection-content-datePoints,
    .mainSection-content-event-infos {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .points {
        text-align: right;
      }
    }
    .mainSection-content-datePoints {
      margin-top: 15px;
    }
    .mainSection-content-banner {
      display: flex;
      width: 100%;
      height: 120px;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      margin-top: 15px;
      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .mainSection-content-barCode {
      width: 100%;
      margin-top: 20px;
      padding: 0 15px;
      box-sizing: border-box;
      .barCode {
        display: flex;
        height: 100px;
        width: 100%;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 1px 0px;
        box-sizing: border-box;
        img {
          width: 70%;
          height: 70px;
          object-fit: cover;
        }
      }
      .walletId {
        /* font-family: "Product Sans", sans-serif; */
        font-size: 14px;
        color: ${(props) => props.mainContentTextColor};
        text-align: center;
        letter-spacing: 0.025em;
        margin: 7px 0px;
      }
    }
    .mainSection-label,
    .mainSection-event-label {
      font-family: "Product Sans Light", sans-serif;
      font-size: 0.7rem;
      font-weight: 700;
      color: ${(props) => props.mainContentTextColor};
      letter-spacing: 1px;
      text-transform: capitalize;
      margin: 0;
    }
    .mainSection-event-label {
      text-transform: uppercase;
    }
    .mainSection-data {
      font-family: "Product Sans light", sans-serif;
      font-size: 1rem;
      color: ${(props) => props.mainContentTextColor};
      margin: 0;
      font-weight: 600;
    }
  }

  .details_btn {
    display: flex;
    width: fit-content;
    align-items: center;
    /* font-family: "Product Sans", sans-serif; */
    font-size: 0.97rem;
    margin: 20px 0 5px 0;
    :hover {
      color: #3f51b5;
      .MuiSvgIcon-root {
        fill: #3f51b5;
      }
    }
  }

  .dataSection {
    display: flex;
    height: ${(props) => (props.visibilty ? "670px" : "0")};
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: ${(props) => (props.visibilty ? "0px 10px 20px 10px" : "0")};
    opacity: ${(props) => (props.visibilty ? "1" : "0")};
    transition: height 0.3s cubic-bezier(0.4, 0, 1, 1),
      opacity 1s cubic-bezier(0.4, 0, 1, 1);
    gap: 5px;
    .sectionIcon {
      margin-top: 20px;
      gap: 16px;
      align-items: center;
      display: flex;
      .data {
        font-family: "Product Sans Thin", sans-serif;
        font-size: 14px;
        letter-spacing: 1px;
        word-break: break-all;
        margin: 0px;
      }
    }
    .sectionLabel {
      margin-top: 10px;
      .label {
        color: #686767;
        font-family: "Product Sans Light", sans-serif;
        font-size: 0.6rem;
        letter-spacing: 1.5px;
        line-height: 20px;
        text-transform: uppercase;
        margin: 0px;
      }
      .data {
        font-family: "Product Sans Thin", sans-serif;
        font-size: 0.8rem;
        letter-spacing: 0.075em;
        line-height: 20px;
        color: ${(props) => props.textColor};
        word-break: break-all;
        margin: 0px;
      }
      svg {
        color: #3a6ac3;
      }
    }
  }
`;
