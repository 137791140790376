import React from "react";
import { Route, Switch, RouteComponentProps } from "react-router-dom";

import WalletPasses from "../../modules/WalletPasses";
import { useStyles } from "./walletPasses.style";

const WalletPassesRouter: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.rootAppRouter}>
      <Switch>
        <Route path="/" component={WalletPasses} exact />
      </Switch>
    </div>
  );
};

export default WalletPassesRouter;
