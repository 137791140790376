import * as React from "react";
import Box from "@material-ui/core/Box";

import { Loading } from "../../Commons";

import BusWireframeIPhone from "../../Components/BusWireframeIPhone";
import CinemaTicketWireframeIPhone from "../../Components/CinemaTicketWireframeIPhone";
import CouponWireframeIPhone from "../../Components/CouponWireframeIPhone";
import EventWireframeIPhone from "../../Components/EventWireframeIPhone";
import FerryWireframeIPhone from "../../Components/FerryWireframeIPhone";
import FlightWireframeIPhone from "../../Components/FlightWireframeIPhone";
import HotelWireframeIPhone from "../../Components/HotelWireframeIPhone";
import TramWireframeIPhone from "../../Components/TramWireframeIPhone";
import RailWireframeIPhone from "../../Components/RailWireframeIPhone";
import StorecardWireframeIPhone from "../../Components/WireframeIPhone";

import { AppleCardStyled } from "./AppleCard-style";

type cardProps = {
  selectedPass: string;
  loading: boolean;
};
export const AppleCard = (props: cardProps): JSX.Element => {
  const { selectedPass, loading } = props;
  const iphoneWireframes: any = {
    storecard: (
      <StorecardWireframeIPhone
        backgroundColor={{ hex: "rgba(70,70,247,0.1)", rgb: "rgb(0,0,0)" }}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
        name={"Best - Demo"}
        textColor={"#000"}
        titleColor={"#1C423D"}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/storecard-strip.jpg"
        }
        address={"65 St. Germain Street"}
        descriptions={[]}
        openingHours={"..."}
        additionalContacts={[]}
        // facebook={"https://facebook.fr"}
        // linkedin={"https://linkedin.fr"}
        website={"https://bestwallet.fr"}
        businessPhone={"01.93.62.36.64"}
        servicePhone={"01.93.62.36.64"}
        // instagram={"https://instagram.fr"}
        gainUnit={"$"}
        displayWalletName={true}
      />
    ),
    coupon: (
      <CouponWireframeIPhone
        backgroundColor={{ hex: "#f7f3ff", rgb: "rgb(0,0,0)" }}
        image={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/coupon-strip.jpg"
        }
        promotion={"77 $"}
        promoCode={"B3S7D3M0"}
        description={
          "Enjoy Your Fre Coupon by using this coupon in your favorite store"
        }
        titleColor={"#003445"}
        textColor={"#000"}
        name={"BEST COUPON"}
        symbol={"symbol"}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
        expires={"27/07/2077"}
      />
    ),
    event: (
      <EventWireframeIPhone
        backgroundColor={{ hex: "#F9F2ED", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/event-strip.jpg"
        }
        price={"100"}
        endDate={"27 Jul 2077 22:00"}
        startDate={"27 Jul 2077 19:00"}
        // openDate={"27 Jul 2077 20:00"}
        openDate={"2023-06-21T10:08:25Z"}
        terms={"Only for the BEST"}
        name={"BEST Event"}
        locationName={"Élysée Montmartre"}
        subscriptionMessage={"Happy Party!"}
        titleColor={"#003445"}
        textColor={"#000"}
        QrCode={{ value: "556655665566" }}
        locations={[{ latitude: 0, longitude: 0 }]}
        email={"contact@orgazone.fr"}
        contactPhone={"+330389230246"}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
      />
    ),
    flight: (
      <FlightWireframeIPhone
        titleColor={"#003445"}
        textColor={"#000"}
        customerServiceNumber={123456789}
        backgroundColor={{ hex: "#EAF9F4", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/billet-back.jpg"
        }
        number={"13"}
        destinationTerminal={"INTL"}
        originTerminal={"INTL"}
        originGate={"A1"}
        destinationGate={"44"}
        locations={["locations"]}
        layovers={"layovers"}
        originIATACode={"CDG"}
        destinationIATACode={"LHR"}
        originAirportName={"Roissy-Charles de Gaulle"}
        destinationAirportName={"Heathrow"}
        departureDateTime={"2023-06-21T10:08:25Z"}
        details={"Have a good trip!"}
        QrCode={{ value: "625eca0f7ecd160007721d8a" }}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
      />
    ),
    hotel: (
      <HotelWireframeIPhone
        backgroundColor={{ hex: "rgba(70,70,247,0.1)", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/hotel-strip.jpg"
        }
        number={"6066e4315abbcc1f9526d392"}
        terms={"Check in 14h"}
        QrCode={{ value: "556655665566" }}
        titleColor={"#003445"}
        textColor={"#000"}
        name={"BEST Hotel"}
        type={"Single"}
        email={"contact@orgazone.fr"}
        address={"Passy banner 10 street"}
        contactPhone={"+3355443322"}
        website={"bestwallet.fr"}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
      />
    ),
    cinema: (
      <CinemaTicketWireframeIPhone
        backgroundColor={{ hex: "#F5F1F1", rgb: "rgb(0,0,0)" }}
        contactPhone={"+3355443322"}
        locations={["test"]}
        email={"contact@orgazone.fr"}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/cinema-strip.jpg"
        }
        price={"8.5 $"}
        endDate={"27 Jul 2077 22:00"}
        startDate={"27 Jul 2077 19:00"}
        openDate={"27 Jul 2077 20:00"}
        terms={"come on time"}
        name={"LE FILM"}
        locationName={"83 Boulevard Montparnasse"}
        titleColor={"#003445"}
        textColor={"#000"}
        QrCode={{ value: "556655665566" }}
        movieName={"LE FILM"}
        rating={"PG-13"}
        screen={3}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
      />
    ),
    ferry: (
      <FerryWireframeIPhone
        backgroundColor={{ hex: "#EAF9F4", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/billet-back.jpg"
        }
        number={"375"}
        originStationCode={"FR"}
        originName={"Calais France"}
        destinationName={`Douvres royaume uni`}
        destinationStationCode={"UK"}
        departureTime={"24/07/2077 4:11 AM"}
        arrivalTime={"24/07/2077 8:11 AM"}
        tarif={"177"}
        customerServiceNumber={"+3355443322"}
        details={"Changeable Alternate Traverse Be Flexible"}
        terms={"Last check-in 45 minutes before departure"}
        QrCode={{ value: "556655665566" }}
        titleColor={"#003445"}
        textColor={"#000"}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
      />
    ),
    tram: (
      <TramWireframeIPhone
        backgroundColor={{ hex: "#EAF9F4", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/ticket-back.jpg"
        }
        number={"62"}
        originStationCode={"LDN"}
        originName={"Londo st-pancars"}
        destinationName={"PARIS NORTH STATION"}
        destinationStationCode={"PAR"}
        departureTime={"24/07/2077 9:01 AM"}
        arrivalTime={"24/07/2077 12:17 PM"}
        tarif={"100"}
        customerServiceNumber={"+3355443322"}
        details={"Details"}
        terms={"Terms"}
        QrCode={{ value: "556655665566" }}
        titleColor={"#003445"}
        textColor={"#000"}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
      />
    ),
    rail: (
      <RailWireframeIPhone
        backgroundColor={{ hex: "#EAF9F4", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/ticket-back.jpg"
        }
        number={"45"}
        originStationCode={"PAR"}
        originName={"PARIS EAST STATION"}
        destinationName={`STOCKHOLM CENTRAL`}
        destinationStationCode={"STO"}
        departureTime={"24/07/2077 4:16 AM"}
        arrivalTime={"24/07/2077 6:16 AM"}
        tarif={"100"}
        customerServiceNumber={"+3355443322"}
        details={"Details"}
        terms={"terms"}
        QrCode={{ value: "556655665566" }}
        titleColor={"#003445"}
        textColor={"#000"}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
      />
    ),
    bus: (
      <BusWireframeIPhone
        backgroundColor={{ hex: "#EAF9F4", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/ticket-back.jpg"
        }
        number={"17"}
        originStationCode={"PF"}
        originName={"GR Paris Bercy-Seine"}
        destinationName={`GR Marseille St-Charles`}
        destinationStationCode={"HND"}
        departureTime={"24 jul., 04:11"}
        arrivalTime={"08:11"}
        tarif={"25"}
        customerServiceNumber={"+3355443322"}
        details={
          "Exchangeable and refundable free of charge by voucher up to 8 days before your depature."
        }
        terms={"Termes"}
        QrCode={{ value: "556655665566" }}
        titleColor={"#003445"}
        textColor={"#000"}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
      />
    ),
  };

  return (
    <AppleCardStyled>
      <Box width={340} maxWidth={340} minHeight={450}>
        {loading ? <Loading /> : iphoneWireframes[selectedPass]}
      </Box>
    </AppleCardStyled>
  );
};
