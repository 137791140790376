import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import WalletPassesRouter from "./routes/WalletPassesRouter";
import enMessages from "./Languages/messages.en";
import frMessages from "./Languages/messages.fr";
import { IntlProvider } from "react-intl";
import { getBrowserLanguage } from "./utils/languageDetector";
function App() {
  const [currentLocale, setCurrentLocale] = useState(getInitialLocal());
  //localstorage
  function getInitialLocal() {
    const savedLocale = localStorage.getItem("language");
    return getBrowserLanguage() || savedLocale || "en";
  }

  useEffect(() => {
    setCurrentLocale(getBrowserLanguage() || "en");
  }, [currentLocale]);

  const messages: any = {
    en: enMessages,
    fr: frMessages,
  };

  return (
    <div className="App">
      <IntlProvider
        locale={currentLocale}
        messages={messages[`${currentLocale}`]}
      >
        <Router>
          <Switch>
            <Route path="/" component={WalletPassesRouter} />
          </Switch>
        </Router>
      </IntlProvider>
    </div>
  );
}

export default App;
