import React from "react";
import { useIntl } from "react-intl";
import { getDataFromCache } from "../../utils/cache";
import { ic_call, ic_public, ic_location_on } from "react-icons-kit/md";
import { ratio } from "wcag-color";
import Icon from "react-icons-kit";
import QRCode from "qrcode.react";
//images and icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import barcode from "../../Assets/images/barcode.png";
import cover from "../../Assets/images/lg-thumbnail.png";
import defaultLogo from "../../Assets/images/sm-thumbnail.png";
//styles
import { Wrapper } from "./ferryWireframe.style";
import { StyledMainSection } from "../../Commons/CommonStyles/CommonStyles";
import {
  StyledDetailsBotton,
  StyledDetailsBottonContainer,
  StyledExpandMoreIcon,
  StyledHeaderContainer,
  StyledSeperator,
} from "../../Commons";

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  originStationCode: any;
  originName: any;
  destinationName: any;
  destinationStationCode: any;
  departureTime: any;
  arrivalTime: any;
  tarif: any;
  customerServiceNumber: any;
  details: any;
  terms: any;
  QrCode: any;
  name: string;
  logo?: any;
};

const FerryWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    number,
    departureTime,
    arrivalTime,
    tarif,
    details,
    QrCode,
    logo,
  } = props;

  const intl = useIntl();

  const currentWallet = getDataFromCache("wallet");
  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>("");

  const handleChange = () => {
    setShowDetails(!showDetails);
    height === "100%" ? setHeight("100%") : setHeight("100%");
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      height={height}
      visibilty={showDetails}
    >
      <StyledMainSection
        backgroundColor={backgroundColor}
        className="mainSection"
      >
        <StyledHeaderContainer
          mainContentTextColor={
            ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
          }
        >
          <div className="mainSection-header-logo">
            <img
              src={
                !logo
                  ? defaultLogo
                  : typeof logo === "string"
                  ? logo
                  : URL.createObjectURL(logo)
              }
              alt="wallet Logo"
            />
          </div>
          <h4>Best - Demo</h4>
        </StyledHeaderContainer>
        <div className="mainSection-content-enseigne">
          <div className="ferry-locations">
            <span className="ferry-location-departure">Calais France</span>
            {" - "}
            <span className="ferry-location-arrival">Douvres Royaume Uni</span>
          </div>
          <div className="ferry-locations-codes">
            <span className="ferry-location-departure-code">FR</span>
            <ArrowForwardIcon />

            <span className="ferry-location-arrival-code">UK</span>
          </div>
          <div className="flight-infos-container">
            <div className="flight-info-wrapper">
              <span className="flight-info-label info-label">
                {intl.formatMessage({ id: "ship.label.departureDate" })}
              </span>
              <span className="flight-info-value">{departureTime}</span>
            </div>

            <div className="flight-info-wrapper">
              <span className="flight-info-label info-label">
                {" "}
                {intl.formatMessage({ id: "transit.label.departureDate" })}
              </span>
              <span className="flight-info-value">{arrivalTime}</span>
            </div>
          </div>
          <StyledSeperator></StyledSeperator>
          <div className="passenger-infos-wrapper">
            <div className="passenger-info-wrapper">
              <span className="passenger-info-label info-label">
                {intl.formatMessage({ id: "ship.label.passenger" })}
              </span>
              <span className="passenger-info-value">Alan Jason</span>
            </div>
          </div>
        </div>

        <div className="mainSection-content-barCode">
          {QrCode ? (
            <div className="qr-code">
              <QRCode value={QrCode.value} />
            </div>
          ) : (
            <>
              <div className="barCode">
                <img src={barcode} alt="barcode" />
              </div>
            </>
          )}
          <p className="walletId">6066e4315abbcc1f9526d392</p>
        </div>
      </StyledMainSection>
      <StyledDetailsBottonContainer>
        <StyledDetailsBotton
          disableRipple
          onClick={handleChange}
          startIcon={<StyledExpandMoreIcon showDetails={showDetails} />}
          variant="outlined"
        >
          Details
        </StyledDetailsBotton>
      </StyledDetailsBottonContainer>

      <div className="dataSection">
        {showDetails && (
          <>
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({ id: "ship.label.departureDate" })}
              </h3>
              <h3 className="data">{departureTime}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({ id: "ship.label.arrivalDate" })}
              </h3>
              <h3 className="data"> {arrivalTime}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({ id: "ship.label.tarif" })}
              </h3>
              <h3 className="data">{`${tarif} $` || ""}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({ id: "ship.label.number" })}
              </h3>
              <h3 className="data">{number}</h3>
            </div>
            <div className="mainSection-content-banner">
              <img
                src={
                  !strip
                    ? cover
                    : typeof strip === "string"
                    ? strip
                    : URL.createObjectURL(strip)
                }
                alt="banner"
              />
            </div>
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({ id: "ship.label.details" })}
              </h3>
              <h3 className="data">{details}</h3>
            </div>
            <div className="sectionIcon">
              <Icon
                icon={ic_location_on}
                size={23}
                style={{ color: "#444444" }}
              />
              <h3 className="data">Nearby Locations</h3>
            </div>
            <div className="sectionIcon">
              <Icon icon={ic_call} size={23} style={{ color: "#444444" }} />
              <h3 className="data">Call Customer Service</h3>
            </div>
            <div className="sectionIcon">
              <Icon icon={ic_public} size={23} style={{ color: "#444444" }} />
              <h3 className="data">Disable notifications</h3>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default FerryWireframeAndroid;
