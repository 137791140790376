import React from "react";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import { FilledInputProps } from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { InputBase } from "@material-ui/core";

type InputProps = FilledInputProps & {
  label?: string;
  msgError?: string;
  dataTestId?: string;
  multiline?: boolean;
  rows?: number | string;
  required?: boolean;
  maxLength?: number;
  step?: number;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      msgError,
      dataTestId,
      multiline,
      rows,
      style,
      required,
      inputProps,
      maxLength,
      endAdornment,
      step,
      ...restProps
    },
    ref
  ) => {
    return (
      <FormControlStyled
        variant="filled"
        error={msgError !== "" && msgError !== undefined}
        style={style}
        required={required || false}
        label={label ? 1 : 0}
      >
        <InputLabel htmlFor="component-filled" shrink>
          {label}
        </InputLabel>

        <InputBase
          ref={ref}
          inputProps={{
            className: "mainInput",
            "data-testid": dataTestId,
            maxLength,
            step,
          }}
          {...restProps}
          multiline={multiline}
          rows={rows}
          endAdornment={endAdornment}
        />
        {msgError && <FormHelperText> {msgError} </FormHelperText>}
      </FormControlStyled>
    );
  }
);

const FormControlStyled = styled(FormControl)<{ label: number }>`
  width: 100%;
  .MuiInputBase-root {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 2px 10px 0px #00000026;
  }
  .mainInput {
    overflow: hidden;
    background-color: #ffffff;
    padding: ${(props) => (props.label ? "25px 19px 6px" : "15px 12px 15px")};
    border-radius: 12px;
  }
`;

export default Input;
