import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="20"
      fill="none"
      viewBox="0 0 43 32"
    >
      <path
        fill="#4646f7"
        fillRule="evenodd"
        d="M3.076.14C.936.654-.412 2.819.13 4.877c.165.628.474 1.19.896 1.625.377.389 18.265 12.249 18.92 12.544.998.45 2.187.433 3.205-.044.8-.375 18.323-12.005 18.768-12.456 2.092-2.12.95-5.712-2.036-6.41-.786-.184-36.04-.18-36.806.004zM0 18.693c0 6.744.028 9.84.093 10.146.307 1.468 1.506 2.665 3.022 3.02.791.184 35.834.19 36.713.005 1.528-.32 2.764-1.536 3.076-3.025.107-.508.135-19.87.03-19.823-.035.015-4.096 2.696-9.025 5.959-4.929 3.262-9.232 6.066-9.562 6.23-.928.464-1.716.641-2.849.641-1.133 0-1.92-.177-2.848-.64-.33-.165-4.633-2.97-9.562-6.231C4.158 11.712.098 9.03.063 9.015.028 9 0 13.356 0 18.694z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
