import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Icon from "react-icons-kit";
import moment from "moment";
import { useIntl } from "react-intl";
import { ratio } from "wcag-color";
import { ic_more_horiz } from "react-icons-kit/md/ic_more_horiz";

import barcode from "../../Assets/images/barcode.png";
import defaultLogo from "../../Assets/images/sm-thumbnail.png";
import cover from "../../Assets/images/lg-thumbnail.png";

import { Wrapper } from "./wireframe.style";

import {
  additionalContacts,
  description,
} from "../walletForm/walletForm.types";

type WireframeProps = {
  backgroundColor: any;
  name: any;
  logo: any;
  strip: any;
  address: any;
  descriptions: any;
  openingHours: any;
  additionalContacts: any;
  titleColor: any;
  textColor: any;
  facebook?: any;
  linkedin?: any;
  website: any;
  businessPhone: any;
  servicePhone: any;
  instagram?: any;
  gainUnit: string;
  displayWalletName: boolean;
};

const WireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    logo,
    name,
    strip,
    address,
    descriptions,
    openingHours,
    titleColor,
    textColor,
    additionalContacts,
    facebook,
    linkedin,
    website,
    businessPhone,
    servicePhone,
    instagram,
    gainUnit,
    displayWalletName,
  } = props;
  const intl = useIntl();

  const [showDetails, setShowDetails] = React.useState(false);

  const backFlip = () => {
    setShowDetails((v) => !v);
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      titleColor={titleColor}
      textColor={textColor}
      flip={showDetails}
    >
      <div className="pass">
        <div className="mainSection">
          <div className="mainSection-header">
            <div className="mainSection-header-logo">
              <img
                src={
                  !logo
                    ? defaultLogo
                    : typeof logo === "string"
                    ? logo
                    : URL.createObjectURL(logo)
                }
                alt="wallet Logo"
              />
            </div>

            <div className="mainSection-content-enseigne">
              <h4> {displayWalletName ? name : ""}</h4>
            </div>
            <div>
              <h3 className="label" style={{ textAlign: "right" }}>
                0.00 {gainUnit !== "points" ? gainUnit : ""}
              </h3>
              <h3 className="data">Silver</h3>
            </div>
          </div>

          <div className="mainSection-content-banner">
            <img
              src={
                !strip
                  ? cover
                  : typeof strip === "string"
                  ? strip
                  : URL.createObjectURL(strip)
              }
              alt="banner"
            />
          </div>
          <div className="mainSection-content-datePoints">
            <div className="date">
              <h3 className="mainSection-label">Date</h3>
              <h3 className="mainSection-data">{moment().format("L")}</h3>
            </div>
            <div className="points">
              <h3 className="mainSection-label">Rewards</h3>
              <h3 className="mainSection-data">
                0.00 {gainUnit !== "points" ? gainUnit : ""}
              </h3>
            </div>
          </div>
          <div className="mainSection-content-barCode">
            <div className="barCode">
              <img src={barcode} alt="barcode" />
              <p className="walletId">5g1hdvb82154df853fca14835</p>
            </div>
          </div>
          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>
        <div className="dataSection">
          {descriptions.length > 0 && (
            <>
              {descriptions.map((elm: description, index: number) => (
                <div className="sectionLabel" key={index}>
                  <h3 className="label">
                    {elm.title ? elm.title : '"Description Title"'}
                  </h3>
                  <h3 className="data">{elm.value ? elm.value : '"Value"'}</h3>
                </div>
              ))}
              <span className="divider"></span>
            </>
          )}
          <div className="sectionLabel">
            <h3 className="label" style={{ marginBottom: "6px" }}>
              Ready to wear for men and women
            </h3>
            <h3 className="data" style={{ lineHeight: "20px" }}>
              Come and discover ourcollections of well- known and recognized
              brands and our other little nuggets ...
            </h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label" style={{ textTransform: "capitalize" }}>
              Alan{" "}
            </h3>
            <h3 className="data">Jason</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Type</h3>
            <h3 className="data">Silver</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {intl.formatMessage({
                id: "wireframes.storecard.label.adress",
              })}
            </h3>
            <h3 className="data">{address ? address : "Adresse"}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {intl.formatMessage({
                id: "wireframes.storecard.label.schedule",
              })}
            </h3>
            <h3 className="data">
              {openingHours ? openingHours : '"Horaires"'}
            </h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {intl.formatMessage({
                id: "wireframes.storecard.label.barcode",
              })}
            </h3>
            <h3 className="data">6066e4315abbcc1f9526d392</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {intl.formatMessage({
                id: "wireframes.storecard.label.offers",
              })}
            </h3>
            <h3 className="data">No offers</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {intl.formatMessage({
                id: "wireframes.storecard.label.coupons",
              })}
            </h3>
            <h3 className="data">No coupons</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label"> {name} respond to you on:</h3>
            <h3 className="data">
              {intl.formatMessage({
                id: "wireframes.storecard.label.commercialPhone",
              })}{" "}
              : <div style={{ color: "#3775d1" }}>{businessPhone}</div>
            </h3>
            <h3 className="data">
              {intl.formatMessage({ id: "wireframes.storecard.label.SAV" })}{" "}
              <div style={{ color: "#3775d1" }}> {servicePhone} </div>
            </h3>
          </div>
          {website && <span className="divider"></span>}
          {website && (
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({
                  id: "wireframes.storecard.label.website",
                })}
              </h3>
              <h3 className="data" style={{ color: "#3775d1" }}>
                {website}
              </h3>
            </div>
          )}
          {(linkedin || facebook || instagram) && (
            <span className="divider"></span>
          )}
          {(linkedin || facebook || instagram) && (
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({
                  id: "wireframes.storecard.label.socialMedia",
                })}
              </h3>
              {facebook && (
                <h3 className="data">
                  Facebook :<div style={{ color: "#3775d1" }}>{facebook}</div>
                </h3>
              )}
              {linkedin && (
                <h3 className="data">
                  Linkedin :<div style={{ color: "#3775d1" }}>{linkedin}</div>
                </h3>
              )}
              {instagram && (
                <h3 className="data">
                  Instagram :<div style={{ color: "#3775d1" }}>{instagram}</div>
                </h3>
              )}
            </div>
          )}
          {additionalContacts.length >= 1 && (
            <>
              <span className="divider"></span>
              additionalContacts[0].title !== "" && (
              <div className="sectionLabel">
                {additionalContacts.map(
                  (elm: additionalContacts, index: number) => (
                    <h3 className="label">
                      {elm.title} : {elm.value}
                    </h3>
                  )
                )}
              </div>
              )
            </>
          )}
          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>
      </div>
    </Wrapper>
  );
};

export default WireframeIPhone;
