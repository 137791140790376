import styled from "styled-components";

export const Wrapper = styled("div")<{
  backgroundColor: any;
  mainContentTextColor: string;
  textColor: string;
  titleColor: string;
  flip: boolean;
}>`
  perspective: 1000px;
  .pass {
    position: relative;
    transform: ${(props) => (props.flip ? "rotateY(180deg)" : "rotateY(0)")};
    transition: transform 0.6s;
    transform-style: preserve-3d;
    .mainSection {
      position: absolute;
      display: flex;
      width: 100%;
      height: fit-content;
      flex-direction: column;
      background-color: ${(props) => props.backgroundColor.hex};
      font-family: "Red Hat Text", sans-serif;
      border-radius: 10px;
      border: 1px solid rgb(135 129 129 / 24%);
      box-sizing: border-box;
      backface-visibility: hidden;
      .mainSection-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 8px 12px;
        box-sizing: border-box;
        .mainSection-header-logo {
          height: 48px;
          width: 48px;
          img {
            height: 48px;
            width: 48px;
            object-fit: contain;
          }
        }

        .mainSection-content-enseigne {
          display: flex;
          flex: 1;
          align-items: center;
          h4 {
            font-size: 1.1rem;
            font-weight: 450;
            text-align: center;
            color: ${(props) => props.textColor};
            margin: 0;
            margin-left: 12px;
            word-break: break-all;
          }
        }
        div {
          .label {
            color: ${(props) => props.titleColor};
            font-weight: 650;
            text-transform: uppercase;
            font-size: 0.6rem;
            letter-spacing: 0.5px;
            line-height: 16px;
            margin: 0px;
          }
          .data {
            color: ${(props) => props.textColor};
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 20px;
            margin: 0px;
          }
        }
      }

      .mainSection-content-banner {
        height: 120px;
        width: 100%;
        overflow: hidden;

        margin-top: 2px;
        img {
          object-fit: cover;
          height: 120px;
          width: 100%;
        }
      }
      .mainSection-content-name {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        h5 {
          text-align: center;
          color: ${(props) => props.titleColor};
          margin: 0;
          margin-left: 15px;
          word-break: break-all;
        }
      }

      .mainSection-content-datePoints {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        margin-top: 10px;
        box-sizing: border-box;

        .points {
          text-align: right;
        }
      }
      .mainSection-content-barCode {
        margin-top: 133px;
        margin-bottom: 35px;
        .barCode {
          width: 250px;
          margin: auto;
          background: #ffffff;
          height: 90px;
          display: flex;
          border-radius: 4px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          img {
            width: 220px;
            height: 40px;
            object-fit: cover;
            margin-top: 15px;
          }
        }
        .walletId {
          font-size: 14px;
          font-weight: 450;
          text-align: center;
          margin: 7px 0px;
        }
      }
      .mainSection-label {
        color: ${(props) => props.titleColor};
        font-weight: 650;
        text-transform: uppercase;
        font-size: 0.6rem;
        letter-spacing: 0.25px;
        margin: 0px;
      }
      .mainSection-data {
        color: ${(props) => props.textColor};
        font-size: 1.3rem;
        font-weight: 400;
        margin: 0px;
      }
    }
    .dataSection {
      position: ${(props) => (props.flip ? "unset" : "absolute")};
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: #fff;
      padding: 6px 14px 8px 14px;
      font-family: "Red Hat Text", sans-serif;
      border: 1px solid rgb(135 129 129 / 24%);
      border-radius: 10px;
      box-sizing: border-box;
      backface-visibility: hidden;
      transform: rotateY(180deg);
      transition: position 0.6s;
      .sectionLabel {
        line-height: 16px;
        .label {
          color: #939393;
          font-weight: 450;
          font-size: 0.72rem;
          text-transform: uppercase;
          letter-spacing: 0.025em;
          margin: 0 0 8px 0;
        }
        .data {
          display: flex;
          color: #939393;
          font-weight: 450;
          font-size: 0.92rem;
          /* word-break: break-all; */
          gap: 8px;
          margin: 8px 0;
        }
      }
      .divider {
        border-bottom: 1px solid #eeedf1;
        margin: 5px 0;
        width: 100%;
      }
    }
    .backFlip_btn {
      position: absolute;
      bottom: 5px;
      right: 5px;
      transform: ${(props) => (props.flip ? "rotateY(180deg)" : "rotateY(0)")};
    }
  }
`;
