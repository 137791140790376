import React from "react";
import { useIntl } from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import { ic_more_horiz } from "react-icons-kit/md/ic_more_horiz";
import Icon from "react-icons-kit";
import { ratio } from "wcag-color";
import QRCode from "qrcode.react";

import barcode from "../../Assets/images/barcode.png";

import { getDataFromCache } from "../../utils/cache";
import { URL_IMAGE } from "../../apis/config";

import { Wrapper } from "./busWireframe.style";

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  originStationCode: any;
  originName: any;
  destinationName: any;
  destinationStationCode: any;
  departureTime: any;
  arrivalTime: any;
  tarif: any;
  customerServiceNumber: any;
  details: any;
  terms: any;
  QrCode: any;
  titleColor?: any;
  textColor?: any;
  state?: any;
  logo?: any;
};

const BusWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    // strip,
    number,
    originStationCode,
    originName,
    destinationName,
    destinationStationCode,
    // departureTime,
    // arrivalTime,
    tarif,
    // customerServiceNumber,
    details,
    terms,
    QrCode,
    titleColor,
    textColor,
    logo,
  } = props;

  const intl = useIntl();

  const currentWallet = getDataFromCache("wallet");
  const [showDetails, setShowDetails] = React.useState(false);

  const backFlip = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      titleColor={titleColor}
      textColor={textColor}
      flip={showDetails}
    >
      <div className="pass">
        <div className="mainSection">
          <div className="firstSection">
            <div className="mainSection-header-logo">
              <img
                src={
                  logo ? logo : `${URL_IMAGE}/${currentWallet?.logoPictureUrl}`
                }
                alt="wallet Logo"
              />
            </div>
            <div className="number">
              <div className="date">
                <h3 className="mainSection-label">
                  {" "}
                  {intl.formatMessage({ id: "ship.label.number" })}
                </h3>
                <h3 className="mainSection-data" style={{ textAlign: "right" }}>
                  {number || "N de place"}
                </h3>
              </div>
            </div>
          </div>

          <div className="mainSection-origin-destination">
            <div className="origin">
              <h3 className="mainSection-origin-name">{originName}</h3>
              <h3 className="mainSection-origin-stationCode">
                {originStationCode}
              </h3>
            </div>
            <svg
              width="26"
              height="31"
              viewBox="0 0 26 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.86523 0.0902111C3.4375 0.322633 2.25195 0.909547 1.39648 1.80994C0.804688 2.43005 0.410156 3.16443 0.15625 4.11267L0 4.69959V15.0023V25.3051L0.134766 25.6469C0.398438 26.3256 0.884766 26.7269 1.59961 26.8568L1.95117 26.9213L1.97656 28.1732C1.99219 28.9808 2.01953 29.4232 2.11914 29.7465C2.19727 30.0033 2.32227 30.1849 2.52148 30.4144C2.9082 30.8588 3.25586 30.9974 3.98633 30.9994C4.51172 31.0013 4.60742 30.9838 4.90625 30.8324C5.29492 30.6351 5.56055 30.3441 5.75586 29.9027C5.95312 29.4564 6.00781 29.0248 6.00781 27.8959V26.9105H12.9844H19.959L19.9609 27.8959C19.9609 29.0248 20.0156 29.4564 20.2129 29.9027C20.4082 30.3441 20.6738 30.6351 21.0625 30.8324C21.3613 30.9838 21.459 31.0013 21.9824 31.0004C22.6465 30.9994 22.998 30.8773 23.3496 30.5287C23.8652 30.0179 24.002 29.4838 24.0039 27.9906V26.9193L24.3418 26.848C24.9375 26.723 25.3848 26.4418 25.6602 26.0267C25.791 25.8295 25.8828 25.6019 25.9336 25.347C25.9941 25.0463 26.0137 15.5912 25.9902 9.57459C25.9805 6.92322 25.9629 4.93982 25.9355 4.70056C25.7637 3.14685 25.1582 1.85974 24.2539 1.12439C23.5742 0.572633 22.7988 0.240602 21.8457 0.0911876C21.0684 -0.0299061 5.60938 -0.0299061 4.86523 0.0902111ZM5 3.50037C5 2.67224 5.67188 2.00037 6.5 2.00037H19.5C20.3281 2.00037 21 2.67224 21 3.50037C21 4.32849 20.3281 5.00037 19.5 5.00037H6.5C5.67188 5.00037 5 4.32849 5 3.50037ZM5 8.00024C3.89543 8.00024 3 8.89567 3 10.0002V14.0002C3 15.1048 3.89543 16.0002 5 16.0002H21C22.1046 16.0002 23 15.1048 23 14.0002V10.0002C23 8.89567 22.1046 8.00024 21 8.00024H5ZM23 21.0001C23 22.1047 22.1046 23.0001 21 23.0001C19.8954 23.0001 19 22.1047 19 21.0001C19 19.8956 19.8954 19.0001 21 19.0001C22.1046 19.0001 23 19.8956 23 21.0001ZM5 23.0001C6.10457 23.0001 7 22.1047 7 21.0001C7 19.8956 6.10457 19.0001 5 19.0001C3.89543 19.0001 3 19.8956 3 21.0001C3 22.1047 3.89543 23.0001 5 23.0001Z"
                fill="black"
              />
            </svg>
            <div className="destination">
              <h3
                className="mainSection-destination-name"
                style={{ textAlign: "right" }}
              >
                {destinationName}
              </h3>
              <h3
                className="mainSection-destination-destinationCode"
                style={{ textAlign: "right" }}
              >
                {destinationStationCode}
              </h3>
            </div>
          </div>

          <div className="firstSection" style={{ marginTop: 14 }}>
            <div className="number">
              <div className="date">
                <h3 className="mainSection-label">Departure Date</h3>
                <h3 className="mainSection-data-2">
                  {/* {departureTime} */}
                  24/7/2077, 04:14
                </h3>
              </div>
            </div>
            <div className="number">
              <div className="date">
                <h3 className="mainSection-label">Arrival Date</h3>
                <h3 className="mainSection-data-2">
                  {/* {arrivalTime} */}
                  24/7/2077, 06:14
                </h3>
              </div>
            </div>
            <div className="number">
              <div className="date">
                <h3 className="mainSection-label">
                  {intl.formatMessage({ id: "ship.label.tarif" })}
                </h3>
                <h3 className="mainSection-data-2"> {`${tarif} $` || ""}</h3>
              </div>
            </div>
          </div>

          <div className="firstSection" style={{ marginTop: 24 }}>
            <div className="date">
              <h3 className="mainSection-label">
                {intl.formatMessage({ id: "ship.label.passenger" })}
              </h3>
              <h3 className="mainSection-data-3">Alan Jason</h3>
            </div>
          </div>

          <div className="mainSection-content-barCode">
            {QrCode ? (
              <div className="qrCode">
                <QRCode value={QrCode.value} />
                <p className="walletId">6066e4315abbcc1f9526d392</p>
              </div>
            ) : (
              <div className="barCode">
                <img src={barcode} alt="barcode" />
                <p className="walletId">6066e4315abbcc1f9526d392</p>
              </div>
            )}
          </div>
          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>
        <div className="dataSection">
          <div className="sectionLabel">
            <h3 className="label">Details</h3>
            <h3 className="data">{`${details}` || ""}</h3>
          </div>
          <span className="divider" />
          <div className="sectionLabel">
            <h3 className="label">Terms & conditions</h3>
            <h3 className="data">{terms || ""}</h3>
          </div>
          <span className="divider" />
          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>
        <div className="round1" />
        <div className="round2" />
        <div className="hidden-round1" />
        <div className="hidden-round2" />
      </div>
    </Wrapper>
  );
};

export default BusWireframeIPhone;
