import styled from "styled-components";

export const Wrapper = styled("div")<{
  backgroundColor: any;
  mainContentTextColor: string;
  titleColor: any;
  textColor: any;
  visibilty?: boolean;
}>`
  .mainSection {
    .place-infos-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .place-info-wrapper {
        flex: 1 1 0px;
        display: flex;
        flex-direction: column;
        gap: 2px;
        .place-info-label {
        }
        .place-info-value {
          font-weight: 900;
          font-family: "Product Sans light", sans-serif;
          font-size: 1.1rem;
        }
      }
      & div:nth-child(1) {
        text-align: left;
      }
      & div:nth-child(2) {
        text-align: center;
        span {
          text-transform: uppercase;
        }
      }
      & div:nth-child(3) {
        text-align: end;
      }
    }

    .movie-infos-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 18px;
      .movie-info-wrapper {
        flex: 1 1 0px;
        display: flex;
        flex-direction: column;
        gap: 2px;
        .movie-info-label {
        }
        .movie-info-value {
          font-weight: 900;
          font-family: "Product Sans light", sans-serif;
          font-size: 1.1rem;
        }
      }
      & div:nth-child(1) {
        text-align: left;
      }
      & div:nth-child(2) {
        text-align: end;
      }
    }

    .info-label {
      font-weight: 700;
      font-family: "Product Sans light", sans-serif;
      font-size: 0.7rem;
    }

    .mainSection-content-enseigne {
      width: 100%;
      font-family: "Product Sans Light", sans-serif;
      padding: 16px 16px 0px 16px;
      box-sizing: border-box;
      .mainSection-issuerName {
        font-size: 0.875rem;
        font-family: "Product Sans Light", sans-serif;
        line-height: 1.25rem;
        color: ${(props) => props.mainContentTextColor};
      }
      .mainSection-eventName {
        font-weight: 600;
        font-family: "Product Sans Light", sans-serif;
        font-size: 1.3rem;
        color: ${(props) => props.mainContentTextColor};
      }
      .mainSection-location {
        font-weight: 600;
        font-family: "Product Sans Light", sans-serif;
        font-size: 0.875rem;
        color: ${(props) => props.mainContentTextColor};
      }
    }

    .mainSection-content-datePoints {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-family: "Product Sans Light", sans-serif;
      margin-top: 16px;
      .points {
        text-align: right;
      }
    }

    .mainSection-content-banner {
      height: 125px;
      width: 100%;
      overflow: hidden;
      margin-top: 14px;
      img {
        object-fit: cover;
        height: inherit;
        width: 100%;
      }
    }

    .mainSection-content-barCode {
      width: 100%;
      margin-top: 20px;
      padding: 0 30px;
      box-sizing: border-box;
      .barCode {
        display: flex;
        height: 112px;
        width: 100%;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border-radius: 16px;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 1px 0px;
        box-sizing: border-box;
        img {
          height: 78px;
          object-fit: cover;
        }
      }
      .walletId {
        font-family: "Product Sans thin", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        color: ${(props) => props.mainContentTextColor};
        text-align: center;
        letter-spacing: 0.05em;
        margin: 14px 0px 0px;
      }
    }
    /* div.mainSection-content-datePoints:nth-child(6) > div:nth-child(2) {
      margin-left: 8px;
      text-align: center;
    } */

    /* .mainSection-label {
      color: ${(props) => props.mainContentTextColor};
      font-size: 0.68rem;
      text-transform: uppercase;
      margin: 0 0 2px 0;
    }
    .mainSection-data {
      font-family: "Product Sans Thin", sans-serif;
      color: ${(props) => props.mainContentTextColor};
      font-size: 1rem;
      margin: 0;
    } */
  }

  /* .details_btn {
    display: flex;
    width: fit-content;
    align-items: center;
    font-family: "Product Sans", sans-serif;
    font-size: 0.97rem;
    margin: 20px 0 5px 0;
    :hover {
      color: #3f51b5;
      .MuiSvgIcon-root {
        fill: #3f51b5;
      }
    }
  } */

  /* .dataSection {
    display: flex;
    height: ${(props) => (props.visibilty ? "580px" : "0")};
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: ${(props) => (props.visibilty ? "0px 10px 20px 10px" : "0")};
    opacity: ${(props) => (props.visibilty ? "1" : "0")};
    transition: height 0.3s cubic-bezier(0.4, 0, 1, 1),
      opacity 1s cubic-bezier(0.4, 0, 1, 1);
    .sectionIcon {
      margin-top: 20px;
      gap: 16px;
      align-items: center;
      display: flex;
      .data {
        font-family: "Product Sans Light", sans-serif;
        font-size: 14px;
        word-break: break-all;
        margin: 0px;
      }
    }
    .sectionLabel {
      margin-top: 10px;
      .label {
        color: #686767;
        font-family: "Product Sans Light", sans-serif;
        font-size: 0.7rem;
        letter-spacing: 0.075em;
        line-height: 20px;
        text-transform: uppercase;
        margin: 0px;
      }
      .data {
        font-family: "Product Sans Light", sans-serif;
        font-size: 0.95rem;
        letter-spacing: 0.075em;
        line-height: 20px;
        color: ${(props) => props.textColor};
        word-break: break-all;
        margin: 0px;
      }
      svg {
        color: #3a6ac3;
      }
    }
  } */
`;
