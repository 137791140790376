import styled from "styled-components";

export const Wrapper = styled("div")<{
  backgroundColor: any;
  mainContentTextColor: string;
  height: any;
  visibilty?: boolean;
}>`
  .mainSection {
    .mainSection-header-left-side {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    .mainSection-content-enseigne {
      width: 100%;
      font-family: "Product Sans Light", sans-serif;
      padding: 20px 16px 16px 16px;
      box-sizing: border-box;
      .flight-locations {
        margin-bottom: 4px;
        font-weight: 600;
      }
      .flight-location-departure,
      .flight-location-arrival {
        font-weight: 600;
        font-size: 0.9rem;
      }
      .flight-locations-codes {
        display: flex;
        align-items: center;
        justify-content: space-=between;
        gap: 16px;
      }
      .flight-location-arrival-code,
      .flight-location-departure-code {
        font-size: 2rem;
        font-weight: 600;
      }

      .flight-infos-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 18px;
        .flight-info-wrapper {
          flex: 1 1 0px;
          display: flex;
          flex-direction: column;
          gap: 2px;
          .flight-info-label {
          }
          .flight-info-value {
            font-weight: 900;
            font-family: "Product Sans light", sans-serif;
            font-size: 1.1rem;
          }
        }
        & div:nth-child(1) {
          text-align: left;
        }
        & div:nth-child(2) {
          text-align: center;
        }
        & div:nth-child(3) {
          text-align: end;
        }
      }

      .passenger-infos-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 18px;
        .passenger-info-wrapper {
          flex: 1 1 0px;
          display: flex;
          flex-direction: column;
          gap: 2px;
          .passenger-info-label {
          }
          .passenger-info-value {
            font-weight: 900;
            font-family: "Product Sans light", sans-serif;
            font-size: 1.1rem;
          }
        }
        & div:nth-child(1) {
          text-align: left;
        }
        & div:nth-child(2) {
          text-align: end;
        }
      }

      .info-label {
        font-weight: 700;
        font-family: "Product Sans light", sans-serif;
        font-size: 0.7rem;
      }

      .mainSection-content-barCode {
        margin: 20px 0 12px;
        .qr-code {
          width: 128px;
          background-color: #ffffff;
          padding: 18px;
          margin: 0 auto;
          border-radius: 12px;
          box-shadow: rgb(0 0 0 / 16%) 0px 0px 4px 1px;
        }
        .barCode {
          width: 270px;
          background: #ffffff;
          height: 100px;
          display: flex;
          border-radius: 3px;
          justify-content: center;
          align-items: center;
          img {
            width: 219px;
            height: 65px;
            object-fit: initial;
          }
        }
        .walletId {
          /* font-family: "Product Sans", sans-serif; */
          font-size: 0.85rem;
          text-align: center;
          letter-spacing: 0.07em;
          color: ${(props) => props.mainContentTextColor};
          margin: 10px 0 7px 0px;
        }
      }
    }
  }

  .dataSection {
    display: flex;
    height: ${(props) => (props.visibilty ? "560px" : "0")};
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: ${(props) => (props.visibilty ? "0px 10px 20px 10px" : "0")};
    opacity: ${(props) => (props.visibilty ? "1" : "0")};
    transition: height 0.3s cubic-bezier(0.4, 0, 1, 1),
      opacity 1s cubic-bezier(0.4, 0, 1, 1);
    .sectionIcon {
      margin-top: 20px;
      gap: 20px;
      align-items: center;
      display: flex;
      .data {
        font-family: "Product Sans Light", sans-serif;
        font-size: 14px;
        word-break: break-all;
        margin: 0px;
      }
    }
    .sectionLabel {
      margin-top: 10px;
      .label {
        color: #686767;
        font-family: "Product Sans Light", sans-serif;
        font-size: 0.7rem;
        letter-spacing: 0.075em;
        line-height: 20px;
        text-transform: uppercase;
        margin: 0px;
      }
      .data {
        font-family: "Product Sans Light", sans-serif;
        font-size: 0.95rem;
        letter-spacing: 0.075em;
        line-height: 20px;
        color: ${(props) => props.mainContentTextColor};
        word-break: break-all;
        margin: 0px;
      }
    }
    .mainSection-content-banner {
      height: 122px;
      width: 100%;
      margin: 16px 0;
      img {
        width: 100%;
        height: 100%;
        border-radius: 3px;
        object-fit: cover;
        box-shadow: rgb(0 0 0 / 16%) 0px 2px 3px 1px;
      }
    }
  }
`;
