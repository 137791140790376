import React from "react";

function Icon({
  width = "28",
  height = "28",
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#C7C7C7"
        fillRule="evenodd"
        d="M23.425 14.351c0 .936.768 1.697 1.712 1.697.477 0 .863.383.863.855v3.077c0 2.603-2.137 4.72-4.763 4.72H7.764C5.138 24.7 3 22.583 3 19.98v-3.078c0-.471.386-.854.862-.854.946 0 1.714-.761 1.714-1.697 0-.912-.737-1.597-1.713-1.597a.865.865 0 01-.61-.251.851.851 0 01-.253-.604l.002-3.178C3.002 6.118 5.14 4 7.766 4h13.468C23.861 4 26 6.118 26 8.72L26 11.8c0 .225-.09.444-.252.604a.865.865 0 01-.61.25c-.945 0-1.713.762-1.713 1.697zm-6.335.744l1.356-1.308a.84.84 0 00-.47-1.437l-1.874-.271-.838-1.682a.848.848 0 00-.762-.47H14.5a.85.85 0 00-.762.469l-.839 1.683-1.87.27a.845.845 0 00-.69.572.833.833 0 00.215.866l1.356 1.308-.32 1.85a.836.836 0 00.34.826.852.852 0 00.894.063l1.676-.873 1.673.87c.29.154.634.13.897-.061a.832.832 0 00.34-.825l-.32-1.85z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
