import styled from "styled-components";

export const WalletPassesStyles = styled.div`
  .navtoolbar {
    position: relative;
    display: flex;
    height: 46px;
    align-items: center;
    background: linear-gradient(
      270deg,
      rgba(70, 70, 247, 1) 0%,
      rgba(81, 138, 237, 0.1) 86.24%
    );

    padding: 10px;
    svg {
      position: absolute;
      height: 46px;
      animation: show-logo 1s cubic-bezier(0.23, 1, 0.32, 1) both;
    }
  }
  .wrapper {
    display: flex;
    height: calc(100vh - 70px);
    justify-content: center;
    margin: 0 auto;
    overflow: auto;
    .container {
      margin: 15px 0;
      display: grid;
      max-width: 800px;
      height: fit-content;
      grid-template-rows: 95px 105px 50px 1fr;
      align-items: center;
      background: rgba(70, 70, 247, 0.05);
      border-radius: 8px;
      box-shadow: 0px 4px 8px 0px #00000040;
      border: 1px solid #e0d8d8;
      animation: show-container 1.5s cubic-bezier(0.23, 1, 0.32, 1) both;
      .header-search {
        padding: 0 40px;
        margin-top: 20px;
        .btn-send {
          position: relative;
          width: 150px;
          height: 49px;
          background: linear-gradient(270deg, #4646f7 -4.44%, #518aed 80.24%);
          color: #fff;
        }
        .Mui-disabled {
          color: rgba(0, 0, 0, 0.26);
          box-shadow: none;
          background: rgba(0, 0, 0, 0.12);
        }
        .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
          margin-top: 0;
        }
        .MuiInputAdornment-positionStart {
          margin: 0 15px 0 40px;
        }
        .MuiInputAdornment-positionEnd {
          margin: 0;
        }
        .button-progress {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #4caf50;
          margin-top: -12px;
          margin-left: -12px;
        }
      }
      .btns-action {
        max-width: 800px;
        .MuiTab-textColorInherit {
          svg {
            width: 46px;
            height: 46px;
            path {
              transition: fill 0.25s ease-out;
            }
            transition: height 0.5s ease-out;
          }
        }
        .MuiTab-textColorInherit.Mui-selected {
          svg {
            width: 56px;
            height: 56px;
            path {
              fill: #4646f7;
              transition: fill 0.2s ease-in;
            }
            transition: height 0.2s ease-in;
          }
        }
        .MuiTab-root {
          min-width: 110px;
          border-radius: 8px;
          height: 86px;
        }
      }
      .btn-wallet {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      .btn-wallet-mobile {
        display: none;
      }
      .wallet-show {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 20px;
        gap: 16px;
      }
      .wallet-show-mobile {
        display: none;
      }
    }
  }

  @keyframes show-logo {
    0% {
      visibility: hidden;
      opacity: 0;
      left: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
      left: 5%;
    }
  }

  @keyframes show-container {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .wrapper {
      overflow-y: auto;
      overflow-x: hidden;
      .container {
        grid-template-rows: 70px 85px 50px 1fr;
        height: fit-content;
        border-radius: 0;
        margin: 0;
        gap: 0;
        .header-search {
          padding: 0 10px;
          .btn-send {
            width: 120px;
          }
          .MuiInputAdornment-positionStart {
            margin-left: 16px;
          }
        }
        .btns-action {
          display: flex;
          max-width: 100vw;
          justify-content: center;
          .MuiTab-textColorInherit {
            svg {
              width: 35px;
              height: 35px;
            }
          }
          .MuiTab-textColorInherit.Mui-selected {
            svg {
              width: 50px;
              height: 50px;
            }
          }
          .MuiTab-root {
            min-width: 80px;
          }
        }
        .btn-wallet {
          display: none;
          height: 0;
          div {
            display: none;
          }
        }
        .btn-wallet-mobile {
          display: grid;
          grid-template-columns: 1fr 1fr;
          height: 100%;
          box-sizing: border-box;
          gap: 4px;
          .gpay-box {
            height: 100%;
            border-radius: 10px 10px 0 0;
          }
          .apay-box {
            height: 100%;
            border-radius: 10px 10px 0 0;
          }
          .selected {
            background: #fff;
          }
        }
        .wallet-show {
          display: none;
        }
        .wallet-show-mobile {
          display: grid;
          grid-template-columns: 1fr;
          height: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 1005px) {
    .wrapper {
      .container {
        max-width: 100vw;
        min-height: calc(100vh - 70px);
        border-radius: 0;
        margin: 0;
        .btns-action {
          display: flex;
          max-width: 99vw;
          justify-content: center;
        }
      }
    }
  }
`;
