import React from "react";
import { useIntl } from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import { ic_more_horiz } from "react-icons-kit/md/ic_more_horiz";
import Icon from "react-icons-kit";
import { ratio } from "wcag-color";
import QRCode from "qrcode.react";

import { getDataFromCache } from "../../utils/cache";
import { URL_IMAGE } from "../../apis/config";

import Ferry from "../../Assets/icons/Ferry";
import barcode from "../../Assets/images/barcode.png";

import { Wrapper } from "./ferryWireframe.style";
type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  originStationCode: any;
  originName: any;
  destinationName: any;
  destinationStationCode: any;
  departureTime: any;
  arrivalTime: any;
  tarif: any;
  customerServiceNumber: any;
  details: any;
  terms: any;
  QrCode: any;
  titleColor?: any;
  textColor?: any;
  state?: any;
  logo?: any;
};

const FerryWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    // strip,
    number,
    originStationCode,
    originName,
    destinationName,
    destinationStationCode,
    departureTime,
    arrivalTime,
    tarif,
    customerServiceNumber,
    details,
    terms,
    QrCode,
    titleColor,
    textColor,
    logo,
  } = props;

  const intl = useIntl();

  const currentWallet = getDataFromCache("wallet");
  const [showDetails, setShowDetails] = React.useState(false);

  const backFlip = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      titleColor={titleColor}
      textColor={textColor}
      flip={showDetails}
    >
      <div className="pass">
        <div className="mainSection">
          <div className="firstSection">
            <div className="mainSection-header-logo">
              <img
                src={
                  logo ? logo : `${URL_IMAGE}/${currentWallet?.logoPictureUrl}`
                }
                alt="wallet Logo"
              />
            </div>
            <div className="number">
              <div className="date">
                <h3 className="mainSection-label">
                  {" "}
                  {intl.formatMessage({ id: "ship.label.number" })}
                </h3>
                <h3 className="mainSection-data" style={{ textAlign: "right" }}>
                  {number || "N de place"}
                </h3>
              </div>
            </div>
          </div>

          <div className="mainSection-origin-destination">
            <div className="origin">
              <h3 className="mainSection-origin-name">{originName}</h3>
              <h3 className="mainSection-origin-stationCode">
                {originStationCode}
              </h3>
            </div>
            <Ferry />
            <div className="destination">
              <h3
                className="mainSection-destination-name"
                style={{ textAlign: "right" }}
              >
                {destinationName}
              </h3>
              <h3
                className="mainSection-destination-destinationCode"
                style={{ textAlign: "right" }}
              >
                {destinationStationCode}
              </h3>
            </div>
          </div>

          <div className="firstSection" style={{ marginTop: 14 }}>
            <div className="number">
              <div className="date">
                <h3 className="mainSection-label">Departure Date</h3>
                <h3 className="mainSection-data-2">{departureTime}</h3>
              </div>
            </div>
            <div className="number">
              <div className="date">
                <h3 className="mainSection-label">Arrival Date</h3>
                <h3 className="mainSection-data-2">{arrivalTime}</h3>
              </div>
            </div>
            <div className="number">
              <div className="date">
                <h3 className="mainSection-label">
                  {intl.formatMessage({ id: "ship.label.tarif" })}
                </h3>
                <h3 className="mainSection-data-2"> {`${tarif} $` || ""}</h3>
              </div>
            </div>
          </div>

          <div className="firstSection" style={{ marginTop: 24 }}>
            <div className="date">
              <h3 className="mainSection-label">
                {intl.formatMessage({ id: "ship.label.passenger" })}
              </h3>
              <h3 className="mainSection-data-3">Alan Jason</h3>
            </div>
          </div>

          <div className="mainSection-content-barCode">
            {QrCode ? (
              <div className="qrCode">
                <QRCode value={QrCode.value} />
                <p className="walletId">6066e4315abbcc1f9526d392</p>
              </div>
            ) : (
              <div className="barCode">
                <img src={barcode} alt="barcode" />
                <p className="walletId">6066e4315abbcc1f9526d392</p>
              </div>
            )}
          </div>
          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>
        <div className="dataSection">
          <div className="sectionLabel">
            <h3 className="label">Description</h3>
            <h3 className="data">{`${details}` || ""}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {" "}
              {intl.formatMessage({ id: "ship.label.departureDate" })}
            </h3>
            <h3 className="data">{departureTime}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {" "}
              {intl.formatMessage({ id: "ship.label.arrivalDate" })}
            </h3>
            <h3 className="data">{departureTime}</h3>
          </div>

          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {" "}
              {intl.formatMessage({ id: "ship.label.ticketNumber" })}
            </h3>
            <h3 className="data">6066e4315abbcc1f9526d392</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {intl.formatMessage({ id: "ship.label.tarif" })}
            </h3>
            <h3 className="data">{`${tarif} $` || ""}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Terms & conditions</h3>
            <h3 className="data">{terms || ""}</h3>
          </div>
          <span className="divider"></span>

          <div className="sectionLabel">
            <h3 className="label">
              {" "}
              {intl.formatMessage({ id: "ship.label.customerServiceNumber" })}
            </h3>
            <h3 className="data">{customerServiceNumber || ""}</h3>
          </div>
          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>
        <div className="round1" />
        <div className="round2" />
        <div className="hidden-round1" />
        <div className="hidden-round2" />
      </div>
    </Wrapper>
  );
};

export default FerryWireframeIPhone;
