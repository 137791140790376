import React from "react";
import { useIntl } from "react-intl";
import Icon from "react-icons-kit";
import { ic_public } from "react-icons-kit/md";
import { ratio } from "wcag-color";
import { getDataFromCache } from "../../utils/cache";
//images and icons
import barcode from "../../Assets/images/barcode.png";
import cover from "../../Assets/images/lg-thumbnail.png";
import defaultLogo from "../../Assets/images/sm-thumbnail.png";
//styles
import { Wrapper } from "./couponWireframe.style";
import {
  StyledDetailsBotton,
  StyledDetailsBottonContainer,
  StyledExpandMoreIcon,
  StyledHeaderContainer,
} from "../../Commons";
import { StyledMainSection } from "../../Commons/CommonStyles/CommonStyles";

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  promotion: any;
  name: string;
  symbol: string;
  logo?: any;
  expiresLabel?: string;
  expiresDate?: string;
  fromLabel?: string;
  from?: string;
  promoCodeLabel?: string;
  promoCode?: string;
  descriptionLabel: string;
  description: string;
  valueLabel: string;
  value: string;
  valueCurrency: string;
  idCouponLabel: string;
  idCoupon: string;
  disableNotifications: string;
};

const CouponWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    promoCode,
    strip,
    promotion,
    name,
    symbol,
    logo,
    expiresDate,
    fromLabel,
    from,
    promoCodeLabel,
    descriptionLabel,
    description,
    valueLabel,
    value,
    valueCurrency,
    idCouponLabel,
    idCoupon,
    disableNotifications,
  } = props;
  const currentWallet = getDataFromCache("wallet");
  const intl = useIntl();
  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>("800px");
  const handleChange = () => {
    setShowDetails(!showDetails);
    height === "100%" ? setHeight("100%") : setHeight("100%");
  };
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      height={height}
      visibilty={showDetails}
    >
      <StyledMainSection
        backgroundColor={backgroundColor}
        className="mainSection"
      >
        <StyledHeaderContainer
          mainContentTextColor={
            ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
          }
        >
          <div className="mainSection-header-logo">
            <img
              src={
                !logo
                  ? defaultLogo
                  : typeof logo === "string"
                  ? logo
                  : URL.createObjectURL(logo)
              }
              alt="wallet Logo"
            />
          </div>
          <h4>{!name ? '"Enseigne"' : name}</h4>
        </StyledHeaderContainer>

        <div className="mainSection-content-enseigne">
          <h3 className="mainSection-eventName"> {name} </h3>
        </div>
        <div className="mainSection-content-barCode">
          <div className="barCode">
            <img src={barcode} alt="barcode" />
          </div>
          <p className="walletId">{promoCode || "promoCode"}</p>
        </div>
        <div className="mainSection-content-banner">
          <img
            src={
              !strip
                ? cover
                : typeof strip === "string"
                ? strip
                : URL.createObjectURL(strip)
            }
            alt="banner"
          />
        </div>
      </StyledMainSection>
      <StyledDetailsBottonContainer>
        <StyledDetailsBotton
          disableRipple
          onClick={handleChange}
          startIcon={<StyledExpandMoreIcon showDetails={showDetails} />}
          variant="outlined"
        >
          Details
        </StyledDetailsBotton>
      </StyledDetailsBottonContainer>

      <div className="dataSection">
        {showDetails && (
          <>
            <span className="divider"></span>
            <div className="sectionLabel">
              <h3 className="label">Expires</h3>
              <h3 className="data">{expiresDate}</h3>
            </div>
            <span className="divider"></span>
            <div className="sectionLabel">
              <h3 className="label">
                {fromLabel ||
                  intl.formatMessage({
                    id: "coupon.label.From",
                  })}
              </h3>
              <h3 className="data">{from || "wallet"}</h3>
            </div>
            <span className="divider"></span>
            <div className="sectionLabel">
              <img
                src={
                  !strip
                    ? cover
                    : typeof strip === "string"
                    ? strip
                    : URL.createObjectURL(strip)
                }
                alt="banner"
              />
            </div>
            <span className="divider"></span>
            <div className="sectionLabel">
              <h3 className="label">
                {promoCodeLabel ||
                  intl.formatMessage({
                    id: "couponCard.label.promoCode",
                  })}
              </h3>
              <h3 className="data">{promoCode || "promoCode"}</h3>
            </div>
            <span className="divider"></span>
            <div className="sectionLabel">
              <h3 className="label">
                {descriptionLabel ||
                  intl.formatMessage({
                    id: "wallet.label.Description",
                  })}
              </h3>
              <h3 className="data">{description || "description"}</h3>
            </div>
            <span className="divider"></span>
            <div className="sectionLabel">
              <h3 className="label">
                {valueLabel ||
                  intl.formatMessage({
                    id: "coupon.label.value",
                  })}
              </h3>
              <h3 className="data"> {`${value} ${valueCurrency}`} </h3>
            </div>
            <span className="divider"></span>
            <div className="sectionLabel">
              <h3 className="label">
                {idCouponLabel ||
                  intl.formatMessage({
                    id: "couponCard.label.idCoupon",
                  })}
              </h3>
              <h3 className="data">{idCoupon}</h3>
            </div>
            <span className="divider"></span>
            <div className="sectionIcon">
              <Icon icon={ic_public} size={23} style={{ color: "#444444" }} />
              <h3 className="data">{disableNotifications}</h3>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default CouponWireframeAndroid;
