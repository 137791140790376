import styled from "styled-components";

export const Wrapper = styled("div")<{
  backgroundColor: any;
  mainContentTextColor: string;
  height: any;
  visibilty?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  .mainSection {
    .mainSection-content-enseigne {
      width: 100%;
      font-family: "Product Sans Light", sans-serif;
      padding: 14px 14px 0px 14px;
      box-sizing: border-box;
      .mainSection-address-value {
        font-weight: 600;
        font-family: "Product Sans Light", sans-serif;
        font-size: 1.3rem;
        color: ${(props) => props.mainContentTextColor};
      }
      .mainSection-address-label {
        font-weight: 600;
        font-family: "Product Sans Light", sans-serif;
        font-size: 0.875rem;
        color: ${(props) => props.mainContentTextColor};
      }
      .mainSection-address-label,
      .mainSection-address-value {
        margin: 0;
      }

      .mainSection-title {
        font-size: 0.9rem;
      }
      .mainSection-issuerName {
        font-size: 1.4rem;
        font-weight: 400;
      }
      .mainSection-address {
        font-size: 0.9rem;
      }
    }
    .mainSection-content-banner {
      height: 125px;
      width: 100%;
      overflow: hidden;
      margin-top: 14px;
      box-sizing: border-box;
      img {
        object-fit: cover;
        height: inherit;
        width: 100%;
      }
    }
    .datasect {
      display: grid;
      width: 100%;
      grid-template-columns: 80px 1fr 1fr;
      gap: 8px;
      margin-top: 12px;
      padding: 6px 14px;
      box-sizing: border-box;
      .title {
        font-family: "Product Sans Light", sans-serif;
        font-size: 0.7rem;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-transform: capitalize;
      }
      .result {
        font-family: "Product Sans Light", sans-serif;
        font-size: 1rem;
        font-weight: 900;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .mainSection-content-barCode {
      width: 100%;
      margin-top: 20px;
      padding: 0 30px;
      box-sizing: border-box;
      .barCode {
        display: flex;
        height: 112px;
        width: 100%;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border-radius: 16px;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 1px 0px;
        box-sizing: border-box;
        img {
          height: 78px;
          object-fit: cover;
        }
      }
      .walletId {
        font-family: "Product Sans thin", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        color: ${(props) => props.mainContentTextColor};
        text-align: center;
        letter-spacing: 0.05em;
        margin: 14px 0px 0px;
      }
    }
  }

  /* .details_btn {
    display: flex;
    width: fit-content;
    align-items: center;
    font-family: "Product Sans", sans-serif;
    font-size: 0.97rem;
    margin: 20px 0 5px 0;
    :hover {
      color: #3f51b5;
      .MuiSvgIcon-root {
        fill: #3f51b5;
      }
    }
  } */

  /* .dataSection {
    display: flex;
    height: ${(props) => (props.visibilty ? "450px" : "0")};
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: ${(props) => (props.visibilty ? "0px 10px 20px 10px" : "0")};
    opacity: ${(props) => (props.visibilty ? "1" : "0")};
    transition: height 0.3s cubic-bezier(0.4, 0, 1, 1),
      opacity 1s cubic-bezier(0.4, 0, 1, 1);
    .sectionIcon {
      margin-top: 20px;
      gap: 16px;
      align-items: center;
      display: flex;
      .data {
        font-family: "Product Sans Light", sans-serif;
        font-size: 14px;
        word-break: break-all;
        margin: 0px;
      }
    }
    .sectionLabel {
      margin-top: 10px;
      .label {
        color: #686767;
        font-family: "Product Sans Light", sans-serif;
        font-size: 0.7rem;
        letter-spacing: 0.075em;
        line-height: 20px;
        text-transform: uppercase;
        margin: 0px;
      }
      .data {
        font-family: "Product Sans Light", sans-serif;
        font-size: 0.95rem;
        letter-spacing: 0.075em;
        line-height: 20px;
        color: ${(props) => props.mainContentTextColor};
        word-break: break-all;
        margin: 0px;
      }
      svg {
        color: #3a6ac3;
      }
    }
  } */
`;
