import React from "react";

function Icon({
  width = "28",
  height = "28",
  fill = "#C7C7C7",
}: // className,
{
  // className?: string;
  width?: string;
  height?: string;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      // viewBox="0 0 24 22"
      viewBox="0 0 28 22"
    >
      <path
        fill="#C7C7C7"
        fillRule="evenodd"
        d="M18.731 6.799c0-.011.01-.02.02-.02H21.2c1.363 0 2.534-1.137 2.111-2.433C22.507 1.885 20.38.5 17.269.5H6.733C2.708.5.333 2.815.333 6.728v8.544c0 3.913 2.375 6.228 6.399 6.228h10.536c4.024 0 6.398-2.315 6.398-6.228a.364.364 0 00-.364-.364h-4.572c-2.29 0-4.148-1.81-4.148-4.044 0-2.227 1.846-4.034 4.128-4.045a.02.02 0 00.02-.02zm0 1.719h4.065a.86.86 0 01.871.85v2.951a.87.87 0 01-.87.85h-3.973c-1.16.015-2.174-.76-2.437-1.86a2.313 2.313 0 01.506-1.928 2.44 2.44 0 011.838-.863zm.176 3.104h.384a.88.88 0 00.892-.87c0-.48-.4-.87-.892-.87h-.384a.894.894 0 00-.63.249.849.849 0 00-.262.611.89.89 0 00.892.88zM5.861 6.779h6.586a.88.88 0 00.891-.87c0-.48-.399-.87-.891-.87H5.86a.881.881 0 00-.891.86.89.89 0 00.891.88z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
