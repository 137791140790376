import styled from "styled-components";

export const Wrapper = styled("div")<{
  backgroundColor: any;
  mainContentTextColor: string;
  height: any;
  visibilty?: boolean;
}>`
  .mainSection {
    .mainSection-content-enseigne {
      box-sizing: border-box;
      width: 100%;
      padding: 10px 16px 0px 16px;
      h3 {
        /* font-family: "Product Sans", sans-serif; */
        font-size: 1.3rem;
        color: ${(props) => props.mainContentTextColor};
        line-height: 22px;
        letter-spacing: 1px;
        margin: 0;
        font-weight: 500;
      }
    }

    .mainSection-content-banner {
      height: 120px;
      width: 100%;
      margin-top: 6px;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .mainSection-content-barCode {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin-top: 25px;
      padding: 0 15px;
      box-sizing: border-box;
      .barCode {
        display: flex;
        height: 112px;
        width: 90%;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border-radius: 16px;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 1px 0px;
        box-sizing: border-box;
        img {
          width: 70%;
          height: auto;
          object-fit: cover;
        }
      }
      .walletId {
        /* font-family: "Product Sans", sans-serif; */
        font-size: 14px;
        color: ${(props) => props.mainContentTextColor};
        text-align: center;
        letter-spacing: 0.025em;
        margin: 7px 0 10px 0px;
      }
    }
  }

  .details_btn {
    display: flex;
    width: fit-content;
    align-items: center;
    /* font-family: "Product Sans", sans-serif; */
    font-size: 0.97rem;
    margin: 20px 0 5px 0;
    :hover {
      color: #3f51b5;
      .MuiSvgIcon-root {
        fill: #3f51b5;
      }
    }
  }

  .mainSection-label {
    color: ${(props) => props.mainContentTextColor};
    font-weight: 300;
    text-transform: uppercase;
    font-size: 12px;
    margin: 0px;
  }
  .mainSection-data {
    color: ${(props) => props.mainContentTextColor};
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
  }
  .dataSection {
    display: flex;
    height: ${(props) => (props.visibilty ? "510px" : "0")};
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: ${(props) => (props.visibilty ? "0px 10px 20px 10px" : "0")};
    opacity: ${(props) => (props.visibilty ? "1" : "0")};
    transition: height 0.3s cubic-bezier(0.4, 0, 1, 1),
      opacity 1s cubic-bezier(0.4, 0, 1, 1);
    .sectionIcon {
      margin-top: 20px;
      gap: 16px;
      align-items: center;
      display: flex;
      .data {
        /* font-family: "Product Sans Light", sans-serif; */
        font-size: 14px;
        word-break: break-all;
        margin: 0px;
      }
    }
    .sectionLabel {
      margin-top: 10px;
      .label {
        color: #686767;
        /* font-family: "Product Sans Light", sans-serif; */
        font-size: 0.7rem;
        letter-spacing: 0.075em;
        line-height: 20px;
        text-transform: uppercase;
        margin: 0px;
      }
      .data {
        /* font-family: "Product Sans Light", sans-serif; */
        font-size: 0.95rem;
        letter-spacing: 0.075em;
        line-height: 20px;
        color: ${(props) => props.mainContentTextColor};
        word-break: break-all;
        margin: 0px;
      }
      img {
        width: 100%;
        height: 123px;
        object-fit: cover;
        box-shadow: rgb(0 0 0 / 16%) 0px 2px 3px 1px;
      }
    }
  }
`;
