import styled from "styled-components";

export const Wrapper = styled("div")<{
  backgroundColor: any;
  mainContentTextColor: string;
  textColor: string;
  titleColor: string;
  flip: boolean;
}>`
  perspective: 1000px;
  .pass {
    position: relative;
    transform: ${(props) => (props.flip ? "rotateY(180deg)" : "rotateY(0)")};
    transition: transform 0.6s;
    transform-style: preserve-3d;
    .mainSection {
      position: absolute;
      display: flex;
      height: 475px;
      width: 100%;
      flex-direction: column;
      background-color: ${(props) => props.backgroundColor.hex};
      font-family: "Red Hat Text", sans-serif;
      padding: 8px 12px;
      border-radius: 10px;
      border: 1px solid #dddada;
      box-sizing: border-box;
      backface-visibility: hidden;
      .firstSection {
        display: flex;
        justify-content: space-between;
        .mainSection-header-logoSection {
          display: flex;
          width: 100%;
          gap: 12px;
          span {
            color: ${(props) => props.titleColor};
            font-size: 1.1rem;
            text-align: right;
            font-weight: 650;
            line-height: 45px;
          }
          .mainSection-header-logo {
            height: 50px;
            width: 50px;
            img {
              object-fit: contain;
              height: 40px;
              width: 40px;
            }
          }
        }
        .mainSection-label {
          color: ${(props) => props.textColor};
          font-weight: 500;
          text-transform: uppercase;
          font-size: 0.6rem;
          text-align: right;
          margin: 0px;
        }
        .mainSection-data {
          color: ${(props) => props.titleColor};
          font-size: 1rem;
          text-align: right;
          font-weight: 500;
          line-height: 18px;
          margin: 0px;
        }
      }

      .mainSection-origin-destination {
        display: grid;
        grid-template-columns: 120px 60px 1fr;
        margin-top: 14px;
        gap: 2px;

        .mainSection-origin-name,
        .mainSection-destination-name {
          width: 100%;
          min-height: 11px;
          font-size: 0.6rem;
          font-weight: 500;
          line-height: 11px;
          letter-spacing: 0.07272727em;
          text-transform: uppercase;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 0;
        }
        .mainSection-origin-stationCode,
        .mainSection-destination-destinationCode {
          font-size: 1.8rem;
          font-weight: 400;
          line-height: 42px;
          color: ${(props) => props.titleColor};
          overflow-wrap: break-word;
          word-wrap: break-word;
          margin: 0;
        }
      }
      .secondSection {
        display: grid;
        grid-template-columns: 95px 56px 55px 1fr;
        margin-top: 8px;
        margin-bottom: 20px;
        .title {
          font-weight: 500;
          text-transform: uppercase;
          font-size: 0.6rem;
          margin: 0px;
        }
        .result {
          color: ${(props) => props.titleColor};
          font-size: 1rem;
          font-weight: 500;
          line-height: 18px;
          margin: 0px;
        }
      }
      .mainSection-content-barCode {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 70px;
        margin-bottom: 10px;
        .qrCode {
          display: flex;
          width: fit-content;
          flex-direction: column;
          align-items: center;
          background-color: #ffffff;
          padding: 12px 12px 5px 12px;
          border-radius: 5px;
          .walletId {
            font-size: 0.93rem;
            font-weight: 450;
            margin: 5px auto;
          }
        }

        .barCode {
          width: 270px;
          margin: auto;
          background: #ffffff;
          height: 100px;
          display: flex;
          border-radius: 3px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          img {
            width: 235px;
            height: 58px;
            align-self: center;
            margin-top: 11px;
          }
          .walletId {
            font-size: 12px;
            color: black;
            text-align: center;
            margin: 10px 0 7px 0px;
          }
        }
      }
    }
    .dataSection {
      position: ${(props) => (props.flip ? "unset" : "absolute")};
      display: flex;
      width: 100%;
      height: 475px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: #fff;
      padding: 5px 10px 10px 10px;
      font-family: "Red Hat Text", sans-serif;
      border: 1px solid #dddada;
      border-radius: 10px;
      box-sizing: border-box;
      backface-visibility: hidden;
      transform: rotateY(180deg);
      transition: position 0.6s;
      .sectionLabel {
        line-height: 16px;
        .label {
          color: #837b7b;
          font-weight: 450;
          font-size: 0.75rem;
          text-transform: uppercase;
          letter-spacing: 0.025em;
          margin: 0 0 8px 0;
        }
        .data {
          display: flex;
          color: #837b7b;
          font-weight: 450;
          font-size: 0.95rem;
          word-break: break-all;
          gap: 8px;
          margin: 8px 0;
        }
      }
      .divider {
        width: 100%;
        border-bottom: 1px solid #eeedf1;
        margin: 5px 0;
      }
    }
    .round1 {
      position: absolute;
      top: 120px;
      left: -7px;
      height: 12px;
      width: 11px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid #dddada;
    }
    .hidden-round1 {
      position: absolute;
      top: 119px;
      left: -7px;
      height: 17px;
      width: 7px;
      background-color: #ffffff;
    }
    .round2 {
      position: absolute;
      top: 120px;
      right: -7px;
      height: 12px;
      width: 11px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid #dddada;
    }
    .hidden-round2 {
      position: absolute;
      top: 119px;
      right: -7px;
      height: 17px;
      width: 7px;
      background-color: #ffffff;
    }

    .backFlip_btn {
      position: absolute;
      bottom: 5px;
      right: 5px;
      transform: ${(props) => (props.flip ? "rotateY(180deg)" : "rotateY(0)")};
    }
  }
`;
