import styled from "styled-components";
import ButtonMui from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const Button = styled(ButtonMui)`
  border-radius: 0 12px 12px 0;
`;

export const StyledDetailsBottonContainer = styled("div")`
  width: 100%;
  text-align: center;
  .MuiButton-outlined {
    border: 2px solid #d0d0d0;
  }
`;

export const StyledDetailsBotton = styled(ButtonMui)`
  font-family: "Product Sans light", sans-serif;
  margin: 0 auto;
  font-weight: 600;
  border-radius: 24px;
  width: 70%;
  margin: 20px 0 5px 0;
  text-transform: capitalize;
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)<{
  showDetails: boolean;
}>`
  width: 22px;
  height: 22px;
  transition: 0.4s;
  transform: ${(props) =>
    props.showDetails ? "rotate3d(0,0,1,1turn)" : "rotate3d(1,0,0,0.5turn)"};
`;

export const StyledHeaderContainer = styled("div")<{
  mainContentTextColor: string;
  justifyContent?: string;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  box-sizing: border-box;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  padding: 5px 5px;
  position: relative;
  box-shadow: 0px -6px 10px 0px #000000;
  padding: 14px 14px 12px 14px;

  h4 {
    /* font-family: "Product Sans Regular"; */
    font-size: 0.95rem;
    font-weight: 500;
    color: ${(props) => props.mainContentTextColor};
    letter-spacing: 1px;
    margin: 0;
  }
  .mainSection-header-logo {
    position: relative;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    img {
      height: 32px;
      width: 32px;
      object-fit: contain;
      border-radius: 50%;
    }
  }
`;

export const StyledSeperator = styled("div")`
  height: 1px;
  background-color: #dbdbdb;
  border-radius: 1px;
  width: 100%;
  margin: 14px 0;
`;

export const StyledContactInfoContainer = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
`;
