/* eslint-disable import/no-anonymous-default-export */
export default {
  //**************************************Input File****************************//
  //Buttons
  "inputFile.button.select": "Parcourir",
  //**************************************Dialog****************************//
  //Buttons
  "dialog.button.agree": "Oui",
  "dialog.button.desagree": "Non",
  //**************************************DRAWER****************************//
  //Menu List
  "drawer.menuList.wallet": "Wallet",
  "drawer.menuList.offers": "Offre/Communication",
  "drawer.menuList.coupons": "Coupons",
  "drawer.menuList.event": "Evènements",
  "drawer.menuList.settings": "Paramètres",
  "drawer.menuList.stats": "Statistiques",
  "drawer.menuList.ships": "Bateaux",
  "drawer.menuList.buses": "Bus",
  "drawer.menuList.trams": "Metro",
  "drawer.menuList.rails": "Train",
  "drawer.menuList.cinemas": "Cinema",
  "drawer.menuList.flights": "Avions",
  "drawer.menuList.hotels": "Chambres",
  //Menu Item
  "drawer.menuItem.profile": "Profil",
  "drawer.menuItem.logout": "Déconnexion",
  //**************************************Login****************************//
  //Labels
  "login.label.forgotPassword": "Mot de passe oublié ?",
  //Placeholder
  "login.placeholder.username": "Identifiant",
  "login.placeholder.password": "Mot de passe",
  //Buttons
  "login.button.login": "Se Connecter",
  //Error
  "login.error.fieldsReqiured": "L'identifiant et le mot de passe sont requis",
  "login.error.incorrectUsernameOrPassword":
    "L'identifiant ou le mot de passe est incorrect",
  "login.error.userNotAuthorized": "Utilisateur non autorisé",
  //**************************************Settings****************************//
  //Settings Languages
  "settings.langauges.english": "Anglais",
  "settings.langauges.french": "Français",
  "settings.langauges.german": "German",
  //Settings Labels
  "settings.label.settingsTitle": "Paramètres",
  "settings.label.registrationParameterTitle": "Paramètre d'inscription",
  "settings.label.link": "Lien:",
  "settings.label.QrCode": "QR Code:",
  "settings.label.validationMessage":
    "Message de validation d'inscription de client externe",
  "settings.label.emailMessage": "Texte de l'email d'inscription",
  "settings.label.offerEmailMessage":
    "Texte de l'email d'offre (nouvelle offre)",
  "settings.label.offerNotificationMefssage":
    "Texte de la notification (nouvelle offre)",
  "settings.label.couponEmailMessage": "Texte de l'email (nouveau coupon)",
  "settings.label.couponNotificationMessage":
    "Texte de la notification (nouveau coupon)",
  "settings.label.newOfferSMSMessage": "Texte de SMS d'offre (nouvelle offre)",
  "settings.label.couponSMSMessage": "Texte de SMS (nouveau coupon)",
  "settings.label.changeLanguageTitle": "Changer de langue",
  "settings.label.linkCopied": "Lien copié",
  "settings.label.subscriptionMessage": "Texte d'abonnement",
  "settings.label.newAccountSMSMessage": "Message de SMS d'inscription",
  "settings.label.transit": "Carte de transport en commun",

  "settings.communicationChannel.noAccountMessage":
    "Aucun compte SMS n'est associé à votre wallet!",
  "settings.communicationChannel.noCreditsMessage":
    "Le crédit de votre compte SMS est insuffisant!",

  "settings.label.smsTitle": "Titre du SMS",

  "settings.label.newPassEmailMessage": "Texte de l'email (nouveau)",
  "settings.label.updatePassEmailMessage": "Texte de l'email (mise a jour)",
  "settings.label.pushNotificationMessage": "Texte de la notification",
  "settings.label.inscriptionSettings": "Paramètre d'inscription",
  "settings.label.walletIdentifier": "Identifiant",
  "settings.label.inscriptionLink": "Lien",
  "settings.label.showBarcodeText":
    "Afficher le champ de code à barres lors de l'ajout d'un client",
  "settings.label.communicationChannels": "Canaux de communication",
  "settings.label.email": "Email",
  "settings.label.phoneNumber": "Numéro de téléphone",
  "settings.label.emailAndPhoneNumber": "Email et Numéro de téléphone",

  "settings.label.styles": "Styles",
  "settings.label.messages": "Messages",
  "settings.label.information": "Information",
  "settings.label.notifications": "Notifications",

  // Tabs
  // Wallet Style
  "settings.label.walletStyle": "Style du wallet",

  "settings.label.styles.titlesColor": "Titre (iOS)",
  "settings.label.styles.textColor": "Texte (iOS)",
  "settings.label.styles.backgroundColor": "Arrière plan (iOS-Android)",
  "settings.label.styles.changeLogo":
    "Modifier la logo de wallet (200px/200px)",
  "settings.label.styles.changeIcon":
    "Modifier l`icône pour les notifications(29px/29px)",
  "settings.label.styles.changeStrip":
    "Modifier l`image de bande (240px*400px) ",

  //Informatin Tab
  "settings.label.accountInformation": "Information du compte",
  "settings.label.userName": "Nom d'utilisateur",
  "settings.label.password": "Mot de passe",
  "settings.label.communicationEmail": "Email de Communication",

  "settings.label.walletInformation": "Information du wallet",
  "settings.label.walletName": "Enseigne",
  "settings.label.showWalletName": "Afficher l'ensigne",
  "settings.label.commercialPhone": "Téléphone commercial",
  "settings.label.afterSalePhone": "Téléphone S.A.V",
  "settings.label.website": "Site web",
  "settings.label.address": "Adresse",
  "settings.label.openingHours": "Horaires d'ouvertures",

  "settings.placeholder.walletInformation": "Information du wallet",
  "settings.placeholder.walletName": "Enseigne",
  "settings.placeholder.showWalletName": "Afficher l'ensigne",
  "settings.placeholder.commercialPhone": "Téléphone commercial",
  "settings.placeholder.afterSalePhone": "Téléphone S.A.V",
  "settings.placeholder.website": "Site web",
  "settings.placeholder.address": "Adresse",
  "settings.placeholder.openingHours": "Horaires d'ouvertures",

  //additionalContacts

  "settings.label.additionalContactTitle":
    "Contact supplémentaire (Gérant, Responsable administratif ...)",
  "settings.label.additionalContact": "Contact supplémentaire",
  "settings.label.title": "Titre",
  "settings.label.fieldType": "Type de champ",
  "settings.label.url": "URL",
  "settings.label.value": "Valeur du champ",
  "settings.label.addAdditionalContact": "Ajouter Contact supplémentaire",

  "settings.placeholder.additionalContactTitle":
    "Contact supplémentaire (Gérant, Responsable administratif ...)",
  "settings.placeholder.additionalContact": "Contact supplémentaire",
  "settings.placeholder.title": "Titre",
  "settings.placeholder.fieldType": "Type de champ",
  "settings.placeholder.url": "URL",
  "settings.placeholder.value": "Valeur du champ",
  "settings.placeholder.addAdditionalContact": "Ajouter Contact supplémentaire",

  // locationlisation

  "settings.label.locatisation": "Localisation",
  "settings.label.latitude": "Latitude",
  "settings.label.longitude": "Longitude",
  "settings.label.locatisationNotificationMessage":
    "Message de notification de la localisation",
  "settings.label.description": "Description",

  "settings.button.addDescription": "Ajouter une description",
  "settings.button.delete": "Supprimer",

  "settings.placeholder.locatisation": "Localisation",
  "settings.placeholder.latitude": "Latitude",
  "settings.placeholder.longitude": "Longitude",
  "settings.placeholder.locatisationNotificationMessage":
    "Message de notification de la localisation",
  "settings.placeholder.description": "Description",
  "settings.placeholder.descriptionTitle": "Titre de la description",

  //messages tab

  "settings.label.inscriptionEmailCode": "Code de l'email d'inscription",
  "settings.label.updateEmailCode": "Email update code",

  "settings.label.newOfferTitle": "New Offer",
  "settings.label.subscription": "Subscription",
  "settings.label.SMSMessage": "SMS message",
  "settings.label.notificationMessage": "Texte de la notification",
  "settings.label.newCouponTitle": "New coupon",
  "settings.label.expiredOfferTitle": "Expired offer",
  "settings.label.expiredNotificationText":
    "Texte de notification d'offre expirée",
  "settings.label.flight": "Vol",
  "settings.label.preview": "Aperçu",
  "settings.placeholder.letEmpty":
    "Laisser vide pour appliquer la valeur par défaut",
  "settings.label.expiredOfferEmailText": "Texte de l'email (offre expirée)",

  //notifications tab

  "settings.label.emailUpdateText": "Texte de l'email (mise à jour)",
  "settings.label.updateNotificationText":
    "Texte de la notification (mise à jour)",
  "settings.label.activateEmailNotification":
    "Activation de la notification par email",
  "settings.label.walletUpdates": "Mise à jour du wallet",
  "settings.label.newOffers": "Nouvelles offres",
  "settings.label.newCoupons": "Nouveaux coupons",
  "settings.label.activateSMSNotification":
    "Activation de la notification par SMS",

  //Placeholder

  "settings.placeholder.userName": "Nom d'utilisateur",
  "settings.placeholder.password": "Mot de passe",
  "settings.placeholder.communicationEmail": "Email de Communication",

  "settings.placeholder.validationMessage": "Texte de validation d'inscription",
  "settings.placeholder.emailMessage": "Texte de l'email d'inscription",
  "settings.placeholder.offerEmailMessage":
    "Texte de l'email d'offre (nouvelle offre)",
  "settings.placeholder.offerNotificationMessage":
    "Vous avez une nouvelle offre,...",
  "settings.placeholder.couponEmailMessage": "Texte de l'email",
  "settings.placeholder.couponNotificationMessage": "Texte de la notification",
  "settings.placeholder.couponSMSMessage": "Texte de la notification SMS",
  "settings.placeholder.newOfferSMSMessage":
    "Texte de SMS d'offre (nouvelle offre)",

  "settings.placeholder.subscriptionMessage": "Votre Texte d'abonnement ici",
  "settings.placeholder.newAccountSMSMessage": "Texte de SMS d'inscription",

  "settings.placeholder.newPassEmailMessage": "Texte de l'email (nouveau)",
  "settings.placeholder.updatePassEmailMessage":
    "Texte de l'email (mise a jour)",
  "settings.placeholder.pushNotificationMessage": "Texte de la notification",
  "settings.label.note":
    "Vous utilisez actuellement le service e-mail de Bestwallet , si vous souhaitez utiliser votre configuration SMTP veuillez contacter l'administrateur",
  //Settings Buttons
  "settings.button.save": "Sauvegarder",
  "settings.button.cancel": "Annuler",
  "settings.button.copyLink": "Copier le lien",
  "settings.button.verifyEmail": "Verifier Email",
  //**************************************WALLET****************************//
  //Labels
  "wallet.label.searchCustomer": "Rechercher un client",
  "wallet.label.filter": "Filtrer par",
  "wallet.label.customersList": "Liste des clients",
  "wallet.label.customersEmptyList": "La liste des clients est vide",
  "wallet.label.value": "Valeur",
  "wallet.label.termsAndConditions": "Termes et conditions",
  "wallet.label.infoLine": "Ligne d'information",
  "wallet.label.expires": "Expire",
  "wallet.label.From": "De",
  "wallet.label.Description": "Description",
  //Select
  "wallet.select.firstName": "Prénom",
  "wallet.select.lastName": "Nom",
  "wallet.select.email": "Email",
  //Placeholder
  "wallet.placeholder.searchCustomer": "Recherche",
  //Buttons
  "wallet.add": "Ajouter Client",
  "wallet.button.import": "Importer",
  "wallet.button.export": "Exporter",

  "wallet.label.downloadTemplate": "Télécharger le template CSV",
  "wallet.label.importFile": "Importer un fichier CSV",
  "wallet.lable.importFileDropZone":
    "Glissez le ficher ici ou cliquez pour importer",
  "wallet.button.rechercher": "Rechercher",
  "wallet.label.date": "Date",
  "wallet.label.time": "Heure",
  "wallet.type.label": "Type",

  //**************************************Customers Table****************************//
  //Labels
  "customersTable.Barcode": "Code-barres",
  "customersTable.id": "ID",
  "customersTable.firstName": "Prénom",
  "customersTable.lastName": "Nom",
  "customersTable.createdAt": "Enregistré le",
  "customersTable.gains": "Gains",
  "customersTable.email": "Email",
  "customersTable.phoneNumber": "Numéro de téléphone",
  "customersTable.source": "Source",
  "customersTable.source.internal": "Interne",
  "customersTable.source.external": "Externe",
  "customersTable.source.crm": "CRM",
  "customersTable.source.imported": "Importé",

  "customersTable.status": "Statut",
  "customersTable.suprimer": "Supprimé",
  "customersTable.system": "Système",
  "customerTable.isDeleted": "Oui",
  "customerTable.isNotDeleted": "Non",
  //Buttons
  "customersTable.updateGain": "Mettre à jour le gain",
  //Historical
  "customersTable.historical.title": "Historique",
  "customersTable.historical.emptyList": "La liste d'historique est vide",
  "customersTable.historical.date": "Date",
  "customersTable.historical.gain": "Gain",
  //**************************************Update Gain****************************//
  //Labels
  "updateGain.label.gain": "Mettre à jour le gains",
  //Placeholder
  "updateGain.placehoder.gain": "Gains",
  //Buttons
  "updateGain.button.confirm": "Mettre à jour",
  //Error
  "updateGain.error.gainNotNumber": "Le gain doit être un nombre",

  //**************************************Update Gain unit****************************//
  "gainUnit.dialog.text.change":
    "Voulez-vous vraiment changer l'unité du gains? Cela réinitialise les gains de tous les clients à 0 ",
  "gainUnit.dialog.text.title": "Attention!",

  //**************************************Add Customer****************************//
  //Labels
  "addCustomer.label.firstName": "Prénom",
  "addCustomer.label.lastName": "Nom",
  "addCustomer.label.email": "Email",
  "addCustomer.label.barcode": "Code à barres",
  "addCustomer.label.qrcode": "Code QR",

  "addCustomer.label.phoneNumber": "Numéro de téléphone",
  //Placeholder
  "addCustomer.placeholder.firstName": "Prénom",
  "addCustomer.placeholder.lastName": "Nom",
  "addCustomer.placeholder.email": "Email",
  "addCustomer.placeholder.barcode": "Code à barres",
  "addCustomer.placeholder.phoneNumber": "+33757130710",
  //Buttons
  "addCustomer.button.add": "Ajouter",
  //Error
  "addCustomer.error.firstNameRequired": "Prénom est obligatoire",
  "addCustomer.error.lastNameRequired": "Nom est obligatoire",
  "addCustomer.error.emailRequired": "Email est obligatoire",
  "addCustomer.error.phoneRequired": "Numéro de téléphone est obligatoire",
  "addCustomer.error.requiredFields":
    "Email ou le numéro de téléphone sont nécessaires",

  "addCustomer.error.emailInvalid": "Email invalide",
  "addCustomer.error.emailExist": "L'e-mail ou le code-barres existe déjà",
  "addCustomer.error.phoneNumberInvalid": "Numéro de téléphone est invalide",
  "addCustomer.error.barcodeInvalid":
    "le code à barre doit comporter au moins 8 caractères",
  "addCustomer.error.phoneNumberLength":
    "Le numéro de téléphone ne doit pas dépasser 15 chiffres ",
  //**************************************Offers List****************************//
  //Labels
  "offers.label.title": "Liste des offres",
  "offers.label.emptyList": "La liste des offres est vide",
  "offers.label.filter": "Filtrer par",
  "offers.label.startDate": "Date de début",
  "offers.label.endDate": "Date de fin",
  "offers.label.allTime": "Tout le temps",
  //Select
  "offers.select.all": "TOUS",
  "offers.select.opened": "OUVERT",
  "offers.select.actif": "ACTIVÉ",
  "offers.select.inactif": "DESACTIVÉ",
  "offers.select.expired": "EXPIRÉ",
  "offers.select.deleted": "SUPPRIME",

  //Dialog
  "offers.dialog.title": "Information",
  "offers.dialog.text.inactif": "Voulez-vous vraiment désactiver cette offre ?",
  "offers.dialog.text.action": "",
  "offers.dialog.text.deleted": "Voulez-vous vraiment supprimer cette offre ?",

  //Buttons
  "offers.button.search": "Rechercher",
  "offers.button.create": "Nouvelle Offre",
  //**************************************Offer Card****************************//
  //Labels
  "offerCard.label.id": "ID:",

  "offerCard.label.status": "Status",
  "offerCard.label.startDate": "Date de début",
  "offerCard.label.endDate": "Date de fin",
  "offerCard.label.description": "Description",
  //Buttons
  "offerCard.button.desactivate": "Désactiver",
  "offerCard.button.delete": "Supprimer",
  "offerCard.label.unlimited": "Illimité",

  //**************************************Statistiques****************************//
  //sms
  "stats.label.title": "Rapport d'envoi d'SMS",
  "stats.label.newAccount": "Création de compte",
  "stats.label.offers": "Les offres ",
  "stats.label.coupons": "Coupons",
  "stats.label.events": "Les événements",
  "stats.label.total": "total",
  "stats.label.availableCredit": "Crédits disponibles",
  "stats.label.noAssociatedAccount": "Aucun compte n'est associé ",
  "statsCLients.label.title": "Rapport de statistiques du clients",

  "stats.label.flightsTickets": "Les tickets d'avions",
  "stats.label.walletUpdate": "Mises a jour du wallet",
  "stats.label.moviesTickets": "Les tickets de film",
  "stats.label.transitTickets": "Les billets de voyage",
  "stats.label.reservations": "Les réservations",
  "Rapport de statistiques du clients": "Rapport de statistiques du clients",
  "stats.label.internal": "Internes",
  "stats.label.external": "Externes",
  "stats.label.activeClients": "Clients actifs",
  "stats.label.inactiveClients": "Clients inactifs",
  "stats.label.deletedClients": "Clients Supprimé",
  "stats.label.androidActive": "Actifs sur Android",
  "stats.label.iosActive": "Actifs sur IOS",
  "stats.label.deletedAndroid": "Supprimé sur Android",
  "stats.label.deletedIos": "Supprimé sur iOS",
  "stats.label.emailReport": "Rapport de statistiques d'emails",
  "stats.label.totalClients": "Total des clients",
  //**************************************Offer****************************//
  //Labels
  "offer.label.title": "Créer une offre",
  "offer.label.image": "Image à insérer*",
  "offer.label.description": "Description*",
  "offer.label.startDate": "Date de début",
  "offer.label.endDate": "Date de fin",
  "offer.label.active": "Actif",
  "offer.label.inactive": "Inactif",

  //Placeholder
  "offer.placeholder.description": "Description ...",
  //Buttons
  "offer.button.add": "Ajouter",
  "offer.button.cancel": "Annuler",
  //Error
  "offer.error.imageRequired": "L'insertion d'image est obligatoire",
  "offer.error.startDateRequired": "La date de début est obligatoire",
  "offer.error.startDateInvalid": "Date de début non valide",
  "offer.error.endDateRequired": "La date de fin est obligatoire",
  "offer.error.endDateInvalid": "Date de fin non valide",
  "offer.error.endDateBeforeStartDate":
    "La date de fin est antérieure à la date de début",
  "offer.error.descriptionRequired": "Description est obligatoire",
  "offer.error.datesUnavailable": "Les dates ne sont pas disponibles",

  //**************************************Wireframes****************************//

  "wireframes.storecard.name": "Carte de fidélité",
  "wireframes.storecard.label.customerName": "Nom du client",
  "wireframes.storecard.label.memberId": "ID Membres",
  "wireframes.storecard.label.type": "Type",
  "wireframes.storecard.label.adress": "Adresse",
  "wireframes.storecard.label.schedule": "Programme",
  "wireframes.storecard.label.offerDescription": "Descriptif de l'offre",
  "wireframes.storecard.label.commercialPhone": "Téléphone commercial",
  "wireframes.storecard.label.SAV": "Service après-vente",
  "wireframes.storecard.label.website": "Website",
  "wireframes.storecard.label.socialMedia": "Réseaux sociaux",
  "wireframes.storecard.label.barcode": "Code à barre",
  "wireframes.storecard.label.offers": "Des offres",
  "wireframes.storecard.label.coupons": "Coupons",

  //**************************************Coupons List****************************//
  //Labels
  "coupons.label.title": "Liste des coupons",
  "coupons.label.emptyList": "La liste des coupons est vide",
  //Dialog
  "coupons.dialog.title": "Information",
  "coupons.dialog.text": "Voulez-vous envoyer ce coupon à tous les clients ?",
  //Buttons
  "coupons.button.create": "Nouveau Coupon",
  //**************************************Coupon Card****************************//
  //Labels
  "couponCard.label.expirationDate": "Date d'expiration",
  "couponCard.label.name": "Nom",
  "couponCard.label.percent": "Pourcentage de réduction",
  "couponCard.label.promoCode": "Code promo",
  "couponCard.label.description": "Description",
  "couponCard.label.status": "Statut",
  "couponCard.label.source": "Source",
  "couponCard.label.idCoupon": "Id Coupon",
  //Buttons
  "couponCard.button.send": "Envoyer",
  "couponCard.switch.activate": "Activer",
  //**************************************Coupon****************************//
  //Labels
  "coupon.label.expirationDate": "Date d'expiration",
  "coupon.label.textColor": "Titre (iOS)",
  "coupon.label.backgroundColor": "Arrière plan(iOS-Android) ",
  "coupon.label.foregroundColor": "Texte (iOS)",
  "coupon.label.image": "Image à insérer*",
  "coupon.label.percent": "Pourcentage ou valeur*",
  "coupon.label.description": "Description*",
  "coupon.label.promoCode": "Promo Code*",
  "coupon.label.name": "Nom",
  "coupon.label.value": "Valeur",
  "coupon.label.addCoupon": "Ajouter Coupon",
  "coupon.label.updateCoupon": "Modifier Coupon",
  "coupon.label.source": "Source",
  "coupon.label.All": "TOUT",
  "coupon.label.Internal": "INTERNE",
  "coupon.label.External": "EXTERNE",
  "coupon.label.crm": "CRM",
  "coupon.label.From": "De",

  "coupon.label.Inactif": "INACTIF",
  "coupon.label.Actif": "ACTIF",
  "coupon.label.Expire": "EXPIRÉ",
  //Placeholder
  "coupon.placeholder.description": "Description ...",
  //Buttons
  "coupon.button.add": "Ajouter",
  //Error
  "coupon.error.expirationDateRequired": "La date d'expiration est obligatoire",
  "coupon.error.expirationDateInvalid": "Date d'expiration non valide",
  "coupon.error.labelColorRequired": "La couleur du texte est obligatoire",
  "coupon.error.backgroundColorRequired":
    "La couleur d'arrière-plan est obligatoire",
  "coupon.error.foregroundColorRequired":
    "La couleur de premier plan est obligatoire",
  "coupon.error.imageRequired": "Image est obligatoire",
  "coupon.error.percentReductionRequired":
    "Pourcentage ou valeur de réduction est obligatoire",
  "coupon.error.percentReductionNotNumber": "Le valeur doit être un nombre",

  "coupon.error.descriptionRequired": "Description est obligatoire",
  "coupon.error.promoCodeRequired": "Promo Code est obligatoire",
  "coupon.error.nameRequired": "Nom est obligatoire",

  //**************************************Events List****************************//
  //Labels
  "events.label.title": "Liste des Evènements",
  "events.button.create": "Nouvel évènement",
  "event.label.name": "Nom de l'évènement*",
  "event.label.openingDate": "Date d’ouverture",
  "event.label.startingDate": "Date de début",
  "event.label.closingDate": "Date de fermeture",
  "event.label.eventLocation": "Lieu de l’évènement ",
  "event.label.title": "Créer un évènement",
  "event.label.image": "Image à insérer*",
  "event.label.openingHour": "Date et heure de debut de l’évènement",
  "event.label.closingHour": "Date et heure de fin de l’évènement",
  "event.label.doorOpeningHour": "Date et heure de l’ouverture des portes",
  "event.label.price": "Prix de l’évènement",
  "event.label.locationName": "Nom d'emplacement*",
  "event.label.address": "Adresse d'emplacement*",
  "event.label.contactPhone": "Téléphone de contact*",
  "event.label.email": "Email de communication*",
  "event.label.website": "Website*",
  "event.label.terms": "Termes et conditions*",
  "event.label.labelColor": "Titre(iOS)",
  "EventsCustomersTable.Id": "ID (Ticket)",
  "EventsCustomersTable.firstName": "Prénom",
  "EventsCustomersTable.lastName": "Nom",
  "EventsCustomersTable.email": "Email",
  "EventsCustomersTable.enregistre": "Enregistré",
  "EventsCustomersTable.systeme": "Système",
  "EventsCustomersTable.enregistre.true": "Oui",
  "EventsCustomersTable.enregistre.false": "Non",
  "event.label.section.style": "Style de l'événement",
  "event.label.section.timeLocation": "Heure et lieu de l'événement",
  "event.label.selectLocation": "Sélectionnez l'emplacement exact",
  "event.label.section.contact": "Informations de contact",
  "event.label.subMessage": "Message d'abonnement*",
  "event.label.section.emailMessage": "Message de l’email",
  "event.label.emailMessage": "Message de l’email",
  "event.label.create": "Créer événement",
  "event.label.update": "Modifier",
  "event.label.section.info": "Informations du l’événement",
  "email.label.emailMessage": "Message de l'événement",
  "mapComponent.label.search": "Rechercher sur la carte",

  "event.label.eventName": "Nom de l'évènement",
  "event.label.door": "Porte",
  "event.label.row": "rangee",
  "event.label.place": "chaise",
  "event.label.ticketHolder": "Détenteur de billet",
  "event.label.doorsOpen": "Ouverture des portes ",
  "event.label.startTime": "Heure de début de l'événement",
  "event.label.endDate": "Heure de fin de l'événement ",
  "event.label.ticketNumber": "Numéro de billet",

  //Errors
  "event.error.nameRequired": "Nom de l'évènement est obligatoire",
  "event.error.priceRequired": "Prix de l'évènement est obligatoire",
  "event.error.locationNameRequired": "Nom du location est obligatoire",
  "event.error.addressRequired": "address de l'évènement est obligatoire",
  "event.error.contactPhoneRequired": "Telephone de contact est obligatoire",
  "event.error.emailRequired": "Email de communication est obligatoire",
  "event.error.websiteError": "Website est obligatoire",
  "event.error.termsRequired": "Terms sont obligatoire",
  "event.error.imageRequiredRequired": "Image de bande est obligatoire",
  "event.error.subscriptionMessageRequired":
    "Message d'abonnement est obligatoire",

  //******************************* cinematickets list *********************************//

  "cinemaTicket.label.openingDate": "Date d’ouverture",
  "cinemaTicket.label.startingDate": "Date de début",
  "cinemaTicket.label.closingDate": "Date de fermeture",
  "cinemaTicket.label.eventLocation": "Lieu de l’évènement ",
  "cinemaTicket.label.title": "Créer un ticket",
  "cinemaTicket.label.image": "Image à insérer*",
  "cinemaTicket.label.openingHour": "Date et heure de debut de ",
  "cinemaTicket.label.closingHour": "Date et heure de fin de l’évènement",
  "cinemaTicket.label.doorOpeningHour":
    "Date et heure de l’ouverture des portes",
  "cinemaTicket.label.locationName": "Nom d'emplacement*",
  "cinemaTicket.label.address": "Adresse d'emplacement*",
  "cinemaTicket.label.contactPhone": "Téléphone de contact*",
  "cinemaTicket.label.email": "Email de communication*",
  "cinemaTicket.label.website": "Website*",
  "cinemaTicket.label.terms": "Termes et conditions*",
  "cinemaTicket.label.labelColor": "Couleur du titre (iOS)",

  //Labels
  "cinemaTickets.label.title": "Liste des billets du cinema",
  "cinemaTickets.button.create": "Nouvelle billet",
  "cinemaTicket.label.name": "Nom du film",
  "cinemaTicket.label.screen": "Ecran",
  "cinemaTicket.label.price": "Tarif",
  "cinemaTicket.label.id": "ID",
  "cinemaTicket.label.date": "Date",
  "cinemaTicket.label.time": "Temps",
  "wallet.button.cancel": "Annuler",
  "cinemaTicket.label.movieName": "Nom du film",
  "cinemaTicket.label.classification": "Classification",
  "cinemaTicket.label.rating": "Rating",

  //**************************************Profile****************************//
  //Labels
  "profile.label.email": "Adresse Mail",
  "profile.label.password": "Mot de Passe",

  "profile.label.emailSettings": "Paramètres Emailing",
  "profile.label.managerEmail": "Email Principal",
  "profile.label.communicationEmail": "Email de communication",
  "profile.label.communicationEmailHost": "Serveur Hébergeur (Host)",
  "profile.label.communicationEmailPort": "Numéro de Port",
  "profile.label.communicationEmailUser": "Nom d'utilisateur",
  "profile.label.communicationEmailPass": "Mot de passe",
  "profile.label.emailSettingsConfirmation":
    "Veuillez saisir votre mot de passe",

  "profile.label.updatePasswordTitle": "Changer le mot de passe",
  "profile.label.oldPassword": "Ancien mot de passe",
  "profile.label.newPassword": "Nouveau mot de passe",
  "profile.label.confirmPassword": "Confirmer le mot de passe",
  //Placeholder
  "profile.placeholder.managerEmail": "Votre Email ...",
  "profile.placeholder.communicationEmailHost": "xx.xxx.xx ",
  "profile.placeholder.communicationEmailPort": "0000",
  "profile.placeholder.communicationEmailUser": "nom d'utilisateur ....",
  "profile.placeholder.communicationEmailPass": "mot de passe ...",
  "profile.placeholder.oldPassword": "Ancien mot de passe ...",
  "profile.placeholder.newPassword": "Nouveau mot de passe ...",
  "profile.placeholder.confirmPassword": "Confirmer le mot de passe ...",
  //Buttons
  "profile.button.confirmPassword": "Confirmer",
  "profile.button.updateEmailSettings": "Mettre à jour",
  "profile.button.updatePassword": "Mettre à jour",
  //Success
  "profile.success.successMessage": "Mis à jour avec succès",
  //Error
  "profile.error.oldPasswordRequired": "Ancien mot de passe est obligatoire.",
  "profile.error.oldPasswordIncorrect":
    "Votre mot de passe actuel est incorrect.",
  "profile.error.newPasswordRequired": "Nouveau mot de passe est obligatoire.",
  "profile.error.newPasswordRequiredLength":
    "Le mot de passe doit contenir au moins 4 caractères.",
  "profile.error.confirmPasswordRequired":
    "Confirm mot de passe est obligatoire.",
  "profile.error.confirmPasswordNotMatch":
    "La confirmation ne correspond pas au nouveau mot de passe.",
  "profile.error.emailSettingsError": "Configuration Email est invalide",
  "profile.error.emailCommunication":
    "Erreur de configuration, veuillez vérifier les paramètres saisies.",
  "profile.error.userPasswordError": "Mot de passe incorrect",
  "profile.error.managerEmail": "Email Principal est obligatoire",
  "profile.error.userPrincipalEamilExistsError":
    "Adresse email déjà existante, essayez avec une autre",
  //**************************************Forgot Password****************************//
  //Labels
  "forgotPassword.label.email": "Saisissez votre nom d'utilisateur",
  //Placeholder
  "forgotPassword.placeholder.email": "Nom d'utilisateur",
  //Buttons
  "forgotPassword.button.confirm": "Confirmer",
  //Error
  "forgotPassword.error.emailRequired": "Nom d'utilisateur est obligatoire",
  "forgotPassword.error.invalidEmail": "Nom d'utilisateur invalide",
  //**************************************Reset Password****************************//
  //Labels
  "resetPassword.label.password": "Nouveau mot de passe:",
  "resetPassword.label.passwordConfirm": "Confirmez le mot de passe:",
  //Placeholder
  "resetPassword.placeholder.password": "Saisissez votre nouveau mot de passe",
  "resetPassword.placeholder.passwordConfirm": "Confirmer votre mot de passe",
  //Buttons
  "resetPassword.button.confirm": "Confirmer",
  //Error
  "resetPassword.error.passwordRequired":
    "Nouveau mot de passe est obligatoire.",
  "resetPassword.error.passwordRequiredLength":
    "Le mot de passe doit contenir au moins 4 caractères.",
  "resetPassword.error.passwordConfirmRequired":
    "Confirmer le mot de passe est obligatoire.",
  "resetPassword.error.passwordConfirmNotMatch":
    "La confirmation ne correspond pas au nouveau mot de passe.",
  "resetPassword.error.tokenExpired":
    "Désolé, votre token a expiré ! Vous devrez renvoyer votre e-mail.",
  //**************************************ships List****************************//
  //Labels
  "ships.label.title": "Liste des billets bateaux",
  "ships.label.emptyList": "La liste des billets bateaux est vide",
  "ships.label.filter": "Filtrer par",
  "ships.label.startDate": "Date de début",
  "ships.label.endDate": "Date de fin",
  "ships.label.allTime": "Tout le temps",
  //Select
  "ships.select.originName": "Station de départ",
  "ships.select.destinationName": `Station d'arrivé`,
  "ships.select.departureTime": "Date de départ",
  "ships.select.arrivalTime": `Date d'arrivé`,
  //Dialog
  "ships.dialog.title": "Information",
  "ships.dialog.text.inactif": "Voulez-vous vraiment désactiver cette offre ?",
  "ships.dialog.text.action": "",
  "ships.dialog.text.deleted": "Voulez-vous vraiment supprimer cette offre ?",
  "ships.label.originName": "Origin",
  "ships.label.origin": "Station de départ",

  "ships.label.destinationName": "Destination",
  "ships.label.departureTime": "Heures de départ",
  "ships.label.arrivalTime": `Heures d'arrivé`,
  //Buttons
  "ships.button.search": "Rechercher",
  "ships.button.create": "Nouvel Transit",
  "ship.button.add": "Sauvgarder",
  "sent.success": "bien envoyé",
  //**************************************Flights List****************************//
  //Labels
  "flights.label.title": "Liste des billets d'avions",
  "flights.label.emptyList": "La liste des billets bateaux est vide",
  "flights.label.filter": "Filtrer par",
  "flights.label.startDate": "Date de début",
  "flights.label.endDate": "Date de fin",
  "flights.label.allTime": "Tout le temps",
  "settings.label.letEmpty": "Laisser vide pour appliquer la valeur par défaut",

  //Select
  "flights.select.originName": "Station départ",
  "flights.select.destinationName": "Station arrivé",
  "flights.select.departureTime": "Date de départ",
  "flights.select.arrivalTime": `Date d'arrivé`,
  //Dialog
  "flights.dialog.title": "Information",
  "flights.dialog.text.inactif":
    "Voulez-vous vraiment désactiver cette offre ?",
  "flights.dialog.text.action": "",
  "flights.dialog.text.deleted": "Voulez-vous vraiment supprimer cette offre ?",

  //Buttons
  "flights.button.search": "Rechercher",
  "flights.button.create": "Nouvelle vol",
  "flights.button.add": "Sauvgarder",

  //**************************************flight Card****************************//

  //Labels
  "flight.label.number": "Numéro",
  "flight.label.airportIataCode": "Code Iata de l'aéroport",
  "flight.label.gate": "Porte",
  "flight.label.terminal": "Terminal",
  "flight.label.airportName": "Nom de l'aéroport",
  "flight.label.departureDateTime": "Date du vol",
  "flight.label.layovers": "Escaliers",
  "flight.label.carrierIataCode": "Code Iata du transporteur",
  "flight.label.seatNumber": "Chaise",
  "flight.label.boardingGroup": "Groupe",
  "flight.label.seatClass": "Classe",
  "flight.label.confirmationCode": "Code de confirmation",
  "flight.label.terminalAndGate": "Terminal/Porte",
  "flight.label.class": "Cabine",
  "flight.label.zone": "Zone / Siège",
  "flight.label.addFlight": "Ajouter un pass transportation",
  "flight.label.details": "Details",
  "flight.label.logo": "Logo à insérer*",
  "flight.label.customerServiceNumber": "Numéro de service client",
  "flight.label.estimatedDepartureTime": "Heure de départ estimée",
  "flight.label.arrivalGate": "Porte d'arrivée",
  "flight.label.destinationTerminale": "Terminale de destination",
  "flight.label.layover": "Escalier ",
  "flight.label.addLayover": "Ajouter un escalier",

  //Error
  "flight.error.airportNameRequired": "Nom de l'aéroport  est obligatoire",
  "flight.error.gateRequired": "Porte est obligatoire",
  "flight.error.terminalRequired": "Terminal est obligatoire",
  "flight.error.airportIATAcodeRequired":
    "Code IATA de l'aéroport Obligatoire ",
  "flight.error.dateTimeRequired": "date requise",
  "flight.error.carrierIataCodeRequired": "Code Iata du transporteur requis ",

  "flight.error.numberRequired": "Numéro est obligatoire",
  "flight.error.detailsRequired": "Details est obligatoire",
  "flight.error.logoRequired": "Logo  est obligatoire",
  "flight.error.customerServiceNumberRequired":
    "Numéro de service client est obligatoire",
  "flight.error.imageRequired": "image  est obligatoire",
  "flight.error.stripRequired": "Logo est obligatoire",

  //**************************************ship Card****************************//
  //Labels
  "shipCard.label.id": "ID:",
  "shipCard.label.status": "Status",
  "shipCard.label.startDate": "Date de début",
  "shipCard.label.endDate": "Date de fin",
  "shipCard.label.description": "Description",
  "ferry.label.departureDate": "Date Départ",
  "ferry.label.arrivalDate": "Date Arrivé",
  "rail.label.departureDate": "Date Départ",
  "rail.label.arrivalDate": "Date Arrivé",
  "bus.label.departureDate": "Date Départ",
  "bus.label.arrivalDate": "Date Arrivé",
  "ferry.label.passengerType": "Type de passager",
  "ferry.label.tripType": "Type de voyage",
  //Buttons
  "shipCard.button.desactivate": "Désactiver",
  "shipCard.button.delete": "Supprimer",

  //Labels
  "ship.label.number": "Numéro",
  "ship.label.originStationCode": "Code gare départ",
  "ship.label.originName": "Station Départ",
  "ship.label.destinationStationCode": "Code  gare arrivé",
  "ship.label.destinationName": "Station Arrivé",
  "ship.label.departureTime": "Temps Départ",
  "ship.label.arrivalTime": "Temps Arrivé",
  "ship.label.tarif": "Tarif",
  "ship.label.details": "Details",
  "ship.label.terms": "Termes",
  "ship.label.logo": "Logo à insérer*",
  "ship.label.customerServiceNumber": "Numéro de service client",

  "ship.label.addShip": "Ajouter un pass transportation",
  "ship.label.departureDate": "Départ",
  "ship.label.arrivalDate": "Arrivée à",
  "ship.label.passenger": "PASSAGER",
  "ship.label.ticketNumber": "Numero du billet",

  //Error
  "ship.error.arrivalTimeRequired": "L'heure est obligatoire",
  "ship.error.departureTimeRequired": "L'heure est obligatoire",
  "ship.error.originNameRequired": "Station Départ est obligatoire",
  "ship.error.destinationNameRequired": "Station Arrivé est obligatoire",
  "ship.error.originStationCodeRequired": "Code gare est obligatoire",
  "ship.error.destinationStationCodeRequired": "Code gare est obligatoire",
  "ship.error.numberRequired": "Numéro est obligatoire",
  "ship.error.tarifRequired": "Tarif est obligatoire",
  "ship.error.detailsRequired": "Details est obligatoire",
  "ship.error.termsRequired": "Termes est obligatoire",
  "ship.error.logoRequired": "Logo  est obligatoire",
  "ship.error.customerServiceNumberRequired":
    "Numéro de service client est obligatoire",
  "ship.error.imageRequired": "image  est obligatoire",
  "ship.error.stripRequired": "Logo est obligatoire",
  "ferry.error.createdAtRequired": "Date est obligatoire",
  "ferry.error.createdAtInvalid": "Date est invalid",
  "ferry.error.passengerTypeRequired": "Type est obligatoire",
  "ferry.error.tripTypeRequired": "Type est obligatoire",
  "ferry.error.phoneNumberRequired": "Numéro est obligatoire",
  ////Buses
  "buses.label.title": "Liste des Transits Bus",
  ///Trams
  "trams.label.title": "Liste des Transits Metro",
  ///Rails
  "rails.label.title": "Liste des Transits Train",
  ///Hotels
  //**************************************Hotels List****************************//
  //Labels
  "hotels.label.title": "Liste des chambres",
  "hotels.label.emptyList": "La liste des chambres est vide",
  "hotels.label.HotelName": "Nom du Hotél",
  "hotels.label.RoomType": "Type de chambre",
  "hotels.label.RoomNumber": "Numéro de chambre",
  "hotel.label.addHotel": "Ajouter une chambre",
  //Dialog
  "hotels.dialog.title": "Information",
  "hotels.dialog.text":
    "Voulez-vous envoyer cette chambre à tous les clients ?",
  //Buttons
  "hotels.button.create": "Nouvelle Chambre",

  //////******Hotel ****//////
  ////buttons
  "hotel.button.add": "Sauvgarder",
  ////labels
  "hotel.label.name": "Nom de hôtel:",
  "hotel.label.type": "Type de chambre:",
  "hotel.label.number": "Numéro de chambre:",
  "hotel.label.address": "Adresse de hôtel:",
  "hotel.label.email": "Adresse e-mail:",
  "hotel.label.website": "Site de hôtel:",
  "hotel.label.contactPhone": "Numéro du contact:",
  "hotel.label.terms": "Condition d utilisation",
  "hotel.label.logo": "Image à insérer*",
  //////******Hotels ****//////
  //Labels
  "hotels.label.filter": "Filtrer par",
  "hotels.label.startDate": "Date de début",
  "hotels.label.endDate": "Date de fin",
  "hotels.label.allTime": "Tout le temps",
  "hotel.label.createdAt": "Date de creation",
  "hotel.label.checkin": "Date d'arriver",
  "hotel.label.checkout": "Date de quitter",
  //Select
  "hotels.select.name": "Nom de hotel",
  "hotels.select.type": "Type de chambre",
  "hotels.select.departureTime": "Date départ",
  "hotels.select.arrivalTime": "Date arrivé",

  "hotel.select.single": "Single ",
  "hotel.select.double": "Double",
  "hotel.select.triple": "tripler",
  "hotel.select.Quad": "Quadruple",
  "hotel.select.Queen": "reine",
  "hotel.select.King": "Roi",
  "hotel.select.Twin": "Double",
  "hotel.select.DoubleDouble": "Double double",
  "hotel.select.Studio": "Studio",
  "hotel.select.Suite": "Suite",
  "hotel.select.JuniorSuite": "Suite junior",
  "hotel.select.PresidentSuite": "Suite Président",
  "hotel.select.apartment": "appartement",
  "hotel.select.ConnectingRoom": "Chambre communicante",
  "hotel.select.MurphyRoom": "Chambre Murphy",
  "hotel.select.AccessibleRoom": "Chambre Accessible",
  "hotel.select.AdjoiningRoom": "Chambre Attenante",
  "hotel.select.AdjacentRoom": "Chambre Adjacente",
  "hotel.select.villa": "Villa",

  //error hotel customer

  "hotel.error.createdAtRequired": "La date de creation est obligatoire",
  "hotel.error.createdAtInvalid": "Date de creation non valide",

  "hotel.error.checkinRequired": "La date d'arriver est obligatoire",
  "hotel.error.checkinInvalid": "Date d'arriver non valide",

  "hotel.error.checkoutRequired": "La date de quitter est obligatoire",
  "hotel.error.checkoutInvalid": "Date de quitter non valide",
  //Bus customer
  "bus.label.createdAt": "Date de Creation",
  "bus.label.passengerType": "Type de passager",
  "bus.label.tripType": "Type de voyage",
  "bus.label.phoneNumber": "Numéro de Télephone",
  //error bus customer

  "bus.error.createdAtRequired": "La date de creation est obligatoire",
  "bus.error.createdAtInvalid": "Date de creation non valide",

  "bus.error.checkinRequired": "La date d'arriver est obligatoire",
  "bus.error.checkinInvalid": "Date d'arriver non valide",

  "bus.error.checkoutRequired": "La date de quitter est obligatoire",
  "bus.error.checkoutInvalid": "Date de quitter non valide",
  //Tram customer
  "tram.label.createdAt": "Date de Creation",
  "tram.label.passengerType": "Type de passager",
  "tram.label.tripType": "Type de voyage",
  "tram.label.phoneNumber": "Numéro de Télephone",
  //error tram customer

  "tram.error.createdAtRequired": "La date de creation est obligatoire",
  "tram.error.createdAtInvalid": "Date de creation non valide",

  "tram.error.checkinRequired": "La date d'arriver est obligatoire",
  "tram.error.checkinInvalid": "Date d'arriver non valide",

  "tram.error.checkoutRequired": "La date de quitter est obligatoire",
  "tram.error.checkoutInvalid": "Date de quitter non valide",
  "tram.error.departureDateRequired": "Date est obligatoire",
  "tram.error.departureDateInvalid": "Date est invalid",
  "tram.error.arrivalDateRequired": "Date est obligatoire",
  "tram.error.arrivalDateInvalid": "Date est invalid",
  "tram.error.passengerTypeRequired": "Type est obligatoire",
  "tram.error.tripTypeRequired": "Type est obligatoire",
  "tram.error.phoneNumberRequired": "Numéro est obligatoire",
  "tram.label.departureDate": "Date Départ",
  "tram.label.arrivalDate": "Date Arrivé",
  //Rail customer
  "rail.label.createdAt": "Date de Creation",
  "rail.label.passengerType": "Type de passager",
  "rail.label.tripType": "Type de voyage",
  "rail.label.phoneNumber": "Numéro de Télephone",
  //error rail customer

  "rail.error.createdAtRequired": "La date de creation est obligatoire",
  "rail.error.createdAtInvalid": "Date de creation non valide",

  "rail.error.checkinRequired": "La date d'arriver est obligatoire",
  "rail.error.checkinInvalid": "Date d'arriver non valide",

  "rail.error.checkoutRequired": "La date de quitter est obligatoire",
  "rail.error.checkoutInvalid": "Date de quitter non valide",

  // customer errors
  "ferry.error.arrivalDateRequired": "La date arrivé est obligatoire",
  "ferry.error.departureDateRequired": "La date de depart est obligatoire",

  //**************************************bus Card****************************//
  //Labels
  "busCard.label.id": "ID:",
  "busCard.label.status": "Status",
  "busCard.label.startDate": "Date de début",
  "busCard.label.endDate": "Date de fin",
  "busCard.label.description": "Description",
  "busCard.label.departureDate": "Date Départ",
  "busCard.label.arrivalDate": "Date Arrivé",
  "busCard.label.departureTime": "Heure de départ",
  "busCard.label.arrivalTime": "Heure d'arrivé",

  "busCard.button.desactivate": "Désactiver",
  "busCard.button.delete": "Supprimer",

  //**************************************transit Card****************************//
  //Labels
  "transitCard.label.id": "ID:",

  "transitCard.label.description": "Description",
  "transitCard.label.departureDate": "Date de départ",
  "transitCard.label.arrivalDate": `Date d'arrivé`,
  "transitCard.label.departureTime": "Heure de départ",
  "transitCard.label.arrivalTime": "Heure d'arrivé",
  "transitCard.label.originName": "Station de départ",
  "transitCard.label.destinationName": `Station d'arrivé`,
  "transitCard.label.destination": `Station d'arrivé`,

  "transit.label.sentTransits": "List of sent tickets",

  //Labels
  "transitCard.label.status": "Status",
  "transitCard.label.startDate": "Start date",
  "transitCard.label.endDate": "End date",
  "transit.label.passengerType": "Passenger type",
  "transit.label.tripType": "Trip type",

  //Labels
  "transit.label.number": "Numéro",
  "transit.label.originStationCode": "Code gare départ",
  "transit.label.originName": "Origin name",
  "transit.label.destinationStationCode": "Destination code",
  "transit.label.destinationName": "Station Arrivé",
  "transit.label.departureTime": "Heure de départ",
  "transit.label.arrivalTime": "Heure d'arrivé",
  "transit.label.tarif": "Tarif",
  "transit.label.details": "Details",
  "transit.label.terms": "Termes",
  "transit.label.logo": "Logo*",
  "transit.label.customerServiceNumber": "Numéro de service client",

  "transit.label.addTrip": "Add a trip",
  "transit.label.departureDate": "Départ",
  "transit.label.arrivalDate": "Date d'arrivé",
  "transit.label.passenger": "Passager",
  "transit.label.ticketNumber": "Numéro du ticket",
  //messages

  "user.success.added": "L'utilisateur a été ajouté avec succès",
  "event.success.added": "L'événement a été ajouté avec succès",
  "wallet.error.occured": "Erreur est survenue",
  "emailsms.error.failedSent": "Problème d'envoi de SMS et de mail",
  "sms.error.failedSent": "Problème d'envoi de SMS",
  "email.error.failedSent": "Problème d'envoi de email",
  "sms.error.insuffisantCredit": "Crédit insuffisant",
  "phone.error.alreadyExists": "Le numéro de téléphone existe déjà",
  "email.error.alreadyExists": "L'adresse mail existe déjà",
  "sms.error.failedCredits": "Erreur lors de l'obtention du crédit",
  "transit.success.update": "Le transit a été modifié avec succès",
  "transit.success.delete": "Le Transit a été supprimé avec succès",
  "cinema.success.added": "La ticket a été ajouté avec succès",
  "cinema.success.deleted": "La ticket a été supprimé avec succès",
  "coupon.success.added": "Le coupon a été ajouté avec succès",
  "coupon.error.duplicatedPromo": "Le code promo est dupliqué",
  "coupon.error.updated": "Le coupon a été modifié avec succès",
  "coupon.info.couponActive": "Il y a déjà un coupon actif",
  "coupon.success.deleted": "Le coupon a été supprimé avec succès",
  "coupon.success.sent": "Les coupons ont été envoyés avec succès.",
  "event.success.sent": "L'événement a été ajouté avec succès",
  "event.success.update": "L'événement a été mis à jour avec succès",
  "flight.success.added": "Le vol a été ajouté avec succès",
  "flight.success.updated": "Le vol a été modifié avec succès",
  "flight.success.deleted": "Le vol a été supprimé avec succès",
  "room.success.added": "La Chambre a été ajouté avec succès",
  "room.success.updated": "Le hotel a été modifié avec succès",
  "room.success.deleted": "La Chambre a été supprimé avec succès",
  "offer.success.updated": "L'offre a été modifié avec succès",
  "offer.success.added": "L'offre a été ajouté avec succès",
  "rail.success.added": "L'événement a été ajouté avec succès",
  "rail.success.updated": "Le transit a été modifié avec succès",
  "rail.success.deleted": "Le Transit a été supprimé avec succès",
  "wallet.success.settingsUpdated":
    "Les paramètres sont sauvegardés avec succès",
  "transit.success.added": "L'événement a été ajouté avec succès",
  "transit.success.updated": "Le transit a été modifié avec succès",
  "transit.success.deleted": "transit a été supprimé avec succès",
  "customer.success.deleted": "L'utilisateur a été supprimé avec succès",
  "customer.info.emptyList": "La liste des clients est vide",
  "customer.success.exported":
    "La liste des clients a été exportée avec succée",
};
