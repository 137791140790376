import React from "react";
import { useIntl } from "react-intl";
import Box from "@material-ui/core/Box";
import Icon from "react-icons-kit";
import {
  ic_call,
  ic_email,
  ic_location_on,
  ic_public,
} from "react-icons-kit/md";
import { ratio } from "wcag-color";
import QRCode from "qrcode.react";
import moment from "moment";
//images and icons
import DirectionsIcon from "@material-ui/icons/Directions";
import { getDataFromCache } from "../../utils/cache";
import barcode from "../../Assets/images/barcode.png";
import cover from "../../Assets/images/lg-thumbnail.png";
import defaultLogo from "../../Assets/images/sm-thumbnail.png";
//styles
import { Wrapper } from "./eventWireframe.style";
import {
  StyledDetailsBotton,
  StyledDetailsBottonContainer,
  StyledExpandMoreIcon,
  StyledHeaderContainer,
  StyledSeperator,
} from "../../Commons";
import { StyledMainSection } from "../../Commons/CommonStyles/CommonStyles";

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  priceCurrency?: string;
  priceLabel?: string;
  price: any;
  dateLabel?: string;
  openDate: any;
  startDateLabel?: any;
  startDate: any;
  endDate: any;
  eventName: string;
  locationName: string;
  locationAddress: string;
  subscriptionMessage: any;
  QrCode: any;
  logo?: any;
  timeLabel?: string;
  doorLabel?: string;
  door?: string;
  rowLabel?: string;
  row?: string;
  placeLabel?: string;
  place?: string;
  walletId?: string;
  barCodeLink?: string;
  ticketHolderLabel?: string;
  ticketHolder?: string;
  ticketNumberLabel?: string;
  ticketNumber?: string;
  termsLabel?: string;
  terms?: string;
  doorsOpenLabel?: string;
  googleMapsLink?: string;
  infoLine?: string;
  email?: string;
  disableNotifications?: string;
};

const EventWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    locationName,
    locationAddress,
    eventName,
    priceCurrency,
    priceLabel,
    price,
    endDate,
    startDate,
    openDate,
    QrCode,
    logo,
    dateLabel,
    timeLabel,
    doorLabel,
    door,
    rowLabel,
    row,
    placeLabel,
    place,
    walletId,
    barCodeLink,
    ticketHolderLabel,
    ticketHolder,
    doorsOpenLabel,
    startDateLabel,
    ticketNumberLabel,
    ticketNumber,
    termsLabel,
    terms,
    googleMapsLink,
    infoLine,
    email,
    disableNotifications,
  } = props;

  const currentWallet = getDataFromCache("wallet");
  const intl = useIntl();
  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>("800px");

  const handleChange = () => {
    setShowDetails(!showDetails);
    height === "100%" ? setHeight("100%") : setHeight("100%");
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      titleColor={"rgba(255, 80, 120, 0.83)"}
      textColor={"#000"}
      visibilty={showDetails}
    >
      <StyledMainSection
        backgroundColor={backgroundColor}
        className="mainSection"
      >
        <StyledHeaderContainer
          mainContentTextColor={
            ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
          }
        >
          <div className="mainSection-header-logo">
            <img
              src={
                !logo
                  ? defaultLogo
                  : typeof logo === "string"
                  ? logo
                  : URL.createObjectURL(logo)
              }
              alt="wallet Logo"
            />
          </div>
          <h4>{!eventName ? '"Enseigne"' : eventName}</h4>
        </StyledHeaderContainer>
        <div className="mainSection-content">
          <div className="mainSection-content-infos">
            <div className="mainSection-content-enseigne">
              <div className="mainSection-location">
                {locationName ||
                  intl.formatMessage({ id: "event.label.locationName" })}
              </div>
              <div className="mainSection-eventName">
                {eventName ||
                  intl.formatMessage({ id: "event.label.eventName" })}
              </div>
            </div>
            <div className="mainSection-content-datePoints">
              <div className="date">
                <h3 className="mainSection-label">
                  {dateLabel || intl.formatMessage({ id: "wallet.label.date" })}
                </h3>
                <h3 className="mainSection-data">
                  {moment(openDate).format("ll")}
                </h3>
              </div>
              <div className="points">
                <h3 className="mainSection-label">
                  {timeLabel || intl.formatMessage({ id: "wallet.label.time" })}
                </h3>
                <h3 className="mainSection-data">
                  {moment(openDate).format("LT")}
                </h3>
              </div>
            </div>
            <StyledSeperator></StyledSeperator>
            <div className="mainSection-content-event-infos">
              <div className="date" style={{ textAlign: "left" }}>
                <h3 className="mainSection-event-label">
                  {doorLabel || intl.formatMessage({ id: "event.label.door" })}
                </h3>
                <h3 className="mainSection-data">{door}</h3>
              </div>
              <div className="points" style={{ textAlign: "center" }}>
                <h3 className="mainSection-event-label">
                  {rowLabel || intl.formatMessage({ id: "event.label.row" })}
                </h3>
                <h3 className="mainSection-data">{row}</h3>
              </div>
              <div className="points" style={{ textAlign: "right" }}>
                <h3 className="mainSection-event-label">
                  {placeLabel ||
                    intl.formatMessage({ id: "event.label.place" })}
                </h3>
                <h3 className="mainSection-data">{place}</h3>
              </div>
            </div>
            <div className="mainSection-content-barCode">
              {QrCode.state ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "270px",
                  }}
                >
                  <QRCode includeMargin value={QrCode.value} />
                </div>
              ) : (
                <>
                  <div className="barCode">
                    <img src={barCodeLink || barcode} alt="barcode" />
                  </div>
                  <p className="walletId">{walletId}</p>
                </>
              )}
            </div>
          </div>
          <div className="mainSection-content-banner">
            <img
              src={
                !strip
                  ? cover
                  : typeof strip === "string"
                  ? strip
                  : URL.createObjectURL(strip)
              }
              alt="banner"
            />
          </div>
        </div>
      </StyledMainSection>
      <StyledDetailsBottonContainer>
        <StyledDetailsBotton
          disableRipple
          onClick={handleChange}
          startIcon={<StyledExpandMoreIcon showDetails={showDetails} />}
          variant="outlined"
        >
          Details
        </StyledDetailsBotton>
      </StyledDetailsBottonContainer>
      <div className="dataSection">
        {showDetails && (
          <>
            <span className="divider"></span>
            <div className="sectionLabel">
              <h3 className="label">
                {ticketHolderLabel ||
                  intl.formatMessage({ id: "event.label.ticketHolder" })}
              </h3>
              <h3 className="data">{ticketHolder}</h3>
            </div>
            <span className="divider"></span>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              className="sectionLabel"
            >
              <div>
                <h3 className="label">
                  {locationName ||
                    intl.formatMessage({ id: "event.label.locationName" })}
                </h3>
                <h3 className="data">
                  {locationAddress ||
                    intl.formatMessage({ id: "event.label.address" })}
                </h3>
              </div>
              <DirectionsIcon />
            </Box>
            <span className="divider"></span>
            <div className="sectionLabel">
              <h3 className="label">
                {doorsOpenLabel ||
                  intl.formatMessage({ id: "event.label.doorsOpen" })}
              </h3>
              <h3 className="data">
                {moment(openDate).format("YYYY Do MMM LT")}
              </h3>
            </div>
            <span className="divider"></span>
            <div className="sectionLabel">
              <h3 className="label">
                {" "}
                {startDateLabel ||
                  intl.formatMessage({ id: "event.label.startTime" })}
              </h3>
              <h3 className="data">{startDate}</h3>
            </div>
            <span className="divider"></span>
            <div className="sectionLabel">
              <h3 className="label">
                {ticketNumberLabel ||
                  intl.formatMessage({ id: "event.label.ticketNumber" })}
              </h3>
              <h3 className="data">{ticketNumber}</h3>
            </div>
            {/* <span className="divider"></span> */}
            {/* <div className="sectionLabel">
              <h3 className="label">{intl.formatMessage({ id: "event.label.ticketNumber" })}</h3>
              <h3 className="data">6066e4315abbcc1f9526d392</h3>
            </div> */}
            <span className="divider"></span>
            <div className="sectionLabel">
              <h3 className="label">
                {priceLabel || intl.formatMessage({ id: "wallet.label.value" })}
              </h3>
              <h3 className="data">
                {price ? `${price} ${priceCurrency}` : ""}{" "}
              </h3>
            </div>
            <span className="divider"></span>
            <div className="sectionLabel">
              <h3 className="label">
                {termsLabel ||
                  intl.formatMessage({
                    id: "wallet.label.termsAndConditions",
                  })}
              </h3>
              <h3 className="data">{terms || ""}</h3>
            </div>
            <div className="sectionIcon">
              <Icon
                icon={ic_location_on}
                size={23}
                style={{ color: "#444444" }}
              />
              <h3 className="data"> {googleMapsLink || "Google Map"}</h3>
            </div>
            <div className="sectionIcon">
              <Icon icon={ic_call} size={23} style={{ color: "#444444" }} />
              <h3 className="data">
                {infoLine ||
                  intl.formatMessage({
                    id: "wallet.label.infoLine",
                  })}
              </h3>
            </div>
            <div className="sectionIcon">
              <Icon icon={ic_email} size={23} style={{ color: "#444444" }} />
              <h3 className="data">
                {email ||
                  intl.formatMessage({
                    id: "wallet.select.email",
                  })}
              </h3>
            </div>
            <div className="sectionIcon">
              <Icon icon={ic_public} size={23} style={{ color: "#444444" }} />
              <h3 className="data">
                {" "}
                {disableNotifications || "Disabled notifications"}{" "}
              </h3>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default EventWireframeAndroid;
