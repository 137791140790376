import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { ic_more_horiz } from "react-icons-kit/md/ic_more_horiz";
import Icon from "react-icons-kit";
import { ratio } from "wcag-color";

import { getDataFromCache } from "../../utils/cache";
import { URL_IMAGE } from "../../apis/config";

import cover from "../../Assets/images/lg-thumbnail.png";
import barcode from "../../Assets/images/barcode.png";

import { Wrapper } from "./couponWireframe.style";

type WireframeProps = {
  backgroundColor: any;
  promotion: any;
  image: any;
  promoCode: any;
  description: any;
  titleColor: string;
  textColor: string;
  name: string;
  symbol: string;
  logo?: any;
  expires: any;
};

const CouponWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    image,
    promotion,
    promoCode,
    description,
    titleColor,
    textColor,
    name,
    symbol,
    expires,
    logo,
  } = props;
  const currentWallet = getDataFromCache("wallet");
  const [showDetails, setShowDetails] = React.useState(false);
  const backFlip = () => {
    setShowDetails(!showDetails);
  };
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      titleColor={titleColor}
      textColor={textColor}
      flip={showDetails}
    >
      <div className="pass">
        <div className="mainSection">
          <div className="front">
            <div className="mainSection-header">
              <div className="mainSection-header-logoSection">
                <img
                  src={
                    logo
                      ? logo
                      : `${URL_IMAGE}/${currentWallet?.logoPictureUrl}`
                  }
                  alt="wallet Logo"
                />
                <h3>{name || "Coupon"}</h3>
              </div>
              <div className="mainSection-header-name">
                <h6>ALAN</h6>
                <h3>Jason</h3>
              </div>
            </div>

            <div className="mainSection-content-banner">
              <img
                src={
                  !image
                    ? cover
                    : typeof image === "string"
                    ? image
                    : URL.createObjectURL(image)
                }
                alt="banner"
              />
            </div>

            <div className="mainSection-content-datePoints">
              <div className="points">
                <h3 className="mainSection-label">Value</h3>
                <h3 className="mainSection-subdata">
                  {promotion || 0 + " " + symbol}
                </h3>
              </div>
              <div className="date">
                <h3 className="mainSection-label">Expiration Date</h3>
                <h3 className="mainSection-subdata">{expires}</h3>
              </div>
            </div>

            <div className="mainSection-content-barCode">
              <div className="barCode">
                <img src={barcode} alt="barcode" />
                <p className="walletId">{promoCode || ""}</p>
              </div>
            </div>
            <IconButton
              className="backFlip_btn"
              size="small"
              onClick={backFlip}
            >
              <Icon size={21} icon={ic_more_horiz} />
            </IconButton>
          </div>
        </div>
        <div className="dataSection">
          <div className="back">
            <div className="sectionLabel">
              <h3 className="label">Description</h3>
              <h3 className="data">{description}</h3>
            </div>
            <div className="divider" />
            <div className="sectionLabel">
              <h3 className="label">Promo Code</h3>
              <h3 className="data">B3S7D3M0</h3>
            </div>
            <div className="divider" />
            <div className="sectionLabel">
              <h3 className="label">Coupon ID</h3>
              <h3 className="data">625e9cc9197e60007963fd7</h3>
            </div>
            <IconButton
              className="backFlip_btn"
              size="small"
              onClick={backFlip}
            >
              <Icon size={21} icon={ic_more_horiz} />
            </IconButton>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CouponWireframeIPhone;
