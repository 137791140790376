import * as React from "react";
import Box from "@material-ui/core/Box";

import { Loading } from "../../Commons";

import BusWireframeAndroid from "../../Components/BusWireframeAndroid";
import CinemaTicketWireframeAndroid from "../../Components/CinemaTicketWireframeAndroid";
import CouponWireframeAndroid from "../../Components/CouponWireframeAndroid";
import EventWireframeAndroid from "../../Components/EventWireframeAndroid";
import FerryWireframeAndroid from "../../Components/FerryWireframeAndroid";
import FlightWireframeAndroid from "../../Components/FlightWireframeAndroid";
import HotelWireframeAndroid from "../../Components/HotelWireframeAndroid";
import TramWireframeAndroid from "../../Components/TramWireframeAndroid";
import RailWireframeAndroid from "../../Components/RailWireframeAndroid";
import StorecardWireframeAndroid from "../../Components/Wireframe";

import { AndroidCardStyled } from "./AndroidCard-style";
import { useIntl } from "react-intl";

type cardProps = {
  selectedPass: string;
  loading: boolean;
};

export const AndroidCard = (props: cardProps): JSX.Element => {
  const { selectedPass, loading } = props;
  const intl = useIntl();
  const androidWireframes: any = {
    storecard: (
      <StorecardWireframeAndroid
        backgroundColor={{ hex: "rgba(70,70,247,0.1)", rgb: "rgb(0,0,0)" }}
        logo="https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        strip="https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/storecard-strip.jpg"
        address="65 St. Germain Street"
        descriptions={[]}
        name="Best - Demo"
        gainLabel={intl.formatMessage({
          id: "customersTable.gains",
        })}
        gain="1.50"
        gainUnit="$"
        additionalContacts={[]}
        textColor="#000"
        titleColor="#1C423D"
        title="Store card"
        date="09/13/2022"
        dateLabel={intl.formatMessage({
          id: "wallet.label.date",
        })}
        walletIdLabel={intl.formatMessage({
          id: "wireframes.storecard.label.memberId",
        })}
        walletId="5g1hdvb82154df853fca14835"
        detailsButtonText="Details"
        clientNameLabel="Client name"
        clientName="Alan Jason"
        addressLabel={intl.formatMessage({
          id: "wireframes.storecard.label.adress",
        })}
        typeLabel={intl.formatMessage({
          id: "wallet.type.label",
        })}
        type="Silver"
        descriptionLabel="Ready to wear for men and women"
        description="Come and discover ourcollections of well- known and recognized
        brands and our other little nuggets ..."
        offerDescriptionLabel={intl.formatMessage({
          id: "wireframes.storecard.label.offerDescription",
        })}
        offerDescription="No offers"
        CommercialPhoneLabel={intl.formatMessage({
          id: "wireframes.storecard.label.commercialPhone",
        })}
        CommercialPhone="+33 891 060 698"
        AfterSaleService="+33 891 060 698"
        AfterSaleServiceLabel={intl.formatMessage({
          id: "wireframes.storecard.label.SAV",
        })}
        website="https://website.com"
        disableNotifications="Disabled notifications"
        scheduleLabel={intl.formatMessage({
          id: "wireframes.storecard.label.schedule",
        })}
        scheduleOpeningHours="10:00 AM - 4:00 PM"
      />
    ),
    coupon: (
      <CouponWireframeAndroid
        backgroundColor={{ hex: "#F7F3FF", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/coupon-strip.jpg"
        }
        promotion={"77 $"}
        name={"BEST COUPON"}
        symbol={"symbol"}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
        expiresLabel={intl.formatMessage({
          id: "wallet.label.expires",
        })}
        expiresDate={"Jul 27 2077, 19:00"}
        fromLabel={intl.formatMessage({
          id: "wallet.label.From",
        })}
        from="Wallet"
        promoCodeLabel={intl.formatMessage({
          id: "couponCard.label.promoCode",
        })}
        promoCode={"5G2G5D2A5F2"}
        descriptionLabel={intl.formatMessage({
          id: "wallet.label.Description",
        })}
        description="Enjoy your coupon by using it in your favorite store"
        valueLabel={intl.formatMessage({
          id: "coupon.label.value",
        })}
        value="77"
        valueCurrency="$"
        idCouponLabel={intl.formatMessage({
          id: "couponCard.label.idCoupon",
        })}
        idCoupon="61dc3b460779650007267001"
        disableNotifications="Disabled notifications"
      />
    ),
    event: (
      <EventWireframeAndroid
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/event-strip.jpg"
        }
        backgroundColor={{ hex: "#F9F2ED", rgb: "rgb(0,0,0)" }}
        eventName={"BEST Event"}
        priceCurrency="$"
        priceLabel={intl.formatMessage({ id: "wallet.label.value" })}
        price={"100"}
        endDate={"27 Jul 2077 22:00"}
        startDateLabel={intl.formatMessage({ id: "event.label.startTime" })}
        startDate={"27 Jul 2077 19:00"}
        openDate={"2023-06-21T10:08:25Z"}
        locationAddress={"72 Blvd Marguerite Paris"}
        locationName={"ElySEE Montmartre"}
        subscriptionMessage={"Happy Party!"}
        QrCode={{ value: "556655665566" }}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
        timeLabel={intl.formatMessage({ id: "wallet.label.time" })}
        doorLabel={intl.formatMessage({ id: "event.label.door" })}
        door="1"
        rowLabel={intl.formatMessage({ id: "event.label.row" })}
        row="1stC"
        placeLabel={intl.formatMessage({ id: "event.label.place" })}
        place="17B"
        walletId="5g1hdvb82154df853fca14835"
        barCodeLink=""
        ticketHolderLabel={intl.formatMessage({
          id: "event.label.ticketHolder",
        })}
        ticketHolder="Alan Jason"
        ticketNumberLabel={intl.formatMessage({
          id: "event.label.ticketNumber",
        })}
        ticketNumber="001"
        termsLabel={intl.formatMessage({
          id: "wallet.label.termsAndConditions",
        })}
        terms="Only for the BEST"
        googleMapsLink="Google Maps Link"
        infoLine="+33 891 060 698"
        email="email@email.com"
        disableNotifications="Disabled notifications"
        doorsOpenLabel={intl.formatMessage({ id: "event.label.doorsOpen" })}
      />
    ),
    flight: (
      <FlightWireframeAndroid
        backgroundColor={{ hex: "#EAF9F4", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/billet-back.jpg"
        }
        number={"13"}
        destinationTerminal={"INTL"}
        originTerminal={"INTL"}
        originGate={"59"}
        destinationGate={"44"}
        carrierIataCode={"LX"}
        locations={["locations"]}
        layovers={"layovers"}
        originIATACode={"CDG"}
        destinationIATACode={"LHR"}
        originAirportName={"Roissy-Charles de Gaulle"}
        destinationAirportName={"Heathrow"}
        departureDateTime={"Jul 27 2077, 19:00"}
        details={"Have a good trip!"}
        QrCode={{ value: "625eca0f7ecd160007721d8a" }}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
        name="Best - Demo"
      />
    ),
    hotel: (
      <HotelWireframeAndroid
        backgroundColor={{ hex: "rgba(70,70,247,0.1)", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/hotel-strip.jpg"
        }
        number={"316"}
        terms={"Check in 14h"}
        QrCode={{ value: "556655665566" }}
        name={"BEST Hotel"}
        type={"Single"}
        email={"Email"}
        address={"Passy banner 10 street"}
        contactPhone={"Info Line"}
        website={"bestwallet.fr"}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
      />
    ),
    cinema: (
      <CinemaTicketWireframeAndroid
        backgroundColor={{ hex: "#F5F1F1", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/cinema-strip.jpg"
        }
        price={"8.5"}
        endDate={"27 Jul 2077 22:00"}
        startDate={"27 Jul 2077 19:00"}
        openDate={"27 Jul 2077 20:00"}
        terms={"Come on time"}
        name={"UGC Montparnasse"}
        locationName={"83 Boulevard Montparnasse"}
        QrCode={{ value: "556655665566" }}
        movieName={"THE FILM"}
        rating={"PG-13"}
        screen={3}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
      />
    ),
    ferry: (
      <FerryWireframeAndroid
        backgroundColor={{ hex: "#EAF9F4", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/billet-back.jpg"
        }
        number={"375"}
        originStationCode={"FR"}
        originName={"Calais France "}
        destinationName={`Douvres royaume uni`}
        destinationStationCode={"UK"}
        departureTime={"Jul 27, 3:11 AM"}
        arrivalTime={"07:11 AM"}
        tarif={"177"}
        customerServiceNumber={"+3355443322"}
        details={"Changeable Alternate Traverse Be Flexible"}
        terms={"Last check-in 45 minutes before departure"}
        QrCode={{ value: "556655665566" }}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
        name="Best Ferry"
      />
    ),
    tram: (
      <TramWireframeAndroid
        backgroundColor={{ hex: "#EAF9F4", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/ticket-back.jpg"
        }
        number={"62"}
        originStationCode={"LDN"}
        originName={"London st-pancras"}
        destinationName={"Paris North Station"}
        destinationStationCode={"PAR"}
        departureTime={"Jul 27, 11:20 AM"}
        arrivalTime={"12:20 PM"}
        tarif={"100"}
        customerServiceNumber={"+3355443322"}
        details={"Transit Details"}
        terms={"Terms"}
        QrCode={{ value: "556655665566" }}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
        name={"Demo"}
      />
    ),
    rail: (
      <RailWireframeAndroid
        backgroundColor={{ hex: "#EAF9F4", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/ticket-back.jpg"
        }
        number={"45"}
        originStationCode={"PAR"}
        originName={"Paris east station"}
        destinationName={`Stockholm central`}
        destinationStationCode={"STO"}
        departureTime={"Jul 27, 7:04 AM"}
        arrivalTime={"7:37 AM"}
        tarif={"100"}
        customerServiceNumber={"+3355443322"}
        details={"1d 33m 4 changes"}
        terms={"Terms"}
        QrCode={"6066e4315abbcc1f9526d392"}
        name={"Demo"}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
      />
    ),
    bus: (
      <BusWireframeAndroid
        backgroundColor={{ hex: "#EAF9F4", rgb: "rgb(0,0,0)" }}
        strip={
          "https://bestwallet-uploads.s3.eu-west-3.amazonaws.com/ticket-back.jpg"
        }
        number={"17"}
        originStationCode={"PAR"}
        originName={"GR Paris Bercy-Seine"}
        destinationName={`GR Marseille St-Charles`}
        destinationStationCode={"MRS"}
        departureTime={"Jul 27, 11:17 AM"}
        arrivalTime={"12:17 PM"}
        tarif={"25"}
        customerServiceNumber={"+3355443322"}
        details={"Bon Voyage!"}
        terms={
          "Exchangeable and refundable free of charge by voucher up to 8 days before your depature."
        }
        name="Demo"
        QrCode={{ value: "556655665566" }}
        logo={
          "https://api.bestwallet.fr/static/7914ae112f8eab1918ccc8c5/images/logo.png"
        }
      />
    ),
  };

  return (
    <AndroidCardStyled>
      <Box width={340} maxWidth={340} minHeight={450}>
        {loading ? <Loading /> : androidWireframes[selectedPass]}
      </Box>
    </AndroidCardStyled>
  );
};
