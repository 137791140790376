import styled from "styled-components";

export const Wrapper = styled("div")<{
  backgroundColor: any;
  mainContentTextColor: string;
  textColor: string;
  titleColor: string;
  flip: boolean;
}>`
  perspective: 1000px;
  .pass {
    position: relative;
    transform: ${(props) => (props.flip ? "rotateY(180deg)" : "rotateY(0)")};
    transition: transform 0.6s;
    transform-style: preserve-3d;
    .mainSection {
      position: absolute;
      display: flex;
      width: 100%;
      height: fit-content;
      flex-direction: column;
      background-color: ${(props) => props.backgroundColor.hex};
      font-family: "Red Hat Text", sans-serif;
      border: 1px solid rgb(135 129 129 / 24%);
      box-sizing: border-box;
      backface-visibility: hidden;
      .mainSection-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .mainSection-header-logoSection {
          position: relative;
          display: flex;
          width: 100%;
          height: 55px;
          align-items: center;
          gap: 10px;
          img {
            height: 48px;
            width: 48px;
            object-fit: contain;
            margin-left: 10px;
          }
          .mainSection-header-eventName {
            color: ${(props) => props.textColor};
            font-size: 1rem;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0px;
          }
        }
      }

      .mainSection-content-banner {
        overflow: hidden;
        margin: 8px 0 2px 0px;
        img {
          height: 100px;
          width: 100%;
          object-fit: cover;
        }
      }
      .mainSection-content-enseigne {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        h4 {
          font-weight: bold;
          text-align: center;
          color: ${(props) => props.titleColor};
          margin: 0;
          margin-left: 15px;
          word-break: break-all;
        }
        h3 {
          font-weight: 400;
          text-align: center;
          color: ${(props) => props.textColor};
          margin: 0;
        }
      }
      .mainSection-content-name {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        h5 {
          text-align: center;
          color: ${(props) => props.titleColor};
          margin: 0;
          margin-left: 15px;
          word-break: break-all;
        }
      }
      .mainSection-content-datePoints {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        box-sizing: border-box;
      }

      .mainSection-content-barCode {
        margin-top: 133px;
        margin-bottom: 35px;
        .barCode {
          width: 255px;
          margin: auto;
          background: #ffffff;
          height: 100px;
          display: flex;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          img {
            width: 225px;
            height: 45px;
            object-fit: cover;
            margin-top: 25px;
          }
        }
        .walletId {
          font-size: 14px;
          font-weight: 450;
          text-align: center;
          margin: 10px 0px;
        }
      }
      div.date:nth-child(2) > h3:nth-child(1) {
        text-align: right;
      }

      div.points:nth-child(1) > h3:nth-child(2) {
        text-align: left;
      }

      div.points:nth-child(2) > h3:nth-child(1) {
        text-align: center;
      }

      .mainSection-label {
        color: ${(props) => props.titleColor};
        font-weight: 650;
        text-transform: uppercase;
        font-size: 0.6rem;
        margin: 0px;
      }
      .mainSection-data {
        color: ${(props) => props.textColor};
        font-size: 1.12rem;
        font-weight: 450;
        line-height: 20px;
        margin: 0px;
      }
    }
    .dataSection {
      position: ${(props) => (props.flip ? "unset" : "absolute")};
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: #fff;
      font-family: "Red Hat Text", sans-serif;
      border: 1px solid rgb(135 129 129 / 24%);
      padding: 10px;
      box-sizing: border-box;
      backface-visibility: hidden;
      transform: rotateY(180deg);
      transition: position 0.6s;
      .sectionLabel {
        line-height: 16px;
        .label {
          color: #837b7b;
          font-weight: 450;
          font-size: 0.75rem;
          text-transform: uppercase;
          letter-spacing: 0.025em;
          margin: 0 0 8px 0;
        }
        .data {
          display: flex;
          color: #837b7b;
          font-weight: 450;
          font-size: 0.95rem;
          word-break: break-all;
          gap: 8px;
          margin: 8px 0;
        }
      }
      .divider {
        flex-grow: 1;
        border-bottom: 1px solid #eeedf1;
        margin: 5px;
        width: 100%;
      }
    }
    .ligne {
      position: absolute;
      top: -2px;
      left: 50%;
      height: 2px;
      width: 60px;
      transform: translate(-48%);
      background-color: rgb(255, 255, 255);
      z-index: 999;
    }
    .arc-container {
      position: absolute;
      top: 0px;
      left: 50%;
      width: 70px;
      height: 16px;
      transform: rotateX(180deg) translate(-50%);
      overflow: hidden;
      .arc {
        width: 70px;
        height: 60px;
        background-color: rgb(255, 255, 255);
        border-radius: 50%;

        border: 1px solid rgba(135, 129, 129, 0.24);
      }
    }
    .backFlip_btn {
      position: absolute;
      bottom: 5px;
      right: 5px;
      transform: ${(props) => (props.flip ? "rotateY(180deg)" : "rotateY(0)")};
    }
  }
`;
