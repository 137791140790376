import React from "react";
//material
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
//components
import { Input, Button } from "../../Commons";
import { AndroidCard, AppleCard } from "../../Components";
import { isEmailValid } from "../../utils/validators";
import { sendDemoApi } from "../../apis/demoPasses";
import Snackbar from "../../Components/Snackbar";
//icons - images
import MailOutlined from "../../Assets/icons/MailOutlined";
import Event from "../../Assets/icons/Event";
import Coupon from "../../Assets/icons/Coupon";
import Metro from "../../Assets/icons/Metro";
import Train from "../../Assets/icons/Train";
import Bus from "../../Assets/icons/Bus";
import Avion from "../../Assets/icons/Avion";
import Hotel from "../../Assets/icons/Hotel";
import Wallet from "../../Assets/icons/Wallet";
import Cinema from "../../Assets/icons/Cinema";
import Bateau from "../../Assets/icons/Bateau";
import Apple from "../../Assets/icons/Apple";
import Gpay from "../../Assets/images/gpay.png";
import LogoIcon from "../../Assets/icons/Logo";
//styles
import { WalletPassesStyles } from "./walletPasses.styles";

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const WalletPasses: React.FC = () => {
  const timer = React.useRef<number>();

  const [loading, setLoading] = React.useState(false);
  const [loadingPass, setLoadingPass] = React.useState(true);
  const [value, setValue] = React.useState(0);
  const [paymentMethode, setPaymentMethode] = React.useState("gpay");
  const [kind, setKind] = React.useState("storecard");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState<any>("success");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const HandleSendDemo = async () => {
    if (checkEmail(email)) {
      setLoading(true);
      const res = await sendDemoApi(email, kind);
      setLoading(false);
      if (res.status === 200) {
        setSeverity("success");
        setMessage("Email sent successfully");
        setOpenSnackbar(true);
      } else if (res.status === 429) {
        setSeverity("error");
        setMessage("Too many requests from this IP, please try again later!");
        setOpenSnackbar(true);
      } else {
        setSeverity("error");
        setMessage("An error has occured");
        setOpenSnackbar(true);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const checkEmail = (email: string): boolean => {
    const valid = isEmailValid(email);
    if (!valid) {
      setOpenSnackbar(true);
      setSeverity("error");
      setMessage("Email is invalid");
      return false;
    } else {
      setOpenSnackbar(false);
      return true;
    }
  };

  const handlePaymentMethode = (value: string) => {
    setPaymentMethode(value);
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  React.useEffect(() => {
    if (loadingPass) {
      timer.current = window.setTimeout(() => {
        setLoadingPass(false);
      }, 900);
    }
  }, [loadingPass]);

  const handleButtonClick = () => {
    if (!loadingPass) {
      setLoadingPass(true);
    }
  };

  return (
    <WalletPassesStyles>
      <Snackbar
        message={message}
        severity={severity}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
      />
      <div className="navtoolbar">
        <LogoIcon />
      </div>
      <div className="wrapper">
        <div className="container">
          <div className="header-search">
            <Input
              type="email"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              placeholder="Enter Your Email"
              startAdornment={
                <InputAdornment position="start">
                  <MailOutlined />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    className="btn-send"
                    onClick={HandleSendDemo}
                    disabled={loading || !email}
                  >
                    SEND PASSES
                    {loading && (
                      <CircularProgress size={24} className="button-progress" />
                    )}
                  </Button>
                </InputAdornment>
              }
            />
          </div>
          <div className="btns-action">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
              aria-label="scrollable auto tabs"
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <Tab
                icon={<Wallet />}
                {...a11yProps(0)}
                onClick={() => {
                  setKind("storecard");
                  handleButtonClick();
                }}
              />
              <Tab
                icon={<Event />}
                {...a11yProps(1)}
                onClick={() => {
                  setKind("event");
                  handleButtonClick();
                }}
              />
              <Tab
                icon={<Coupon />}
                {...a11yProps(2)}
                onClick={() => {
                  setKind("coupon");
                  handleButtonClick();
                }}
              />
              <Tab
                icon={<Avion />}
                {...a11yProps(3)}
                onClick={() => {
                  setKind("flight");
                  handleButtonClick();
                }}
              />
              <Tab
                icon={<Bateau />}
                {...a11yProps(4)}
                onClick={() => {
                  setKind("ferry");
                  handleButtonClick();
                }}
              />
              <Tab
                icon={<Bus />}
                {...a11yProps(5)}
                onClick={() => {
                  setKind("bus");
                  handleButtonClick();
                }}
              />
              <Tab
                icon={<Train />}
                {...a11yProps(6)}
                onClick={() => {
                  setKind("rail");
                  handleButtonClick();
                }}
              />
              <Tab
                icon={<Metro />}
                {...a11yProps(7)}
                onClick={() => {
                  setKind("tram");
                  handleButtonClick();
                }}
              />{" "}
              <Tab
                icon={<Cinema />}
                {...a11yProps(8)}
                onClick={() => {
                  setKind("cinema");
                  handleButtonClick();
                }}
              />
              <Tab
                icon={<Hotel />}
                {...a11yProps(9)}
                onClick={() => {
                  setKind("hotel");
                  handleButtonClick();
                }}
              />{" "}
            </Tabs>
          </div>
          <div className="btn-wallet">
            <Box display="flex" justifyContent="center" alignItems="center">
              {/* <Gpay /> */}
              <img src={Gpay} alt="" style={{ height: 100 }} />
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Apple />
            </Box>
          </div>
          <div className="btn-wallet-mobile">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              component={Button}
              className={`gpay-box ${paymentMethode === "gpay" && "selected"}`}
              onClick={() => handlePaymentMethode("gpay")}
            >
              <img src={Gpay} alt="" />
              {/* <Gpay /> */}
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              component={Button}
              className={`apay-box ${paymentMethode === "apay" && "selected"}`}
              onClick={() => handlePaymentMethode("apay")}
            >
              <Apple />
            </Box>
          </div>

          <div className="wallet-show">
            <AndroidCard selectedPass={kind} loading={loadingPass} />
            <AppleCard selectedPass={kind} loading={loadingPass} />
          </div>
          <div className="wallet-show-mobile">
            {paymentMethode === "gpay" && (
              <AndroidCard selectedPass={kind} loading={loadingPass} />
            )}
            {paymentMethode === "apay" && (
              <AppleCard selectedPass={kind} loading={loadingPass} />
            )}
          </div>
        </div>
      </div>
    </WalletPassesStyles>
  );
};

export default WalletPasses;
