import styled from "styled-components";

export const Wrapper = styled("div")<{
  backgroundColor: any;
  mainContentTextColor: string;
  textColor: string;
  titleColor: string;
  flip: boolean;
}>`
  perspective: 1000px;
  .pass {
    position: relative;
    transform: ${(props) => (props.flip ? "rotateY(180deg)" : "rotateY(0)")};
    transition: transform 0.6s;
    transform-style: preserve-3d;
    .mainSection {
      position: absolute;
      display: flex;
      width: 100%;
      height: fit-content;
      flex-direction: column;
      background-color: ${(props) => props.backgroundColor.hex};
      font-family: "Red Hat Text", sans-serif;
      padding: 8px 12px;
      border-radius: 10px;
      border: 1px solid #dddada;
      box-sizing: border-box;
      backface-visibility: hidden;
      .firstSection {
        display: flex;
        justify-content: space-between;
        .mainSection-header-logo {
          height: 50px;
          width: 50px;
          overflow: hidden;
          img {
            height: 50px;
            width: 50px;
            object-fit: contain;
          }
        }
      }
      .mainSection-origin-destination {
        display: grid;
        grid-template-columns: 1fr 37px 1fr;
        align-items: center;
        margin-top: 12px;
        .origin {
          display: flex;
          flex-direction: column;
        }
        .mainSection-origin-name,
        .mainSection-destination-name {
          font-size: 0.61rem;
          font-weight: 600;
          color: ${(props) => props.textColor};
          text-transform: uppercase;
          overflow-wrap: break-word;
          word-wrap: break-word;
          margin: 0;
        }
        .mainSection-origin-stationCode,
        .mainSection-destination-destinationCode {
          font-size: 2.3rem;
          color: ${(props) => props.titleColor};
          font-weight: 400;
          line-height: 40px;
          margin: 0;
        }
        svg {
          width: 37px;
          height: 37px;
          margin-top: 15px;
        }
      }

      .mainSection-content-barCode {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        margin-bottom: 10px;

        .qrCode {
          display: flex;
          width: fit-content;
          flex-direction: column;
          align-items: center;
          background-color: #ffffff;
          padding: 12px 12px 5px 12px;
          .walletId {
            font-size: 0.93rem;
            font-weight: 450;
            margin: 3px auto;
          }
        }

        .barCode {
          width: 270px;
          margin: auto;
          background: #ffffff;
          height: 100px;
          display: flex;
          border-radius: 3px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          img {
            width: 235px;
            height: 58px;
            align-self: center;
            margin-top: 11px;
          }
          .walletId {
            font-size: 12px;
            color: black;
            text-align: center;
            margin: 7px 0px 10px 0px;
          }
        }
      }

      .mainSection-label {
        font-size: 0.61rem;
        color: ${(props) => props.textColor};
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        margin: 0;
      }
      .mainSection-data {
        font-size: 1.35rem;
        color: ${(props) => props.titleColor};
        font-weight: 450;
        line-height: 22px;
        margin: 0;
      }
      .mainSection-data-2 {
        font-size: 0.86rem;
        color: ${(props) => props.titleColor};
        font-weight: 600;
        margin: 0;
      }
      .mainSection-data-3 {
        font-size: 1rem;
        color: ${(props) => props.titleColor};
        font-weight: 600;
        margin: 0;
      }
    }

    .dataSection {
      position: ${(props) => (props.flip ? "unset" : "absolute")};
      display: flex;
      width: 100%;
      height: 475px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: #fff;
      padding: 10px;
      font-family: "Red Hat Text", sans-serif;
      border: 1px solid #dddada;
      border-radius: 10px;
      box-sizing: border-box;
      backface-visibility: hidden;
      transform: rotateY(180deg);
      transition: position 0.6s;
      .sectionLabel {
        line-height: 16px;
        .label {
          font-size: 0.75rem;
          color: #837b7b;
          font-weight: 450;
          text-transform: uppercase;
          letter-spacing: 0.025em;
          margin: 0 0 8px 0;
        }
        .data {
          display: flex;
          font-size: 0.95rem;
          color: #837b7b;
          font-weight: 450;
          gap: 8px;
          margin: 8px 0;
        }
      }
      .divider {
        width: 100%;
        border-bottom: 1px solid #eeedf1;
        margin: 5px 0;
      }
    }
    .divider {
      width: 100%;
      border-bottom: 1px solid #eeedf1;
      margin: 5px 0;
    }

    .round1 {
      position: absolute;
      top: 125px;
      left: -5px;
      height: 9px;
      width: 9px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid #dddada;
    }
    .hidden-round1 {
      position: absolute;
      top: 124px;
      left: -7px;
      height: 17px;
      width: 7px;
      background-color: #ffffff;
    }
    .round2 {
      position: absolute;
      top: 125px;
      right: -5px;
      height: 9px;
      width: 9px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid #dddada;
    }
    .hidden-round2 {
      position: absolute;
      top: 124px;
      right: -7px;
      height: 17px;
      width: 7px;
      background-color: #ffffff;
    }

    .backFlip_btn {
      position: absolute;
      bottom: 5px;
      right: 5px;
      transform: ${(props) => (props.flip ? "rotateY(180deg)" : "rotateY(0)")};
    }
  }
`;
