import styled from "styled-components";

export const StyledMainSection = styled("div")<{
  backgroundColor: any;
}>`
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.backgroundColor.hex};
  border-radius: 28px;
  box-sizing: border-box;
  overflow: hidden;
`;
