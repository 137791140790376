import React from "react";
import { useIntl } from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import { ic_more_horiz } from "react-icons-kit/md/ic_more_horiz";
import Icon from "react-icons-kit";
import { ratio } from "wcag-color";
import QRCode from "qrcode.react";
import moment from "moment";

import { getDataFromCache } from "../../utils/cache";
import { URL_IMAGE } from "../../apis/config";

import barcode from "../../Assets/images/barcode.png";
import cover from "../../Assets/images/lg-thumbnail.png";

import { Wrapper } from "./cinemaTicketWireframe.style";

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  price: any;
  endDate: any;
  startDate: any;
  openDate: any;
  terms: any;
  name: any;
  locationName: any;
  titleColor: any;
  textColor: any;
  QrCode: any;
  locations: any[];
  email: string;
  contactPhone: string;
  movieName: string;
  screen: number;
  rating: string;
  logo?: any;
};

const CinemaTicketWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    locationName,
    name,
    price,
    endDate,
    // startDate,
    openDate,
    terms,
    titleColor,
    textColor,
    QrCode,
    // locations,
    contactPhone,
    email,
    movieName,
    screen,
    rating,
    logo,
  } = props;
  const currentWallet = getDataFromCache("wallet");
  const [showDetails, setShowDetails] = React.useState(false);
  const intl = useIntl();
  const backFlip = () => {
    setShowDetails(!showDetails);
  };
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      titleColor={titleColor}
      textColor={textColor}
      flip={showDetails}
    >
      <div className="pass">
        <div className="mainSection">
          <div className="mainSection-header">
            <div className="mainSection-header-logoSection">
              <img
                src={
                  logo ? logo : `${URL_IMAGE}/${currentWallet?.logoPictureUrl}`
                }
                alt="wallet Logo"
              />
              <h5 className="mainSection-header-eventName">
                {name || "Nom de l'évènement"}
              </h5>
              <div className="ligne" />
              <div className="arc-container">
                <div className="arc"></div>
              </div>
            </div>
          </div>

          <div className="mainSection-content-banner">
            <img
              src={
                !strip
                  ? cover
                  : typeof strip === "string"
                  ? strip
                  : URL.createObjectURL(strip)
              }
              alt="banner"
            />
          </div>
          <div className="mainSection-content-datePoints">
            <div className="date">
              <h3 className="mainSection-label">
                {intl.formatMessage({ id: "coupon.label.value" })}
              </h3>
              <h3 className="mainSection-data">{price}</h3>
            </div>
            <div className="date">
              <h3 className="mainSection-label">Screen</h3>
              <h3 className="mainSection-data">{screen}</h3>
            </div>
            <div className="points">
              <h3 className="mainSection-label">
                {intl.formatMessage({ id: "event.label.row" })}
              </h3>
              <h3 className="mainSection-data">ds 11</h3>
            </div>
            <div className="points">
              <h3 className="mainSection-label">
                {intl.formatMessage({ id: "event.label.place" })}
              </h3>
              <h3 className="mainSection-data" style={{ textAlign: "right" }}>
                17B
              </h3>
            </div>
          </div>
          <div
            className="mainSection-content-datePoints"
            style={{ marginTop: 12 }}
          >
            <div className="points">
              <p className="mainSection-label">
                {intl.formatMessage({ id: "event.label.ticketHolder" })}
              </p>
              <h3 className="mainSection-data">{"Alan Jason"}</h3>
            </div>
            <div className="date">
              <h3 className="mainSection-label">Date</h3>
              <h3 className="mainSection-data">
                {moment(openDate).format("L")} {moment(openDate).format("LT")}
              </h3>
            </div>
          </div>

          <div className="mainSection-content-barCode">
            {QrCode.state ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "270px",
                }}
              >
                <QRCode includeMargin value={QrCode.value} />
              </div>
            ) : (
              <div className="barCode">
                <img src={barcode} alt="barcode" />
                <p className="walletId">6066e4315abbcc1f9526d392</p>
              </div>
            )}
          </div>
          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>
        <div className="dataSection">
          <div className="ligne" />
          <div className="arc-container">
            <div className="arc"></div>
          </div>
          <div className="sectionLabel">
            <h3 className="label">
              {intl.formatMessage({ id: "cinemaTicket.label.movieName" })}
            </h3>
            <h3 className="data">{movieName}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {intl.formatMessage({ id: "cinemaTicket.label.rating" })}
            </h3>
            <h3 className="data">{rating}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">UGC Montaparnasse</h3>
            <h3 className="data">{locationName || "location name"}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Doors open</h3>
            <h3 className="data">{openDate}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {intl.formatMessage({ id: "event.label.startTime" })}
            </h3>
            {openDate}
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">End date</h3>
            {endDate}
          </div>

          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {intl.formatMessage({ id: "transit.label.ticketNumber" })}
            </h3>
            <h3 className="data">6066e4315abbcc1f9526d392</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Terms & conditions</h3>
            <h3 className="data">{terms || ""}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Info Line</h3>
            <h3 className="data" style={{ color: "#3775d1" }}>
              {contactPhone || ""}
            </h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Email</h3>
            <h3 className="data" style={{ color: "#3775d1" }}>
              {email || ""}
            </h3>
          </div>
          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>
      </div>
    </Wrapper>
  );
};

export default CinemaTicketWireframeIPhone;
