import React from "react";
import { useIntl } from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import Icon from "react-icons-kit";
import { ratio } from "wcag-color";
import QRCode from "qrcode.react";
import { ic_more_horiz } from "react-icons-kit/md/ic_more_horiz";
import moment from "moment";

import { getDataFromCache } from "../../utils/cache";
import { URL_IMAGE } from "../../apis/config";

import barcode from "../../Assets/images/barcode.png";

import { Wrapper } from "./flightWireframe.style";

type WireframeProps = {
  backgroundColor: any;
  destinationTerminal: any;
  originTerminal: any;
  originGate: any;
  destinationGate: any;
  locations: any;
  layovers: any;
  originIATACode: any;
  destinationIATACode: any;
  originAirportName: any;
  destinationAirportName: any;
  departureDateTime: any;
  details: any;
  QrCode: any;
  strip: any;
  number: any;
  customerServiceNumber: any;
  titleColor: any;
  textColor: any;
  logo?: any;
};

const FlightWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    // destinationTerminal,
    originTerminal,
    originGate,
    // destinationGate,
    // locations,
    // layovers,
    originIATACode,
    destinationIATACode,
    originAirportName,
    destinationAirportName,
    departureDateTime,
    details,
    number,
    QrCode,
    // customerServiceNumber,
    titleColor,
    textColor,
    logo,
  } = props;
  const intl = useIntl();

  const currentWallet = getDataFromCache("wallet");
  const [showDetails, setShowDetails] = React.useState(false);

  const backFlip = () => {
    setShowDetails((v) => !v);
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      titleColor={titleColor}
      textColor={textColor}
      flip={showDetails}
    >
      <div className="pass">
        <div className="mainSection">
          <div className="firstSection">
            <div className="mainSection-header-logoSection">
              <img
                className="mainSection-header-logo"
                src={
                  logo ? logo : `${URL_IMAGE}/${currentWallet?.logoPictureUrl}`
                }
                alt="wallet Logo"
              />
              <span>Best - Demo</span>
            </div>
            <div
              style={{
                width: "205px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <h3 className="mainSection-label">
                {intl.formatMessage({ id: "flight.label.terminalAndGate" })}
              </h3>
              <h3 className="mainSection-data" style={{ fontSize: 19 }}>
                {originTerminal ? originGate : "A1"}
              </h3>
            </div>
          </div>

          <div className="mainSection-origin-destination">
            <div className="origin">
              <h3 className="mainSection-origin-name">
                {originAirportName || "Ho Chi Minh City"}
              </h3>
              <h3 className="mainSection-origin-stationCode">
                {originIATACode || "LAX"}
              </h3>
            </div>
            <div style={{ marginTop: "auto" }}>
              <svg height="33px" width="33px" viewBox="0 0 141 136">
                <path
                  d="M124,61c-13,0-20,0-20,0L88,42c0,0,8-1,8-4c0-4-12-4-12-4L72,21c0,0,9,0,9-3c0-4-12-3-12-3L55,0h-9l19,48
    c0,0,2.8,5.8,0,10c-2,3-40,5-40,5L10,44H0l7,21c0,0-4,1-4,3s4,3,4,3L0,92h10l15-19c0,0,38,2,40,5c2.8,4.2,0,10,0,10l-19,48h9l14-15
    c0,0,12,1,12-3c0-3-9-3-9-3l12-13c0,0,12,0,12-4c0-3-8-4-8-4l16-19c0,0,7,0,20,0s17-4,17-7S137,61,124,61z"
                ></path>
              </svg>
            </div>
            <div className="destination">
              <h3
                className="mainSection-destination-name"
                style={{ textAlign: "right" }}
              >
                {destinationAirportName || "Tokyo Haneda"}
              </h3>
              <h3
                className="mainSection-destination-destinationCode"
                style={{ textAlign: "right" }}
              >
                {destinationIATACode || "SFO"}
              </h3>
            </div>
          </div>
          <div className="secondSection">
            <span className="title">
              {intl.formatMessage({ id: "transit.label.departureTime" })}
            </span>
            <span className="title">
              {intl.formatMessage({ id: "flight.label.number" })}
            </span>
            <span className="title">
              {intl.formatMessage({ id: "flight.label.class" })}
            </span>
            <span className="title">date</span>
            <span className="result">
              {moment(departureDateTime).format("LT")}
            </span>
            <span className="result">{number || "13"}</span>
            <span className="result">Y</span>
            <span className="result">
              {moment(departureDateTime).format("LL")}
            </span>
          </div>

          <div>
            <div className="firstSection">
              <div className="date">
                <h3
                  className="mainSection-label"
                  style={{ textAlign: "start" }}
                >
                  {intl.formatMessage({ id: "transit.label.passenger" })}
                </h3>
                <h3 className="mainSection-data">Alan Jason</h3>
              </div>
              <div className="date">
                <h3
                  className="mainSection-label"
                  style={{ textAlign: "start" }}
                >
                  {intl.formatMessage({ id: "flight.label.zone" })}
                </h3>
                <h3 className="mainSection-data" style={{ textAlign: "start" }}>
                  C/5
                </h3>
              </div>
            </div>
          </div>

          <div className="mainSection-content-barCode">
            {QrCode ? (
              <div className="qrCode">
                <QRCode value={QrCode.value} />
                <p className="walletId">6066e4315abbcc1f9526d392</p>
              </div>
            ) : (
              <div className="barCode">
                <img src={barcode} alt="barcode" />
                <p className="walletId">6066e4315abbcc1f9526d392</p>
              </div>
            )}
          </div>

          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>

        <div className="dataSection">
          <div className="sectionLabel">
            <h3 className="label">flight details</h3>
            <h3 className="data">{details || ""}</h3>
          </div>

          <span className="divider"></span>

          <div className="sectionLabel">
            <h3 className="label">
              {intl.formatMessage({ id: "flight.label.confirmationCode" })}
            </h3>
            <h3 className="data">123456</h3>
          </div>

          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>
        <div className="round1" />
        <div className="round2" />
        <div className="hidden-round1" />
        <div className="hidden-round2" />
      </div>
    </Wrapper>
  );
};

export default FlightWireframeIPhone;
