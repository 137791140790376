import React from "react";
import { useIntl } from "react-intl";
import Icon from "react-icons-kit";
import { ic_call, ic_email, ic_public } from "react-icons-kit/md/";
import { ratio } from "wcag-color";
//images and icons
import barcode from "../../Assets/images/barcode.png";
import defaultLogo from "../../Assets/images/sm-thumbnail.png";
import cover from "../../Assets/images/lg-thumbnail.png";
//types
import {
  additionalContacts,
  description,
} from "../walletForm/walletForm.types";
//styled Components
import { Wrapper } from "./wireframe.style";
import {
  StyledContactInfoContainer,
  StyledDetailsBotton,
  StyledDetailsBottonContainer,
  StyledExpandMoreIcon,
  StyledHeaderContainer,
} from "../../Commons";
import { StyledMainSection } from "../../Commons/CommonStyles/CommonStyles";

type WireframeProps = {
  backgroundColor: any;
  name: any;
  logo: any;
  strip: any;
  addressLabel: string;
  address: any;
  descriptions: any;
  scheduleLabel: string;
  scheduleOpeningHours: string;
  additionalContacts: any;
  gainUnit: string;
  gain: string;
  gainLabel: string;
  textColor: string;
  titleColor: string;
  title: string;
  dateLabel: string;
  date: string;
  walletIdLabel: string;
  walletId: string;
  detailsButtonText: string;
  clientName: string;
  clientNameLabel: string;
  typeLabel: string;
  type: string;
  descriptionLabel: string;
  description: string;
  offerDescriptionLabel: string;
  offerDescription: string;
  CommercialPhoneLabel: string;
  CommercialPhone: string;
  AfterSaleServiceLabel: string;
  AfterSaleService: string;
  website: string;
  disableNotifications: string;
};

const Wireframe: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    logo,
    name,
    strip,
    address,
    descriptions,
    additionalContacts,
    gainUnit,
    textColor,
    titleColor,
    title,
    date,
    gain,
    walletId,
    detailsButtonText,
    clientName,
    dateLabel,
    gainLabel,
    addressLabel,
    walletIdLabel,
    clientNameLabel,
    typeLabel,
    type,
    descriptionLabel,
    description,
    offerDescriptionLabel,
    offerDescription,
    CommercialPhone,
    AfterSaleService,
    website,
    disableNotifications,
    scheduleLabel,
    scheduleOpeningHours,
    CommercialPhoneLabel,
    AfterSaleServiceLabel,
  } = props;
  const intl = useIntl();

  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>("");

  const handleChange = () => {
    setShowDetails(!showDetails);
    height === "100%" ? setHeight("90%") : setHeight("100%");
  };
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 21.1 ? "#ffffff" : "#000000"
      }
      textColor={textColor}
      titleColor={titleColor}
      visibilty={showDetails}
    >
      <StyledMainSection
        backgroundColor={backgroundColor}
        className="mainSection"
      >
        <StyledHeaderContainer
          mainContentTextColor={
            ratio("#ffffff", backgroundColor.hex) > 21.1 ? "#ffffff" : "#000000"
          }
        >
          <div className="mainSection-header-logo">
            <img
              src={
                !logo
                  ? defaultLogo
                  : typeof logo === "string"
                  ? logo
                  : URL.createObjectURL(logo)
              }
              alt="wallet Logo"
            />
          </div>
          <h4>{!name ? '"Enseigne"' : name}</h4>
        </StyledHeaderContainer>
        <div className="mainSection-content">
          <div className="mainSection-content-infos">
            <div className="mainSection-content-enseigne">
              <h3>{title}</h3>
            </div>
            <div className="mainSection-content-datePoints">
              <div className="date">
                <h3 className="mainSection-label">{dateLabel}</h3>
                <h3 className="mainSection-data">{date}</h3>
              </div>
              <div className="points">
                <h3 className="mainSection-label">{gainLabel}</h3>
                <h3 className="mainSection-data">
                  {gain} {gainUnit !== "points" ? gainUnit : ""}
                </h3>
              </div>
            </div>
          </div>
          <div className="mainSection-content-barCode">
            <div className="barCode">
              <img src={barcode} alt="barcode" />
            </div>
            <p className="walletId"> {walletId} </p>
          </div>
          <div className="mainSection-content-banner">
            <img
              src={
                !strip
                  ? cover
                  : typeof strip === "string"
                  ? strip
                  : URL.createObjectURL(strip)
              }
              alt="banner"
            />
          </div>
        </div>
      </StyledMainSection>
      <StyledDetailsBottonContainer>
        <StyledDetailsBotton
          disableRipple
          onClick={handleChange}
          startIcon={<StyledExpandMoreIcon showDetails={showDetails} />}
          variant="outlined"
        >
          {detailsButtonText}
        </StyledDetailsBotton>
      </StyledDetailsBottonContainer>

      <div className="dataSection">
        {showDetails && (
          <>
            <div className="sectionLabel">
              <h3 className="label"> {clientNameLabel} </h3>
              <h3 className="data">{clientName}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({
                  id: "wireframes.storecard.label.memberId",
                })}
              </h3>
              <h3 className="data">{walletId}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">{typeLabel}</h3>
              <h3 className="data">{type}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">{dateLabel}</h3>
              <h3 className="data">{date}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">{addressLabel}</h3>
              <h3 className="data">{address ? address : "Adresse"}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">{descriptionLabel}</h3>
              <h3 className="data">{description}</h3>
            </div>
            {descriptions.map((elm: description, index: number) => (
              <div className="sectionLabel" key={index}>
                <h3 className="label">
                  {elm.title ? elm.title : '"Description Title"'}
                </h3>
                <h3 className="data">{elm.value ? elm.value : '"Value"'}</h3>
              </div>
            ))}
            <div className="sectionLabel">
              <h3 className="label">{scheduleLabel}</h3>
              <h3 className="data">
                {scheduleOpeningHours ? scheduleOpeningHours : '"Horaires"'}
              </h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">{offerDescriptionLabel}</h3>
              <h3 className="data">{offerDescription}</h3>
            </div>
            {additionalContacts.map(
              (elm: additionalContacts, index: number) => (
                <div className="sectionIcon" key={index}>
                  <Icon
                    icon={
                      elm.kind === "PHONE"
                        ? ic_call
                        : elm.kind === "EMAIL"
                        ? ic_email
                        : ic_public
                    }
                    size={23}
                    style={{ color: "#444444" }}
                  />
                  <h3 className="data">
                    {elm.title ? elm.title : '"Titre du contact"'}
                  </h3>
                </div>
              )
            )}
            <StyledContactInfoContainer>
              <div className="sectionIcon">
                <Icon icon={ic_call} size={23} style={{ color: "#444444" }} />
                <h3 className="data">{CommercialPhoneLabel}</h3>
              </div>
              <h3 className="data">{CommercialPhone}</h3>
            </StyledContactInfoContainer>
            <StyledContactInfoContainer>
              <div className="sectionIcon">
                <Icon icon={ic_call} size={23} style={{ color: "#444444" }} />
                <h3 className="data">{AfterSaleServiceLabel}</h3>
              </div>

              <h3 className="data">{AfterSaleService}</h3>
            </StyledContactInfoContainer>
            <StyledContactInfoContainer>
              <div className="sectionIcon">
                <Icon icon={ic_public} size={23} style={{ color: "#444444" }} />
                <h3 className="data">{website}</h3>
              </div>
            </StyledContactInfoContainer>
            <StyledContactInfoContainer>
              <div className="sectionIcon">
                <Icon icon={ic_public} size={23} style={{ color: "#444444" }} />
                <h3 className="data">{disableNotifications}</h3>
              </div>
            </StyledContactInfoContainer>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default Wireframe;
