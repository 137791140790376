import React from "react";
import { useIntl } from "react-intl";
import Box from "@material-ui/core/Box";
import DirectionsIcon from "@material-ui/icons/Directions";
import { ic_call } from "react-icons-kit/md/ic_call";
import { ic_email } from "react-icons-kit/md/ic_email";
import { ic_location_on } from "react-icons-kit/md/ic_location_on";
import { ratio } from "wcag-color";
import Icon from "react-icons-kit";

import { getDataFromCache } from "../../utils/cache";
import { URL_IMAGE } from "../../apis/config";
import QRCode from "qrcode.react";

import barcode from "../../Assets/images/barcode.png";
import cover from "../../Assets/images/lg-thumbnail.png";

import { Wrapper } from "./cinemaTicketWireframe.style";
import { StyledMainSection } from "../../Commons/CommonStyles/CommonStyles";
import {
  StyledDetailsBotton,
  StyledDetailsBottonContainer,
  StyledExpandMoreIcon,
  StyledHeaderContainer,
  StyledSeperator,
} from "../../Commons";

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  price: any;
  endDate: any;
  startDate: any;
  openDate: any;
  terms: any;
  name: any;
  locationName: any;
  QrCode: any;
  movieName: any;
  rating: any;
  screen: any;
  logo?: any;
};

const CinemaTicketWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    locationName,
    name,
    price,
    endDate,
    startDate,
    openDate,
    terms,
    QrCode,
    movieName,
    screen,
    rating,
    logo,
  } = props;

  const intl = useIntl();
  const currentWallet = getDataFromCache("wallet");

  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>("");

  const handleChange = () => {
    setShowDetails(!showDetails);
    height === "100%" ? setHeight("100%") : setHeight("100%");
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      titleColor={"rgba(255, 80, 120, 0.83)"}
      textColor={"#000"}
      visibilty={showDetails}
    >
      <StyledMainSection
        backgroundColor={backgroundColor}
        className="mainSection"
      >
        <StyledHeaderContainer
          mainContentTextColor={
            ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
          }
        >
          <div className="mainSection-header-logo">
            <img
              src={
                logo ? logo : `${URL_IMAGE}/${currentWallet?.logoPictureUrl}`
              }
              alt="wallet Logo"
            />
          </div>
          <h4>Best - Demo</h4>
        </StyledHeaderContainer>
        <div className="mainSection-content-enseigne">
          <div className="mainSection-location">{name}</div>
          <div className="mainSection-eventName">
            {intl.formatMessage({ id: "cinemaTicket.label.movieName" })}
          </div>
          {/* *********************** */}
          <div className="movie-infos-wrapper">
            <div className="movie-info-wrapper">
              <span className="movie-info-label info-label">Date</span>
              <span className="movie-info-value">Jul 27, 2077</span>
            </div>

            <div className="movie-info-wrapper">
              <span className="movie-info-label info-label">Time</span>
              <span className="movie-info-value">20:00 PM </span>
            </div>
          </div>
          <StyledSeperator></StyledSeperator>
          <div className="place-infos-container">
            <div className="place-info-wrapper">
              <span className="place-info-label info-label">Value</span>
              <span className="place-info-value">8.5$</span>
            </div>
            <div className="place-info-wrapper">
              <span className="place-info-label info-label">Screen</span>
              <span className="place-info-value">3</span>
            </div>
            <div className="place-info-wrapper">
              <span className="place-info-label info-label">Row/PLace</span>
              <span className="place-info-value">ds 11 / 5</span>
            </div>
          </div>

          {/* ************************** */}
          {/* <div className="mainSection-content-datePoints">
            <div className="date">
              <h3 className="mainSection-label">Date</h3>
              <h3 className="mainSection-data">Jul 27, 2077</h3>
            </div>
            <div className="points">
              <h3 className="mainSection-label">TIME</h3>
              <h3 className="mainSection-data">20:00 PM</h3>
            </div>
          </div>

          <div className="mainSection-content-datePoints">
            <div className="date">
              <h3 className="mainSection-label">
                {intl.formatMessage({ id: "coupon.label.value" })}
              </h3>
              <h3 className="mainSection-data">{price || 10} $</h3>
            </div>
            <div className="points">
              <h3 className="mainSection-label">
                {intl.formatMessage({ id: "cinemaTicket.label.screen" })}
              </h3>
              <h3 className="mainSection-data">{screen || "2"}</h3>
            </div>
            <div className="points">
              <h3 className="mainSection-label">Row / Place</h3>
              <h3 className="mainSection-data">ds 11 / 5</h3>
            </div>
          </div> */}
          <div className="mainSection-content-barCode">
            {QrCode.state ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "270px",
                }}
              >
                <QRCode includeMargin value={QrCode.value} />
              </div>
            ) : (
              <>
                <div className="barCode">
                  <img src={barcode} alt="barcode" />
                </div>
                <p className="walletId">6066e4315abbcc1f9526d392</p>
              </>
            )}
          </div>
        </div>

        <div className="mainSection-content-banner">
          <img
            src={
              !strip
                ? cover
                : typeof strip === "string"
                ? strip
                : URL.createObjectURL(strip)
            }
            alt="banner"
          />
        </div>
      </StyledMainSection>

      <StyledDetailsBottonContainer>
        <StyledDetailsBotton
          disableRipple
          onClick={handleChange}
          startIcon={<StyledExpandMoreIcon showDetails={showDetails} />}
          variant="outlined"
        >
          Details
        </StyledDetailsBotton>
      </StyledDetailsBottonContainer>
      <div className="dataSection">
        {showDetails && (
          <>
            <div className="sectionLabel">
              <h3 className="label">Ticket Holder</h3>
              <h3 className="data">Alan Jason</h3>
            </div>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              className="sectionLabel"
            >
              <div>
                <h3 className="label">{name}</h3>
                <h3 className="data">{locationName}</h3>
              </div>
              <DirectionsIcon />
            </Box>
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({ id: "event.label.doorsOpen" })}
              </h3>
              <h3 className="data">{openDate}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">Event start time</h3>
              <h3 className="data">{startDate}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label"> Event end time </h3>
              <h3 className="data">{endDate}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({ id: "event.label.ticketNumber" })}
              </h3>
              <h3 className="data">6066e4315abbcc1f9526d392</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">Terms & conditions</h3>
              <h3 className="data">{terms || "terms"}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({ id: "cinemaTicket.label.movieName" })}
              </h3>
              <h3 className="data">{movieName || "nom"}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({ id: "cinemaTicket.label.rating" })}
              </h3>
              <h3 className="data">{rating}</h3>
            </div>
            <div className="sectionIcon">
              <Icon
                icon={ic_location_on}
                size={23}
                style={{ color: "#444444" }}
              />
              <h3 className="data">Google Map</h3>
            </div>
            <div className="sectionIcon">
              <Icon icon={ic_call} size={23} style={{ color: "#444444" }} />
              <h3 className="data">Info Line</h3>
            </div>
            <div className="sectionIcon">
              <Icon icon={ic_email} size={23} style={{ color: "#444444" }} />
              <h3 className="data">Email</h3>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default CinemaTicketWireframeAndroid;
