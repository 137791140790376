import { createStyles, makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(() =>
  createStyles({
    rootAppRouter: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  })
);
