import axios from "axios";
import { URL } from "./config";

export const sendDemoApi = (email: string, kind: string) => {
  const requestURL = `${URL}/demo`;
  const body = {
    email,
    kind,
  };

  return axios
    .post(requestURL, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
