import React from "react";
import Box from "@material-ui/core/Box";
import DirectionsIcon from "@material-ui/icons/Directions";
import { ic_call, ic_email, ic_location_on } from "react-icons-kit/md";
import { ratio } from "wcag-color";
import Icon from "react-icons-kit";
import QRCode from "qrcode.react";

import { getDataFromCache } from "../../utils/cache";
import { URL_IMAGE } from "../../apis/config";

import barcode from "../../Assets/images/barcode.png";
import cover from "../../Assets/images/lg-thumbnail.png";

import { Wrapper } from "./hotelWireframe.style";
import { StyledMainSection } from "../../Commons/CommonStyles/CommonStyles";
import {
  StyledDetailsBotton,
  StyledDetailsBottonContainer,
  StyledExpandMoreIcon,
  StyledHeaderContainer,
} from "../../Commons";

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  name: any;
  terms: any;
  type: any;
  email: any;
  address: any;
  contactPhone: any;
  website: any;
  QrCode: any;
  logo?: any;
};

const HotelWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    // number,
    name,
    terms,
    // type,
    email,
    address,
    contactPhone,
    // website,
    QrCode,
    logo,
  } = props;

  // const intl = useIntl();
  const currentWallet = getDataFromCache("wallet");

  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>("");

  const handleChange = () => {
    setShowDetails(!showDetails);
    height === "100%" ? setHeight("100%") : setHeight("100%");
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 21.1 ? "#ffffff" : "#000000"
      }
      height={height}
      visibilty={showDetails}
    >
      <StyledMainSection
        backgroundColor={backgroundColor}
        className="mainSection"
      >
        <StyledHeaderContainer
          mainContentTextColor={
            ratio("#ffffff", backgroundColor.hex) > 21.1 ? "#ffffff" : "#000000"
          }
        >
          <div className="mainSection-header-logo">
            <img
              src={
                logo ? logo : `${URL_IMAGE}/${currentWallet?.logoPictureUrl}`
              }
              alt="wallet Logo"
            />
          </div>
          <h4>Best - Demo</h4>
        </StyledHeaderContainer>

        {/* <div className="mainSection-header">
          <Icon size={23} icon={ic_more_vert} />
        </div>
        <div className="mainSection-header-logo">
          <img
            src={logo ? logo : `${URL_IMAGE}/${currentWallet?.logoPictureUrl}`}
            alt="wallet Logo"
          />
        </div> */}
        <div className="mainSection-content-enseigne">
          {/* <span className="mainSection-title">Best - Demo</span>*/}
          <h3 className="mainSection-address-label">{name}</h3>
          <h3 className="mainSection-address-value">{address}</h3>
        </div>
        <div className="datasect">
          <Box display="flex" flexDirection="column" style={{ gap: 1 }}>
            <span className="title">Room</span>
            <span className="result">316</span>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ gap: 1 }}
          >
            <span className="title" style={{ textAlign: "center" }}>
              check in
            </span>
            <span
              className="result"
              style={{ textAlign: "center", margin: "0 auto" }}
            >
              12 december
            </span>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyItems="end"
            style={{ gap: 1 }}
          >
            <span className="title" style={{ textAlign: "right" }}>
              check out
            </span>
            <span
              className="result"
              style={{ textAlign: "right", marginLeft: "auto" }}
            >
              12 december
            </span>
          </Box>
        </div>

        <div className="mainSection-content-barCode">
          {QrCode.state ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "270px",
              }}
            >
              <QRCode includeMargin value={QrCode.value} />
            </div>
          ) : (
            <>
              <div className="barCode">
                <img src={barcode} alt="barcode" />
              </div>
              <p className="walletId">6066e4315abbcc1f9526d392</p>
            </>
          )}
        </div>
        <div className="mainSection-content-banner">
          <img
            src={
              !strip
                ? cover
                : typeof strip === "string"
                ? strip
                : URL.createObjectURL(strip)
            }
            alt="banner"
          />
        </div>
      </StyledMainSection>
      <StyledDetailsBottonContainer>
        <StyledDetailsBotton
          disableRipple
          onClick={handleChange}
          // startIcon={!showDetails ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          startIcon={<StyledExpandMoreIcon showDetails={showDetails} />}
          variant="outlined"
        >
          Details
        </StyledDetailsBotton>
      </StyledDetailsBottonContainer>

      <div className="dataSection">
        {showDetails && (
          <>
            <div className="sectionLabel">
              <h3 className="label">Ticket Holder</h3>
              <h3 className="data">{"Alan Jason"}</h3>
            </div>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              className="sectionLabel"
            >
              <div>
                <h3 className="label">ADDRESS</h3>
                <h3 className="data">{address}</h3>
              </div>
              <DirectionsIcon />
            </Box>
            <div className="sectionLabel">
              <h3 className="label">Terms & conditions</h3>
              <h3 className="data">{terms}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">Type</h3>
              <h3 className="data">single</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">check in</h3>
              <h3 className="data">Tue, 05 Dec 2023 23:20:50 GMT</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">check out</h3>
              <h3 className="data">Tue, 12 Dec 2023 23:20:50 GMT</h3>
            </div>
            <div className="sectionIcon">
              <Icon
                icon={ic_location_on}
                size={23}
                style={{ color: "#444444" }}
              />
              <h3 className="data">Google Map</h3>
            </div>
            <div className="sectionIcon">
              <Icon icon={ic_call} size={23} style={{ color: "#444444" }} />
              <h3 className="data">{contactPhone}</h3>
            </div>
            <div className="sectionIcon">
              <Icon icon={ic_email} size={23} style={{ color: "#444444" }} />
              <h3 className="data">{email}</h3>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default HotelWireframeAndroid;
