import React from "react";
import { useIntl } from "react-intl";
import { ic_call, ic_public } from "react-icons-kit/md";
import Icon from "react-icons-kit";
import { ratio } from "wcag-color";
import moment from "moment";

import barcode from "../../Assets/images/barcode.png";
import cover from "../../Assets/images/lg-thumbnail.png";
import defaultLogo from "../../Assets/images/sm-thumbnail.png";

import { getDataFromCache } from "../../utils/cache";
import QRCode from "qrcode.react";

import { Wrapper } from "./flightWireframe.style";
import {
  StyledDetailsBotton,
  StyledDetailsBottonContainer,
  StyledExpandMoreIcon,
  StyledHeaderContainer,
  StyledSeperator,
} from "../../Commons";
import { StyledMainSection } from "../../Commons/CommonStyles/CommonStyles";

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  originTerminal: any;
  destinationTerminal: any;
  carrierIataCode: any;
  locations: any;
  layovers: any;
  originIATACode: any;
  destinationIATACode: any;
  originAirportName: any;
  destinationAirportName: any;
  departureDateTime: any;
  originGate: any;
  destinationGate: any;
  details: any;
  QrCode: any;
  logo?: any;
  name?: string;
};

const FlightWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    originGate,
    destinationGate,
    // number,
    destinationTerminal,
    originTerminal,
    // carrierIataCode,
    // locations,
    // layovers,
    originIATACode,
    destinationIATACode,
    originAirportName,
    destinationAirportName,
    departureDateTime,
    details,
    QrCode,
    logo,
    name,
  } = props;
  const currentWallet = getDataFromCache("wallet");
  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>("");
  const intl = useIntl();

  const handleChange = () => {
    setShowDetails(!showDetails);
    height === "100%" ? setHeight("100%") : setHeight("100%");
  };
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      height={height}
      visibilty={showDetails}
    >
      <StyledMainSection
        backgroundColor={backgroundColor}
        className="mainSection"
      >
        <StyledHeaderContainer
          mainContentTextColor={
            ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
          }
          justifyContent="space-between"
        >
          <div className="mainSection-header-left-side">
            <div className="mainSection-header-logo">
              <img
                src={
                  !logo
                    ? defaultLogo
                    : typeof logo === "string"
                    ? logo
                    : URL.createObjectURL(logo)
                }
                alt="wallet Logo"
              />
            </div>
            <h4>{name || "Best - Demo"}</h4>
          </div>

          <h4>LX 13</h4>
        </StyledHeaderContainer>
        {/* <div className="mainSection-header">
          <Icon size={21} icon={ic_more_vert} />
        </div>
        <div className="mainSection-header-logo">
          <img
            src={logo ? logo : `${URL_IMAGE}/${currentWallet?.logoPictureUrl}`}
            alt="wallet Logo"
          />
        </div> */}
        <div className="mainSection-content-enseigne">
          <div className="flight-locations">
            <span className="flight-location-departure">
              {/* {destinationAirportName || "Tokyo Haneda"} */}
              Tokyo Haneda
            </span>
            {" - "}
            <span className="flight-location-arrival">
              {/* {destinationAirportName || "Paris Orly"} */}
              Paris Orly
            </span>
          </div>
          <div className="flight-locations-codes">
            <span className="flight-location-departure-code">
              {/* {originIATACode || "HND"} */}
              HND
            </span>
            <span className="arrow">
              <svg
                width="25"
                height="25"
                viewBox="0 0 20 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8,21h2l5-8h5.5a1.5,1.5,0,0,0,0-3H15L10,2H8l2.5,8H5L3.5,8H2l1,3.5L2,15H3.5L5,13h5.5Z"
                  transform="translate(-2 -2)"
                ></path>
              </svg>
            </span>

            <span className="flight-location-arrival-code">
              {/* {destinationIATACode || "ORY"} */}
              ORY
            </span>
          </div>

          <div className="flight-infos-container">
            <div className="flight-info-wrapper">
              <span className="flight-info-label info-label">
                {intl.formatMessage({ id: "flight.label.terminalAndGate" })}
              </span>
              <span className="flight-info-value">
                {" "}
                {originTerminal
                  ? originTerminal + " / " + originGate + 1
                  : "A1"}
              </span>
            </div>
            <div className="flight-info-wrapper">
              <span className="flight-info-label info-label">
                {intl.formatMessage({ id: "flight.label.class" })}
              </span>
              <span className="flight-info-value">VIP</span>
            </div>
            <div className="flight-info-wrapper">
              <span className="flight-info-label info-label">
                {" "}
                {intl.formatMessage({ id: "transit.label.departureDate" })}
              </span>
              <span className="flight-info-value">
                {moment(departureDateTime).format("HH:mm")}
              </span>
            </div>
          </div>
          <StyledSeperator></StyledSeperator>
          <div className="passenger-infos-wrapper">
            <div className="passenger-info-wrapper">
              <span className="passenger-info-label info-label">
                {intl.formatMessage({ id: "transit.label.passenger" })}
              </span>
              <span className="passenger-info-value">Alan Jason</span>
            </div>

            <div className="passenger-info-wrapper">
              <span className="passenger-info-label info-label">
                Zone / Seat
              </span>
              <span className="passenger-info-value">C / 5</span>
            </div>
          </div>
          {/* <div className="mainSection-origin-destination">
            <span className="title" style={{ maxWidth: 120 }}>
              {originAirportName}
            </span>
            <span className="title" style={{ textAlign: "center" }}>
              LX 13
            </span>
            <span className="title" style={{ textAlign: "right" }}>
              {destinationAirportName || "Tokyo Haneda"}
            </span>
            <span className="code" style={{ maxWidth: 120, marginBottom: 15 }}>
              {originIATACode}
            </span>
            <span
              className="arrow"
              style={{ textAlign: "center", marginTop: -5 }}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 20 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8,21h2l5-8h5.5a1.5,1.5,0,0,0,0-3H15L10,2H8l2.5,8H5L3.5,8H2l1,3.5L2,15H3.5L5,13h5.5Z"
                  transform="translate(-2 -2)"
                ></path>
              </svg>
            </span>
            <span
              className="code"
              style={{ marginTop: -12, textAlign: "right" }}
            >
              {destinationIATACode || "SFO"}
            </span>

            <Box display="flex" flexDirection="column" style={{ maxWidth: 80 }}>
              <span className="title">
                {intl.formatMessage({ id: "flight.label.terminalAndGate" })}
              </span>
              <span className="code2">
                {originTerminal
                  ? originTerminal + " / " + originGate + 1
                  : "A1"}
              </span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              height="fit-content"
              justifyItems="center"
            >
              <span className="title">
                {intl.formatMessage({ id: "flight.label.class" })}
              </span>
              <span className="code2" style={{ margin: "0 auto" }}>
                VIP
              </span>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <span className="title">
                {intl.formatMessage({ id: "transit.label.departureDate" })}
              </span>
              <span className="code2">
                {moment(departureDateTime).format("HH:mm")}
              </span>
            </Box>
          </div>
          <div className="mainSection-content">
            <span className="title">
              {intl.formatMessage({ id: "transit.label.passenger" })}
            </span>
            <span className="title" style={{ textAlign: "right" }}>
              Zone / Seat
            </span>
            <span className="result">Alan Jason</span>
            <span className="result" style={{ textAlign: "right" }}>
              C / 5
            </span>
          </div> */}
          <div className="mainSection-content-barCode">
            {QrCode ? (
              <div className="qr-code">
                <QRCode value={QrCode.value} />
              </div>
            ) : (
              <>
                <div className="barCode">
                  <img src={barcode} alt="barcode" />
                </div>
                {/* <p className="walletId">6066e4315abbcc1f9526d392</p> */}
              </>
            )}
          </div>
        </div>
      </StyledMainSection>

      <StyledDetailsBottonContainer>
        <StyledDetailsBotton
          disableRipple
          onClick={handleChange}
          // startIcon={!showDetails ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          startIcon={<StyledExpandMoreIcon showDetails={showDetails} />}
          variant="outlined"
        >
          Details
        </StyledDetailsBotton>
      </StyledDetailsBottonContainer>

      <div className="dataSection">
        {showDetails && (
          <>
            <div className="sectionLabel">
              <h3 className="label">Flight Number</h3>
              <h3 className="data">LX 13</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({ id: "flight.label.confirmationCode" })}
              </h3>
              <h3 className="data">{"123458"}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">Scheduled Departure Time</h3>
              <h3 className="data">{departureDateTime}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">
                {intl.formatMessage({ id: "flight.label.arrivalGate" })}
              </h3>
              <h3 className="data">{destinationGate || "C3"}</h3>
            </div>
            <div className="sectionLabel">
              <h3 className="label">
                {" "}
                {intl.formatMessage({
                  id: "flight.label.destinationTerminale",
                })}
              </h3>
              <h3 className="data">{destinationTerminal || "2"}</h3>
            </div>
            <div className="mainSection-content-banner">
              <img
                src={
                  !strip
                    ? cover
                    : typeof strip === "string"
                    ? strip
                    : URL.createObjectURL(strip)
                }
                alt="banner"
              />
            </div>
            <div className="sectionLabel">
              <h3 className="label">Flight Details</h3>
              <h3 className="data">{details}</h3>
            </div>

            <div className="sectionIcon">
              <Icon icon={ic_call} size={23} style={{ color: "#444444" }} />
              <h3 className="data">Call Customer Service</h3>
            </div>
            <div className="sectionIcon">
              <Icon icon={ic_public} size={23} style={{ color: "#444444" }} />
              <h3 className="data">Disable notifications</h3>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default FlightWireframeAndroid;
