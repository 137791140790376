import styled from "styled-components";

export const AndroidCardStyled = styled.div`
  display: flex;
  width: fit-content;
  min-height: fit-content;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid #f2ecec;
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    width: 100%;
    border-radius: 0 10px 10px 10px;
    padding: 16px 8px;
    border: none;
    margin: 0%;
  }
`;
