import React from "react";
import { useIntl } from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import { ic_more_horiz } from "react-icons-kit/md/ic_more_horiz";
import { ratio } from "wcag-color";
import Icon from "react-icons-kit";
import QRCode from "qrcode.react";

import { getDataFromCache } from "../../utils/cache";
import { URL_IMAGE } from "../../apis/config";

import barcode from "../../Assets/images/barcode.png";
import cover from "../../Assets/images/lg-thumbnail.png";

import { Wrapper } from "./hotelWireframe.style";

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  name: any;
  terms: any;
  type: any;
  email: any;
  address: any;
  contactPhone: any;
  website: any;
  QrCode: any;
  titleColor?: any;
  textColor?: any;
  state?: any;
  logo?: any;
};

const HotelWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    number,
    name,
    terms,
    type,
    email,
    address,
    contactPhone,
    // website,
    QrCode,
    titleColor,
    textColor,
    logo,
  } = props;

  const intl = useIntl();

  const currentWallet = getDataFromCache("wallet");
  const [showDetails, setShowDetails] = React.useState(false);

  const backFlip = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      titleColor={titleColor}
      textColor={textColor}
      flip={showDetails}
    >
      <div className="pass">
        <div className="mainSection">
          <div className="mainSection-header">
            <div className="mainSection-header-logoSection">
              <img
                src={
                  logo ? logo : `${URL_IMAGE}/${currentWallet?.logoPictureUrl}`
                }
                alt="wallet Logo"
              />
              <h5 className="mainSection-header-eventName">
                {name || "Nom de l'évènement"}
              </h5>
              <div className="ligne" />
              <div className="arc-container">
                <div className="arc"></div>
              </div>
            </div>
          </div>

          <div className="mainSection-content-banner">
            <img
              src={
                !strip
                  ? cover
                  : typeof strip === "string"
                  ? strip
                  : URL.createObjectURL(strip)
              }
              alt="banner"
            />
          </div>

          <div className="mainSection-content-datePoints">
            <div className="points">
              <p className="mainSection-label">
                {intl.formatMessage({ id: "event.label.ticketHolder" })}
              </p>
              <h3 className="mainSection-data">Alan Jason</h3>
            </div>
          </div>

          <div
            className="mainSection-content-datePoints"
            style={{ marginTop: 12 }}
          >
            <div className="date">
              <h3 className="mainSection-label">ROOM</h3>
              <h3 className="mainSection-data">316</h3>
            </div>
            <div className="points">
              <h3 className="mainSection-label" style={{ textAlign: "right" }}>
                Check in
              </h3>
              <h3 className="mainSection-data">27 July</h3>
            </div>
            <div className="points">
              <h3 className="mainSection-label" style={{ textAlign: "right" }}>
                Check out
              </h3>
              <h3 className="mainSection-data" style={{ textAlign: "right" }}>
                27 August
              </h3>
            </div>
          </div>

          <div className="mainSection-content-barCode">
            {QrCode.state ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "270px",
                }}
              >
                <QRCode includeMargin value={QrCode.value} />
              </div>
            ) : (
              <div className="barCode">
                <img src={barcode} alt="barcode" />
                <p className="walletId">6066e4315abbcc1f9526d392</p>
              </div>
            )}
          </div>
          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>

        <div className="dataSection">
          <div className="sectionLabel">
            <h3 className="label">Check in</h3>
            <h3 className="data">28 Jul. 2077, 12:20 AM</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Check out</h3>
            <h3 className="data">28 aug. 2077, 12:20 AM</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Type</h3>
            <h3 className="data">{type}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Address</h3>
            <h3 className="data">{address}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Ticket Holder</h3>
            <h3 className="data">Alan Jason</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">
              {intl.formatMessage({ id: "ship.label.ticketNumber" })}
            </h3>
            <h3 className="data">{number}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Terms & conditions</h3>
            <h3 className="data">{terms || ""}</h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Info Line</h3>
            <h3 className="data" style={{ color: "#3775d1" }}>
              {contactPhone || ""}
            </h3>
          </div>
          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Email</h3>
            <h3 className="data" style={{ color: "#3775d1" }}>
              {`${email} ` || ""}
            </h3>
          </div>
          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>
      </div>
    </Wrapper>
  );
};

export default HotelWireframeIPhone;
