import styled from "styled-components";

export const Wrapper = styled("div")<{
  backgroundColor: any;
  mainContentTextColor: string;
  textColor: string;
  titleColor: string;
  flip: boolean;
}>`
  perspective: 1000px;
  .pass {
    position: relative;
    transform: ${(props) => (props.flip ? "rotateY(180deg)" : "rotateY(0)")};
    transition: transform 0.6s;
    transform-style: preserve-3d;
    .mainSection {
      position: absolute;
      display: flex;
      width: 100%;
      height: 429px;
      flex-direction: column;
      background-color: rgb(56 56 56 / 24%);
      font-family: "Red Hat Text", sans-serif;
      --mask: linear-gradient(#000 0 0) center/100% calc(100% - 6px) no-repeat,
        radial-gradient(circle farthest-side, #0000 98%, #000) 50% -3px/5.55px 6px
          repeat-x,
        radial-gradient(circle farthest-side, #0000 98%, #000) 50%
          calc(100% + 3px) / 5.55px 6px repeat-x;
      -webkit-mask: var(--mask);
      mask: var(--mask);
      backface-visibility: hidden;
      box-sizing: border-box;
      padding: 1px;
      .front {
        background-color: ${(props) => props.backgroundColor.hex};
        --mask: linear-gradient(#000 0 0) center/100% calc(100% - 6px) no-repeat,
          radial-gradient(circle farthest-side, #0000 98%, #000) 50% -3px/5.55px
            6px repeat-x,
          radial-gradient(circle farthest-side, #0000 98%, #000) 50%
            calc(100% + 3px) / 5.55px 6px repeat-x;
        -webkit-mask: var(--mask);
        mask: var(--mask);
        .mainSection-header {
          display: flex;
          width: 100%;
          height: 55px;
          justify-content: space-between;
          background-color: ${(props) => props.backgroundColor.hex};
          margin-top: 5px;
          padding: 2px 8px 0 8px;
          box-sizing: border-box;
          .mainSection-header-logoSection {
            display: flex;
            height: 50px;
            justify-content: flex-start;
            margin-left: 5px;
            gap: 8px;
            img {
              height: 48px;
              width: 48px;
              object-fit: contain;
              margin-top: -2px;
            }
            h3 {
              color: ${(props) => props.titleColor};
              font-size: 1.1rem;
              font-weight: 550;
              margin: 8px 0 0 0;
            }
          }
          .mainSection-header-name {
            margin-right: 5px;
            h6 {
              font-size: 0.65rem;
              text-align: right;
              font-weight: 550;
              text-transform: uppercase;
              letter-spacing: 0.5px;
              line-height: 18px;
              margin: 0;
            }
            h3 {
              font-size: 1.28rem;
              color: ${(props) => props.titleColor};
              font-weight: 450;
              line-height: 14px;
              margin: 0;
            }
          }
        }
        .mainSection-content-banner {
          img {
            height: 128px;
            width: 100%;
            object-fit: cover;
            margin-top: 4px;
          }
        }
        .mainSection-content-datePoints {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          padding: 2px 12px;
          box-sizing: border-box;
        }
        .mainSection-content-barCode {
          margin-top: 65px;
          margin-bottom: 35px;
          .barCode {
            display: flex;
            height: 90px;
            width: 250px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            margin: auto;
            border-radius: 5px;
            img {
              width: 210px;
              height: 40px;
              object-fit: cover;
              margin-top: 20px;
            }
          }
          .walletId {
            font-size: 0.8rem;
            font-weight: 500;
            color: black;
            text-align: center;
            margin: 10px 0 7px 0px;
          }
        }
        .mainSection-label {
          color: ${(props) => props.textColor};
          font-weight: 550;
          text-transform: uppercase;
          font-size: 0.65rem;
          letter-spacing: 0.15px;
          margin: 0px;
        }
        .mainSection-subdata {
          color: ${(props) => props.titleColor};
          font-size: 1.35rem;
          font-weight: 450;
          line-height: 22px;
          margin: 0px;
          text-align: left;
        }
      }
    }
    .dataSection {
      position: ${(props) => (props.flip ? "unset" : "absolute")};
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: rgb(56 56 56 / 24%);
      font-family: "Red Hat Text", sans-serif;
      --mask: linear-gradient(#000 0 0) center/100% calc(100% - 6px) no-repeat,
        radial-gradient(circle farthest-side, #0000 98%, #000) 50% -3px/5.55px 6px
          repeat-x,
        radial-gradient(circle farthest-side, #0000 98%, #000) 50%
          calc(100% + 3px) / 5.55px 6px repeat-x;
      -webkit-mask: var(--mask);
      mask: var(--mask);
      box-sizing: border-box;
      backface-visibility: hidden;
      transform: rotateY(180deg);
      transition: position 0.6s;
      padding: 1px;
      .back {
        height: 409px;
        background-color: #fff;
        --mask: linear-gradient(#000 0 0) center/100% calc(100% - 6px) no-repeat,
          radial-gradient(circle farthest-side, #0000 98%, #000) 50% -3px/5.55px
            6px repeat-x,
          radial-gradient(circle farthest-side, #0000 98%, #000) 50%
            calc(100% + 3px) / 5.55px 6px repeat-x;
        -webkit-mask: var(--mask);
        mask: var(--mask);
        padding: 9px;
        .sectionLabel {
          line-height: 16px;
          .label {
            color: #837b7b;
            font-weight: 450;
            font-size: 0.75rem;
            /* text-transform: uppercase; */
            letter-spacing: 0.025em;
            margin: 0 0 8px 0;
          }
          .data {
            display: flex;
            color: #837b7b;
            font-weight: 450;
            font-size: 0.95rem;
            word-break: break-all;
            gap: 8px;
            margin: 8px 0;
          }
        }
        .divider {
          width: 100%;
          border-bottom: 1px solid #eeedf1;
          margin: 12px 0 5px 0;
        }
      }
    }
    .backFlip_btn {
      position: absolute;
      bottom: 5px;
      right: 5px;
      transform: ${(props) => (props.flip ? "rotateY(180deg)" : "rotateY(0)")};
    }
  }
`;
