import React from "react";
import { useIntl } from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import { ic_more_horiz } from "react-icons-kit/md/ic_more_horiz";
import QRCode from "qrcode.react";
import { ratio } from "wcag-color";
import Icon from "react-icons-kit";

import { getDataFromCache } from "../../utils/cache";
import { URL_IMAGE } from "../../apis/config";

import barcode from "../../Assets/images/barcode.png";

import { Wrapper } from "./tramWireframe.style";

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  originStationCode: any;
  originName: any;
  destinationName: any;
  destinationStationCode: any;
  departureTime: any;
  arrivalTime: any;
  tarif: any;
  customerServiceNumber: any;
  details: any;
  terms: any;
  QrCode: any;
  titleColor?: any;
  textColor?: any;
  state?: any;
  logo?: any;
};

const TramWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    // strip,
    number,
    originStationCode,
    originName,
    destinationName,
    destinationStationCode,
    departureTime,
    arrivalTime,
    tarif,
    // customerServiceNumber,
    details,
    terms,
    QrCode,
    titleColor,
    textColor,
    logo,
  } = props;

  const intl = useIntl();
  const currentWallet = getDataFromCache("wallet");

  const [showDetails, setShowDetails] = React.useState(false);

  const backFlip = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio("#ffffff", backgroundColor.hex) > 3.0 ? "#ffffff" : "#000000"
      }
      titleColor={titleColor}
      textColor={textColor}
      flip={showDetails}
    >
      <div className="pass">
        <div className="mainSection">
          <div className="firstSection">
            <div className="mainSection-header-logo">
              <img
                src={
                  logo ? logo : `${URL_IMAGE}/${currentWallet?.logoPictureUrl}`
                }
                alt="wallet Logo"
              />
            </div>
            <div className="number">
              <div className="date">
                <h3 className="mainSection-label">
                  {intl.formatMessage({ id: "ship.label.number" })}
                </h3>
                <h3 className="mainSection-data" style={{ textAlign: "right" }}>
                  {number || "N de place"}
                </h3>
              </div>
            </div>
          </div>

          <div className="mainSection-origin-destination">
            <div className="origin">
              <h3 className="mainSection-origin-name">{originName}</h3>
              <h3 className="mainSection-origin-stationCode">
                {originStationCode}
              </h3>
            </div>
            <svg
              width="20"
              height="30"
              viewBox="0 0 20 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.00001 0C3.23829 0 1.00001 2.23828 1.00001 5V15C1.00001 17.3418 2.60938 19.3066 4.78321 19.8506L3.23438 22.3506L1.63477 25.0625C0.880865 26.3418 0.468756 27.0537 0.248053 27.4814C0.169928 27.6309 0.117193 27.7461 0.0800838 27.8379C0.0332088 27.9551 0.0117245 28.0361 0.00586508 28.1045C-0.0292912 28.4756 0.0957088 28.8057 0.324224 29.0273C0.507818 29.2061 0.757818 29.3135 1.04493 29.3135C1.64454 29.3135 1.80665 29.1631 2.51758 27.9492L3.0586 27.0273L4.25587 25H15.5781L16.7754 27.0273L17.2852 27.9023C18.0254 29.168 18.1797 29.3135 18.7891 29.3135C19.4277 29.3135 19.8887 28.7754 19.8281 28.1045C19.8008 27.8252 19.5508 27.3555 18.1992 25.0654L16.6016 22.3564L15.1992 19.8555C17.3809 19.3184 19 17.3486 19 15V5C19 3.72754 18.5254 2.56543 17.7422 1.68262C17.5801 1.5 17.4063 1.33008 17.2188 1.17285C17.0117 0.998047 16.7891 0.839844 16.5527 0.700195C15.8047 0.254883 14.9316 0 14 0H6.00001ZM12.7676 20H7.20313L5.7461 22.459L5.42969 23H14.4043L14.0859 22.459L12.7676 20ZM14 1H6.00001V4H14V1ZM3.00001 7C3.00001 5.89551 3.89454 5 5.00001 5H15C15.4238 5 15.8164 5.13086 16.1387 5.35547C16.6582 5.7168 17 6.31836 17 7V11C17 11.1211 16.9902 11.2393 16.9688 11.3535C16.9551 11.4297 16.9375 11.5039 16.916 11.5771C16.875 11.7148 16.8184 11.8467 16.75 11.9707C16.6719 12.1113 16.5781 12.2402 16.4707 12.3564C16.1055 12.752 15.582 13 15 13H5.00001C3.89454 13 3.00001 12.1045 3.00001 11V7ZM4.50001 18C4.82618 18 5.12891 17.8955 5.37501 17.7188C5.58985 17.5635 5.76368 17.3535 5.87305 17.1084C5.95508 16.9229 6.00001 16.7168 6.00001 16.5C6.00001 15.6719 5.32813 15 4.50001 15C4.04493 15 3.63672 15.2031 3.36133 15.5234C3.13672 15.7861 3.00001 16.127 3.00001 16.5C3.00001 16.9619 3.20899 17.375 3.53712 17.6504C3.79883 17.8691 4.13477 18 4.50001 18ZM17 16.5C17 17.3281 16.3281 18 15.5 18C14.6719 18 14 17.3281 14 16.5C14 15.6719 14.6719 15 15.5 15C15.8438 15 16.1602 15.1152 16.4121 15.3096C16.7695 15.584 17 16.0156 17 16.5Z"
                fill="black"
              />
            </svg>
            <div className="destination">
              <h3
                className="mainSection-destination-name"
                style={{ textAlign: "right" }}
              >
                {destinationName}
              </h3>
              <h3
                className="mainSection-destination-destinationCode"
                style={{ textAlign: "right" }}
              >
                {destinationStationCode}
              </h3>
            </div>
          </div>

          <div className="firstSection" style={{ marginTop: 14 }}>
            <div className="number">
              <div className="date">
                <h3 className="mainSection-label">Departure Date</h3>
                <h3 className="mainSection-data-2">{departureTime}</h3>
              </div>
            </div>
            <div className="number">
              <div className="date">
                <h3 className="mainSection-label">Arrival Date</h3>
                <h3 className="mainSection-data-2">{arrivalTime}</h3>
              </div>
            </div>
            <div className="number">
              <div className="date">
                <h3 className="mainSection-label">
                  {intl.formatMessage({ id: "ship.label.tarif" })}
                </h3>
                <h3 className="mainSection-data-2"> {`${tarif} $` || ""}</h3>
              </div>
            </div>
          </div>

          <div className="firstSection" style={{ marginTop: 24 }}>
            <div className="date">
              <h3 className="mainSection-label">
                {intl.formatMessage({ id: "ship.label.passenger" })}
              </h3>
              <h3 className="mainSection-data-3">Alan Jason</h3>
            </div>
          </div>

          <div className="mainSection-content-barCode">
            {QrCode ? (
              <div className="qrCode">
                <QRCode value={QrCode.value} />
                <p className="walletId">6066e4315abbcc1f9526d392</p>
              </div>
            ) : (
              <div className="barCode">
                <img src={barcode} alt="barcode" />
                <p className="walletId">6066e4315abbcc1f9526d392</p>
              </div>
            )}
          </div>
          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>
        <div className="dataSection">
          <div className="sectionLabel">
            <h3 className="label">Details</h3>
            <h3 className="data">{`${details}` || ""}</h3>
          </div>

          <span className="divider"></span>
          <div className="sectionLabel">
            <h3 className="label">Terms & conditions</h3>
            <h3 className="data">{terms || ""}</h3>
          </div>

          <IconButton className="backFlip_btn" size="small" onClick={backFlip}>
            <Icon size={21} icon={ic_more_horiz} />
          </IconButton>
        </div>
        <div className="round1" />
        <div className="round2" />
        <div className="hidden-round1" />
        <div className="hidden-round2" />
      </div>
    </Wrapper>
  );
};

export default TramWireframeIPhone;
